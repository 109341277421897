// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tab1-1{
    display: flex;

    #tab1-insided1{
        margin-left: 1vw;
    }
}

#tab1-2{
    margin-top: 1vh;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Tab1/Tab1.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,eAAe;IACf,aAAa;AACjB","sourcesContent":["#tab1-1{\n    display: flex;\n\n    #tab1-insided1{\n        margin-left: 1vw;\n    }\n}\n\n#tab1-2{\n    margin-top: 1vh;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
