import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { getDownTimeUptimeReports } from "../../../api/services/trends/trendsService";
import { Box, Paper, Divider, Typography, Button } from "@mui/material";
import Stationpoints from "../../Commonfns/Stationpoints";
import {
  decimalHoursToTimeString,
  hoursToTimeString,
  timeStringToDecimalHours,
  whatSholdYouDo,
} from "../../../utils/trends";
import chatIcon from "../../../Images/Black.svg";
import manIcon from "../../../Images/man.svg";
import lightIcon from "../../../Images/light.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DownTimeVsUpTime = () => {
  const { t } = useTranslation();

  const monthInputRef = useRef(null);

  const { control, register, formState, setValue, reset, watch } = useForm({
    defaultValues: {
      station_point: "HV",
      month: moment().format("YYYY-MM"),
    },
  });

  const [upTimeData, setUpTimeData] = useState([]);

  const [downTimeData, setDownTimeData] = useState([]);

  const [labels, setLabels] = useState([]);

  const { station_point, month } = watch();

  useEffect(() => {
    getDownTimeUptimeReports(station_point, month).then((data) => {
      setUpTimeData(data.uptimeData);
      setLabels(data.dateLabels);
      setDownTimeData(data.downtimeData);
    });
  }, [station_point, month]);

  const data = {
    labels: labels.map((label) => label),
    datasets: [
      {
        label: "UpTime Hours",
        data: upTimeData.map((timeString) =>
          timeStringToDecimalHours(timeString)
        ),
        backgroundColor: "#6174D7",
        maxBarThickness: 100,
      },
      {
        label: "DownTime Hours",
        data: downTimeData.map((timeString) =>
          timeStringToDecimalHours(timeString)
        ),
        backgroundColor: "#EA4335",
        maxBarThickness: 100,
      },
    ],
  };

  const options = {
    animation: {
      duration: 0,
      easing: undefined,
    },
    plugins: {
      tooltip: {
        animation: false,
        duration: 0,
        callbacks: {
          label: function (context) {
            // Get the value from the dataset

            const datasetLabel = context.dataset.label;
            const value = context.raw;
            // Convert the value to HH:MM:SS format
            const timeString = decimalHoursToTimeString(value);
            // Return the formatted time string
            return `${datasetLabel}: ${timeString}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        ticks: {
          callback: (value) => hoursToTimeString(value),
          beginAtZero: true,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Time",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
  };

  const handleStationPointChange = (value) => {
    setValue("station_point", value);
  };

  return (
    <div>
      <Paper className="filter-container">
        <Box>
          <div>
            <Stationpoints
              onStationPointChange={handleStationPointChange}
              defaultStationPoint="HV"
            />
          </div>
        </Box>
        <Box>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>

          <div>
            <input
              {...register("month")}
              style={{ height: "30px" }}
              type="month"
              ref={monthInputRef}
              defaultValue={month}
              max={new Date().toISOString().slice(0, 7)}
              onClick={() => monthInputRef.current.showPicker()}
              onChange={(e) => setValue('month', e.target.value)}
            />
          </div>
        </Box>
      </Paper>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6">
          {t("downTimeTrendsComponent.downtimeTrends")}
        </Typography>

        <Bar className="alerts-chart" data={data} options={options} />

        <Divider sx={{ mb: 2 }} />

        <Typography sx={{ mb: 2 }}>
          {" "}
          {t("common.howToUseTrendsEffectively")}
        </Typography>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={chatIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.whyIsThisImportanQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("downTimeTrendsComponent.whyIsThisImportantAnswer")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box
            className="imp-container"
            sx={{ justifyContent: "space-between" }}
          >
            <Box sx={{ display: "flex" , gap: 2 }}>
              <img
                style={{ alignSelf: "start" }}
                src={manIcon}
                width={"40px"}
                height={"40px"}
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#447F44", fontWeight: "bold" }}
                >
                  {t("common.WhatShouldYouDoQuestion")}
                </Typography>
                <Typography variant="body1">
                  {t("downTimeTrendsComponent.whatShouldYouDoAnswer")}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Link to={"/Forms?section=Breakdownlogsfetch"}>
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#447F44",
                    color: "white",
                    width: "200px",
                    justifySelf: "end",
                    alignSelf: "end",
                    borderRadius: "4px",
                  }}
                >
                  {t("downTimeTrendsComponent.breakdownLogs")}
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={lightIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.howWillYouImproveQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("downTimeTrendsComponent.howWillYouImproveAnswer")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default DownTimeVsUpTime;
