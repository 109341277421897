

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../../Images/logo.svg';
import "../../Operator/Sidebar/Sidebar.css";
import {  FaHome } from "react-icons/fa";
import { MdContactPhone } from "react-icons/md";
import UserdetailsHeader from "../../UserManagement/Details/Userdetailshead";

const Superadminsidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);

    const onleave = () => {
        setIsOpen(false);
        setActiveDropdown(null); // Close any open dropdown when the sidebar loses focus
    };

    const onenter = () => {
        setIsOpen(true);
    };

    const toggleDropdown = (dropdownName) => {
        if (activeDropdown === dropdownName) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(dropdownName);
        }
    };

    return (
        <div>
            <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`} onMouseLeave={onleave} onMouseEnter={onenter}>
                <div className="trigger">
                    <Link to='/'>
                        <div className="sidbarMainlogo">
                            <img src={Logo} alt="" />
                        </div>
                    </Link>
                    <FontAwesomeIcon icon={isOpen ? '' : faBars} />
                </div>
                <Link to='/' className="sidebar-position">
                    <FaHome />
                    <span>Home Dashboard</span>
                </Link>

                
                <Link to='/Designation' className="sidebar-position">
                    <FaHome />
                    <span>Designation</span>
                </Link>

                <Link to='/SuperadminRegistration' className="sidebar-position">
                    <FaHome />
                    <span>Add Super admin</span>
                </Link>

                <Link to='/Usercreation' className="sidebar-position">
                    <FaHome />
                    <span>Add User</span>
                </Link>

                <div className="sidebar-position1">
                    <UserdetailsHeader />
                </div>

                {/* <Link to='/' className="sidebar-position">
                    <MdContactPhone />
                    <span>Logout</span>
                </Link> */}
            </div>
        </div>
    );
};

export default Superadminsidebar;