// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const DashTOD_runtime = () => {
//     const [data, setData] = useState([{
//         date: 'N/A',
//         incentives: { runHours: 'N/A', consumption: 'N/A' },
//         penalty: { runHours: 'N/A', consumption: 'N/A' },
//         normal: { runHours: 'N/A', consumption: 'N/A' }
//     }]);

//     const [cummulativeenergyintesitydata, setCummulativeenergyintesityData] = useState([{
//         Cumulative_energy_intensity: 'N/A'
//     }])


//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-current-month`);
//                 if (response.data.length > 0) {
//                     setData(response.data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching the data', error);
//             }
//         };

//         fetchData();
//     }, []);

//     useEffect(() => {
//         const fetchcummulativeenergyintesityData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/cummulativeenergyintesity-currentmonth`);
//                 if (response.data.length > 0) {
//                     setCummulativeenergyintesityData(response.data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching the data', error);
//             }
//         };

//         fetchcummulativeenergyintesityData();
//     }, []);
//     return (
//         <div>
//             <div>
//                 <h1>Cummulative Energy Intensity</h1>
//                 {cummulativeenergyintesitydata.map((item, index) => (
//                     <div key={index}>
//                         {item.Cumulative_energy_intensity}
//                     </div>
//                 ))}
//             </div>
//             <div>
//                 <h1>Daily TOD Consumption for HV</h1>
//                 <div className="data-container">
//                     {data.map((item, index) => (
//                         <div key={index} className="data-item">
//                             <div className="data-date">{item.date}</div>
//                             <div className="category-container">
//                                 <div className="category-item">
//                                     <h2>Consumption</h2>
//                                     <div className="details">
//                                         <div className="detail"> Incentive Consumption: {item.incentives.consumption}</div>
//                                         <div className="detail"> Penalty Consumption: {item.penalty.consumption}</div>
//                                         <div className="detail">Normal Consumption: {item.normal.consumption}</div>

//                                     </div>
//                                 </div>
//                                 <div className="category-item">
//                                     <h2>Run Hours</h2>
//                                     <div className="details">
//                                         <div className="detail"> Incentives Run Hours: {item.incentives.runHours}</div>
//                                         <div className="detail"> Penalty Run Hours: {item.penalty.runHours}</div>
//                                         <div className="detail"> Normal Run Hours: {item.normal.runHours}</div>

//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>

//         </div>
//     )
// }

// export default DashTOD_runtime




import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './TOD_runtime.css'
// Function to convert time string to decimal hours and round to 2 decimal points
const timeStringToDecimalHours = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const totalHours = hours + minutes / 60 + seconds / 3600;
    return Math.round(totalHours * 100) / 100; // Round to 2 decimal points
};

const DashTOD_runtime = () => {
    const [data, setData] = useState([{
        date: 'N/A',
        incentives: { runHours: 'N/A', consumption: 'N/A' },
        penalty: { runHours: 'N/A', consumption: 'N/A' },
        normal: { runHours: 'N/A', consumption: 'N/A' }
    }]);

    const [cummulativeenergyintesitydata, setCummulativeenergyintesityData] = useState([{
        Cumulative_energy_intensity: 'N/A'
    }]);

    const consumptionChartRef = useRef(null);
    const runHoursChartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-current-month`);
                if (response.data.length > 0) {
                    setData(response.data);
                }
            } catch (error) {
                console.error('Error fetching the data', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchcummulativeenergyintesityData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/cummulativeenergyintesity-currentmonth`);
                if (response.data.length > 0) {
                    setCummulativeenergyintesityData(response.data);
                }
            } catch (error) {
                console.error('Error fetching the data', error);
            }
        };

        fetchcummulativeenergyintesityData();
    }, []);

    useEffect(() => {
        if (data.length === 0) return;

        // Process consumption data
        const incentives = data[0].incentives.consumption;
        const penalty = data[0].penalty.consumption;
        const normal = data[0].normal.consumption;
        const totalConsumption = (parseFloat(incentives) || 0) + (parseFloat(penalty) || 0) + (parseFloat(normal) || 0);

        const consumptionData = [
            { value: incentives, name: 'Incentives' },
            { value: penalty, name: 'Penalty' },
            { value: normal, name: 'Normal' }
        ];

        // Convert run hours to decimal hours and round to 2 decimal points
        const incentivesRunHours = timeStringToDecimalHours(data[0].incentives.runHours);
        const penaltyRunHours = timeStringToDecimalHours(data[0].penalty.runHours);
        const normalRunHours = timeStringToDecimalHours(data[0].normal.runHours);
        const totalRunHours = incentivesRunHours + penaltyRunHours + normalRunHours;

        const runHoursData = [
            { value: incentivesRunHours, name: 'Incentives' },
            { value: penaltyRunHours, name: 'Penalty' },
            { value: normalRunHours, name: 'Normal' }
        ];

        // Render consumption chart
        if (consumptionChartRef.current) {
            const consumptionChart = echarts.init(consumptionChartRef.current);
            consumptionChart.setOption({
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: 'Consumption',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'center',
                            formatter: () => `Total\n${totalConsumption.toFixed(2)}`, // Show "Total" heading with 2 decimal points
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: '#000' // Change the color if needed
                        },
                        emphasis: {
                            label: {
                                show: false, // Remove hover effect
                            },
                            itemStyle: {
                                borderWidth: 0 // Remove border on hover
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            color: (params) => {
                                const colors = ['#61B061', '#E16A5F', '#b8b8e6']; // Custom colors for each segment
                                return colors[params.dataIndex];
                            }
                        },
                        data: consumptionData
                    }
                ]
            });
        }

        // Render run hours chart
        if (runHoursChartRef.current) {
            const runHoursChart = echarts.init(runHoursChartRef.current);
            runHoursChart.setOption({
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: 'Run Hours',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'center',
                            formatter: () => `Total\n${totalRunHours.toFixed(2)} hours`, // Show "Total" heading with 2 decimal points and "hours" label
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: '#000' // Change the color if needed
                        },
                        emphasis: {
                            label: {
                                show: false, // Remove hover effect
                            },
                            itemStyle: {
                                borderWidth: 0 // Remove border on hover
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            color: (params) => {
                                const colors = ['#61B061', '#E16A5F', '#b8b8e6']; // Custom colors for each segment
                                return colors[params.dataIndex];
                            }
                        },
                        data: runHoursData
                    }
                ]
            });
        }
    }, [data]);

    return (
        <div id='todruntime-total'>
            <div id='todruntime-leftcontent'>
                <div id='todruntime-div1'>
                    <span id='todruntime-subhead1'>Cummulative Energy Intensity</span>
                    <span style={{ fontSize: '10px' }}>Energy(kWh) consumed to produce 1 metric ton</span>
                    {cummulativeenergyintesitydata.map((item, index) => (
                        <div id='todruntime-cumulativevalue' key={index}>
                            {item.Cumulative_energy_intensity}
                        </div>
                    ))}
                </div>
            </div>
            <div id='todruntime-rightcontent'>
            <div>
                <span id='todruntime-subhead1'>TOD Consumption</span>
                <div ref={consumptionChartRef} id='todruntime-cunsumptionchart'></div>

            </div>
            <span id='todruntime-line'></span>
            <div>
                <span id='todruntime-subhead1'>TOD Run hours</span>
                <div ref={runHoursChartRef} id='todruntime-runhours' ></div>

            </div>
            </div>
        </div>
    );
};

export default DashTOD_runtime;
