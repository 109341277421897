import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaFileCsv } from "react-icons/fa6";
import './Overallalertscount.css';
const Overallalertscount = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/all/alerts_reports`);
                setData(response.data);
                setFilteredData(response.data);
            } catch (error) {
                console.error('Error fetching the data', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        filterData();
    }, [fromDate, toDate]);

    const filterData = () => {
        let filtered = data;

        if (fromDate) {
            filtered = filtered.filter(item => new Date(item.date) >= new Date(fromDate));
        }
        if (toDate) {
            filtered = filtered.filter(item => new Date(item.date) <= new Date(toDate));
        }

        setFilteredData(filtered);
    };

    const downloadCSV = () => {
        const csvRows = [];
        const headers = [
            "Date",
            "Vr",
            "Vy",
            "Vb",
            "Ir",
            "Iy",
            "Ib",
            "PF",
            "KVA",
            "Switch",
            "AC Input",
            "Communication"
        ];
        csvRows.push(headers.join(','));

        filteredData.forEach(item => {
            const row = [
                item.date,
                item.overall_alert_counts.Vr,
                item.overall_alert_counts.Vy,
                item.overall_alert_counts.Vb,
                item.overall_alert_counts.Ir,
                item.overall_alert_counts.Iy,
                item.overall_alert_counts.Ib,
                item.overall_alert_counts.PF,
                item.overall_alert_counts.KVA,
                item.overall_alert_counts.Switch,
                item.overall_alert_counts['AC Input'],
                item.overall_alert_counts.Communication
            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'overall_alerts_data.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div id='overallalertcount-total'>
            <div id='overallalertcount-right-21'>
                <label>
                    <span>From Date: </span>
                    <input type='date' value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                </label>
                <label>
                    <span>To Date: </span>
                    <input type='date' value={toDate} onChange={(e) => setToDate(e.target.value)} />
                </label>
                <label>
                    <button onClick={downloadCSV}><FaFileCsv /></button>
                </label>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Vr</th>
                        <th>Vy</th>
                        <th>Vb</th>
                        <th>Ir</th>
                        <th>Iy</th>
                        <th>Ib</th>
                        <th>PF</th>
                        <th>KVA</th>
                        <th>Switch</th>
                        <th>AC Input</th>
                        <th>Communication</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => (
                        <tr key={item._id}>
                            <td>{item.date}</td>
                            <td>{item.overall_alert_counts.Vr}</td>
                            <td>{item.overall_alert_counts.Vy}</td>
                            <td>{item.overall_alert_counts.Vb}</td>
                            <td>{item.overall_alert_counts.Ir}</td>
                            <td>{item.overall_alert_counts.Iy}</td>
                            <td>{item.overall_alert_counts.Ib}</td>
                            <td>{item.overall_alert_counts.PF}</td>
                            <td>{item.overall_alert_counts.KVA}</td>
                            <td>{item.overall_alert_counts.Switch}</td>
                            <td>{item.overall_alert_counts['AC Input']}</td>
                            <td>{item.overall_alert_counts.Communication}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Overallalertscount;
