// import React from 'react'

// const HomeLoad = () => {
//   return (
//     <div>HomeLoad</div>
//   )
// }

// export default HomeLoad


import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import './HomeLoad.css';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import '../../Livesummary/Livesummary.css'
import staticdata from './Staticdata.json'
function Livesummary() {
    const [hvData, setHvData] = useState(null);
    const [imaxValues, setImaxValues] = useState({});
    const [mdData, setMdData] = useState([]);
    const [timestamps, setTimestamps] = useState([]);
    const [showLV1data, setShowLV1data] = useState(false);
    const [showLV2data, setShowLV2data] = useState(false);
    const [showProductiondata, setShowProductiondata] = useState(false);
    const [showDG1data1, setShowDG1data] = useState(false);
    const [showDG2data, setShowDG2data] = useState(false);

    // useEffect(() => {
    //     const socket = io(process.env.REACT_APP_LIVESUMMARY_URL);

    //     socket.on('connect', () => {
    //         console.log('WebSocket client connected');
    //     });

    //     socket.on('hv_data', (data) => {
    //         console.log('Received hv_data:', data);
    //         setHvData(data);
    //         setMdData(prevMdData => [...prevMdData, data.MD]); // Accumulate MD values
    //         setTimestamps(prevTimestamps => [...prevTimestamps, data.current_time]); // Accumulate timestamps
    //     });

    //     socket.on('imax_percentage_values', (data) => {
    //         console.log('Received imax_values:', data);
    //         setImaxValues(prevValues => ({ ...prevValues, ...data }));
    //     });

    //     return () => {
    //         socket.disconnect();
    //     };
    // }, []);


   

    useEffect(() => {
        console.log('MD Data:', mdData);
        console.log('Timestamps:', timestamps);
    }, [mdData, timestamps]);

    // if (!hvData) {
    //     return <div style={{ textAlign: 'center' }}>Loading...</div>;
    // }



    const getProgressBarColor = (percentage) => {
        if (percentage < 90) {
            return 'green';
        } else if (percentage >= 90 && percentage < 95) {
            return 'orange';
        } else if (percentage >= 95) {
            return 'red';
        }
    };
    const getProgressBarColorforGenerators = (percentage) => {
        if (percentage < 5) {
            return 'green';
        } else if (percentage >= 5 && percentage < 10) {
            return 'orange';
        } else if (percentage >= 10) {
            return 'red';
        }
    };

    const toggleLV1Data = () => {
        setShowLV1data(!showLV1data);
    };
    const toggleLV2Data = () => {
        setShowLV2data(!showLV2data);
    };
    const toggleProductionData = () => {
        setShowProductiondata(!showProductiondata);
    };
    const toggleDG1Data1 = () => {
        setShowDG1data(!showDG1data1);
    };
    const toggleDG2Data = () => {
        setShowDG2data(!showDG2data);
    };

    return (
        <div id='homeload-total'>

            <div id='livesummary-right-3'>
                <div id='livesummary-right-31'>
                    <div id='livesummary-right-headflex1'>
                        <div id='livesummary-legend-flex' >
                            <div id='livesummary-right-headflex12'> <span> Loads :&lt; 90% <span id='btn1'></span> </span></div>
                            <div id='livesummary-right-headflex12'> <span>  &gt;= 90% to 95%  <span id='btn2'></span> </span></div>
                            <div id='livesummary-right-headflex12'> <span>  &gt; 95%  <span id='btn3'></span> </span></div>
                        </div>
                        <div id='livesummary-legend-flex' >
                            <div id='livesummary-right-headflex12'> <span> Generators :&lt; 5% <span id='btn1'></span> </span></div>
                            <div id='livesummary-right-headflex12'> <span> &gt;= 5% to 10%  <span id='btn2'></span> </span></div>
                            <div id='livesummary-right-headflex12'> <span>  &gt; 10%  <span id='btn3'></span> </span></div>
                        </div>

                    </div>
                    <div id='livesummary-right-headflex'>
                        <div id='livesummary-sectionname'>Section Name</div>
                        <div id='livesummary-communication'>Communication</div>
                        <div id='livesummary-imaxvalue'>I max</div>
                        <div id='livesummary-loadbar'>Load Bar </div>
                        <div id='livesummary-threshold'>Rated Value</div>
                    </div>

                    <div className="section-container">
                        {Object.entries(staticdata).map(([section, values]) => {
                            if (section === 'HV') {
                                return (
                                    <div className="section" key={section}>
                                        <div className="section-name">{section} <button id='livesummary-dropbtn' onClick={toggleLV1Data}>{showLV1data ? <FaChevronUp /> : <FaChevronDown />}</button></div>
                                        <div className="section-communication" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                        <div className="section-imaxvalue"> {values.imax}</div>
                                        <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
                                            <div
                                                className={`progress-bar ${getProgressBarColor(values.percentage)}`}
                                                style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                            >
                                                <span id='progress-inside-percentage' > {values.percentage}%</span>
                                            </div>
                                        </div>
                                        {/* <div>{values.percentage}% </div> */}
                                        <div className="threshold">{values.threshold}</div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                        <div>
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Induction Heater') {
                                        return (
                                            <div className="section-1" key={section}>
                                                <div className="section-name-1">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-1 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-1">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Work Conveyor Panel') {
                                        return (
                                            <div className="section-1" key={section}>
                                                <div className="section-name-1">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-1 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-1">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            <div>
                                {showLV1data && (
                                    Object.entries(staticdata).map(([section, values]) => {
                                        if (section === 'Compressor (11kw)') {
                                            return (
                                                <div className="section-2" key={section}>
                                                    <div className="section-name-2">{section}</div>
                                                    <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                    <div className="section-imaxvalue-2"> {values.imax}</div>
                                                    <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                        <div
                                                            className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                            style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                        >
                                                            <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                        </div>
                                                    </div>
                                                    <div className="threshold-2">{values.threshold}</div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })
                                )}
                            </div>
                            {/* ------------------ */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Cut Bend') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {/* ----------------- */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Compressor (75kw)') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {/* ----------------- */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Compressor (30kw)') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {/* ----------------- */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Epoxy Coating Blower') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {/* ----------------- */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Epoxy Coating Booth Stabilizer') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Shot Blasting-1') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {/* ------------------ */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Shot Blasting-2') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {/* ------------------ */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Pump House') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {/* ------------------ */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Lighting Panel') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                            {/* ------------------ */}
                            {showLV1data && (
                                Object.entries(staticdata).map(([section, values]) => {
                                    if (section === 'Utility Panel') {
                                        return (
                                            <div className="section-2" key={section}>
                                                <div className="section-name-2">{section}</div>
                                                <div className="section-communication-2" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                                <div className="section-imaxvalue-2"> {values.imax}</div>
                                                <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
                                                    <div
                                                        className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
                                                        style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColor(values.percentage) }}
                                                    >
                                                        <span id='progress-inside-percentage' > {values.percentage}%</span>
                                                    </div>
                                                </div>
                                                <div className="threshold-2">{values.threshold}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            )}
                        </div>

                        {Object.entries(staticdata).map(([section, values]) => {
                            if (section === 'DG 62.5 KVA') {
                                return (
                                    <div className="section" key={section}>
                                        <div className="section-name">{section}</div>
                                        <div className="section-communication" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                        <div className="section-imaxvalue"> {values.imax}</div>
                                        <div className={`progress-bar-container ${getProgressBarColorforGenerators(values.percentage)}`}>
                                            <div
                                                className={`progress-bar-generator ${getProgressBarColorforGenerators(values.percentage)}`}
                                                style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColorforGenerators(values.percentage) }}
                                            >
                                                <span id='progress-inside-percentage' > {values.percentage}%</span>
                                            </div>
                                        </div>
                                        {/* <div>{values.percentage}% </div> */}
                                        <div className="threshold">{values.threshold}</div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                        {Object.entries(staticdata).map(([section, values]) => {
                            if (section === 'Solar 734.66 KVA') {
                                return (
                                    <div className="section" key={section}>
                                        <div className="section-name">{section}</div>
                                        <div className="section-communication" style={{ color: values.communication === 'online' ? 'green' : 'red' }}> {values.communication}</div>
                                        <div className="section-imaxvalue"> {values.imax}</div>
                                        <div className={`progress-bar-container ${getProgressBarColorforGenerators(values.percentage)}`}>
                                            <div
                                                className={`progress-bar-generator ${getProgressBarColorforGenerators(values.percentage)}`}
                                                style={{ width: `${values.percentage}%`, backgroundColor: getProgressBarColorforGenerators(values.percentage) }}
                                            >
                                                <span id='progress-inside-percentage' > {values.percentage}%</span>
                                            </div>
                                        </div>
                                        {/* <div>{values.percentage}% </div> */}
                                        <div className="threshold">{values.threshold}</div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
}

export default Livesummary;