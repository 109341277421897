import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import "./RevenueLoss.css";
import { getRevenueLossesTrends } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import manIcon from "../../../Images/man.svg";
import lightIcon from "../../../Images/light.svg";
import { Link } from "react-router-dom";
import { errorToast } from "../../../utils/toast";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const RevenueLossTrend = () => {
  const { t } = useTranslation();
  const monthInputRef = useRef(null);

  const { register, setValue, watch } = useForm({
    defaultValues: {
      month: moment().format("YYYY-MM"),
    },
  });

  const [chartData, setChartData] = useState([]);

  const { month } = watch();

  useEffect(() => {
    getRevenueLossesTrends(month)
      .then((res) => {
        setChartData(res);
      })
      .catch((error) => {
        errorToast(error.message || "failed to fetch Alert Count");
      });
  }, [month]);

  const data = {
    labels: chartData.map((label) => label?.type),
    datasets: [
      {
        label: "Revenue Losses",
        data: chartData.map((data) => data?.details?.energyLoss),
        backgroundColor: [
          "#FF994D",
          "#4285F4",
          "#FBBC04",
          "#34A853",
          "#FF6D01",
          "#46BDC6",
          "#7BAAF7",
          "#F07B72",
          "#F07B72",
          "#FCD04F",
          "#71C287",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    animation: {
      duration: 50,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          padding: 33,
          textAlign: "start",
          useBorderRadius: true,
          borderRadius: 5,
        },
      },
      tooltip: {
        animation: false,
        duration: 0,
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const typeObj = chartData.find(
              (data) => data.details.energyLoss == value
            );
            const energyLoss = typeObj?.details?.energyLoss;
            const revenueLoss = typeObj?.details?.revenueLoss;

            return [
              `Energy Loss:  ${energyLoss} (kWh)`,
              `Revenue Loss: ${revenueLoss} (₹)`,
            ];
          },
        },
      },
    },
  };

  return (
    <div>
      <Paper className="filter-container">
        <Box>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>

          <div>
            <input
              {...register("month")}
              style={{ height: "30px" }}
              type="month"
              ref={monthInputRef}
              defaultValue={month}
              max={new Date().toISOString().slice(0, 7)}
              onClick={() => monthInputRef.current.showPicker()}
              onChange={(e) => setValue("month", e.target.value)}
            />
          </div>
        </Box>
      </Paper>

      <Paper className="chart-container">
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
          <Typography variant="h6">
            {t("revenueLossTrendComponent.revenueLoss")}
          </Typography>

          <Typography
            dangerouslySetInnerHTML={{
              __html: t(
                "revenueLossTrendComponent.clickBelowLegendToToggleData"
              ),
            }}
            variant="body1"
          />
        </Box>

        <Pie className="alerts-chart" data={data} options={options} />

        <Divider sx={{ mb: 2 }} />

        <Typography sx={{ mb: 2 }}>
          {t("common.howToUseTrendsEffectively")}
        </Typography>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={chatIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.whyIsThisImportanQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("revenueLossTrendComponent.whyIsThisImportantAnswer")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box
            className="imp-container"
            sx={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                style={{ alignSelf: "start" }}
                src={manIcon}
                width={"40px"}
                height={"40px"}
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#447F44", fontWeight: "bold" }}
                >
                  {t("common.WhatShouldYouDoQuestion")}
                </Typography>
                <Typography variant="body1">
                  {t("revenueLossTrendComponent.whatShouldYouDoAnswer")}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Link to={"/Alldash?section=MonthlyDashboard-1"}>
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#447F44",
                    color: "white",
                    width: "200px",
                    justifySelf: "start",
                    alignSelf: "start",
                    borderRadius: "4px",
                  }}
                >
                  {t("revenueLossTrendComponent.revenueLoss")}
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={lightIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.howWillYouImproveQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("revenueLossTrendComponent.howWillYouImproveAnswer")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default RevenueLossTrend;
