
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Transformermonitoringfetch.css';
import { formatDateToDDMMYYYY } from '../Dateformat';
import { FaFileCsv } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Transformermonitoringfetch = ({ onFormButtonClick }) => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [transformers, setTransformers] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userDesignation, setUserDesignation] = useState('');
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerNames`);
            console.log('Fetched Transformers:', response.data);
            setTransformers(response.data);
        } catch (error) {
            console.error('Error fetching Transformers:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformer-monitoring-logs`);
                const data = response.data;
                if (Array.isArray(data)) {
                    setLogs(data);
                    setFilteredLogs(data);
                } else {
                    console.error('Failed to fetch data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchLogs();
    }, []);

    useEffect(() => {
        filterLogsByDate();
    }, [fromDate, toDate, logs]);

    const filterLogsByDate = () => {
        let filtered = logs;

        if (fromDate) {
            const [fromYear, fromMonth, fromDay] = fromDate.split('-');
            const fromDateFormatted = new Date(fromYear, fromMonth - 1, fromDay);  // Create a Date object for comparison

            filtered = filtered.filter(log => {
                const [logDay, logMonth, logYear] = log.date.split('-');
                const logDateFormatted = new Date(logYear, logMonth - 1, logDay); // Create a Date object from log.date
                return logDateFormatted >= fromDateFormatted; // Compare the dates
            });
        }

        if (toDate) {
            const [toYear, toMonth, toDay] = toDate.split('-');
            const toDateFormatted = new Date(toYear, toMonth - 1, toDay);  // Create a Date object for comparison

            filtered = filtered.filter(log => {
                const [logDay, logMonth, logYear] = log.date.split('-');
                const logDateFormatted = new Date(logYear, logMonth - 1, logDay); // Create a Date object from log.date
                return logDateFormatted <= toDateFormatted; // Compare the dates
            });
        }

        setFilteredLogs(filtered);
    };


    const handleEditClick = (log) => {
        setSelectedLog({
            ...log,
            date: log.date.split('-').reverse().join('-'), // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
        });
        setIsPopupOpen(true);
    };

    const calculateKWhConsumption = (KWhinitial, KWhfinal) => {
        const initial = parseFloat(KWhinitial);
        const final = parseFloat(KWhfinal);

        if (!isNaN(initial) && !isNaN(final)) {
            return (final - initial).toFixed(2);
        }
        return '';
    };


    const [errors, setErrors] = useState({
        starttime: '',
        endtime: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;


        if (name === 'transformer') {
            setSelectedLog(prevState => ({
                ...prevState,
                [name]: value
            }));
            return;
        }

        // Define patterns for each input 
    const patterns = {
        vr: /^\d{0,6}(\.\d{0,2})?$/, // 8 digits, 2 decimals for voltage Vr
        vb: /^\d{0,6}(\.\d{0,2})?$/, // 8 digits, 2 decimals for voltage Vb
        vy: /^\d{0,6}(\.\d{0,2})?$/, // 8 digits, 2 decimals for voltage Vy
        KWhinitial: /^\d{0,13}(\.\d{0,2})?$/, // 15 digits, 2 decimals for KWh initial
        KWhfinal: /^\d{0,13}(\.\d{0,2})?$/, // 15 digits, 2 decimals for KWh final
        pf: /^\d{0,4}(\.\d{0,0})?$/, // 4 digits, no decimals for PF
        oilTemp: /^\d{0,3}(\.\d{0,2})?$/, // 5 digits, 2 decimals for Oil Temp
        windingTemp: /^\d{0,3}(\.\d{0,2})?$/, // 5 digits, 2 decimals for Winding Temp
        oilLevelMain: /^\d{0,3}(\.\d{0,2})?$/, // 5 digits, 2 decimals for Oil Level Main
        oilLevelOLTC: /^\d{0,3}(\.\d{0,2})?$/, // 5 digits, 2 decimals for Oil Level OLTC
        Tapposition: /^\d{0,2}(\.\d{0,2})?$/, // 4 digits, 2 decimals for Tap Position
    };

    const pattern = patterns[name];
        
    if ( pattern && pattern.test(value) || value === '') {
        setSelectedLog({
            ...selectedLog,
            [name]: value
        });

       

        if (name === 'KWhinitial' || name === 'KWhfinal') {
            const initial = parseFloat(selectedLog.KWhinitial);
            const final = parseFloat(selectedLog.KWhfinal);

            if (!isNaN(initial) && !isNaN(final) && initial > final) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    KWhinitial: 'KWh Initial value cannot be greater than KWh final value.'
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    KWhinitial: ''
                }));
                setSelectedLog(prevState => ({
                    ...prevState,
                    KWh: calculateKWhConsumption(selectedLog.KWhinitial, selectedLog.KWhfinal)
                }));
            }
        }

    } else {
        toast.error('Invalid input. Please follow the correct format.');
    }
};




    const handleUpdate = async () => {
        const formattedDate = formatDateToDDMMYYYY(selectedLog.date);

        const updatedLog = {
            ...selectedLog,
            modifiedby: loggedInUser.username,
            modifiedat: formattedDate,
            date: formattedDate,
        };

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/transformer-monitoring-logs/${selectedLog._id}`, updatedLog);
            const data = response.data;
            if (data.success) {
                setLogs(logs.map(log => (log._id === selectedLog._id ? updatedLog : log)));
                setIsPopupOpen(false);
                setSelectedLog(null);
                toast.success('Transformer Monitoring Log updated successfully');
            } else {
                console.error('Failed to update data:', data.error);
            }
        } catch (error) {
            console.error('Error updating data:', error);
            if (error.response && error.response.data.message === 'A log already exists for the date') {
                toast.error('Data already exists for the Selected Date and Transformer, Please select any other Date');
            } else {
                toast.error('Failed to update Transformer monitoring log. Please try again later.');
            }
        }
    };


    const downloadCSV = () => {
        // Define the headers for the CSV file
        const headers = [
            'Date', 'Transformer', 'Vr', 'Vb', 'Vy',
            'KWh (initial)', 'KWh (final)', 'KWh', 'PF',
            'Oil Temp - °C', 'Winding Temp - °C',
            'Oil Level Main - °C', 'Oil Level OLTC - °C',
            'Tapposition'
        ];

        // Map the logs data to CSV format
        const rows = filteredLogs.map(log => [
            log.date, log.transformer, log.vr, log.vb, log.vy,
            log.KWhinitial, log.KWhfinal, log.KWh, log.pf,
            log.oilTemp, log.windingTemp, log.oilLevelMain,
            log.oilLevelOLTC, log.Tapposition
        ]);

        // Combine headers and rows
        const csvContent = [
            headers.join(','), // Join headers with commas
            ...rows.map(row => row.join(',')) // Join each row's data with commas
        ].join('\n'); // Combine each row with a newline character

        // Create a blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Transformer_Monitoring.csv');
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);

        if (toDate && toDate < selectedFromDate) {
            setToDate('');
        }
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    };


    return (
        <div id='transformermonfetch-total'>
            <div id='transformermonfetch-right'>

                <div id='transformermonfetch-right-2'>

                    <div id='transformermonfetch-right-21'>
                        <label>
                            <span>From Date:</span>
                            <input
                                type='date'
                                value={fromDate}
                                onChange={handleFromDateChange}
                                max={getTodayDate()}
                            />
                        </label>
                        <label>
                            <span>To Date:</span>
                            <input
                                type='date'
                                value={toDate}
                                onChange={handleToDateChange}
                                max={getTodayDate()}
                                min={fromDate || ''}
                                disabled={!fromDate}
                            />

                        </label>
                        <label id='formbutton-left'>
                            <button id='formbutton-from' onClick={onFormButtonClick}>Form</button>
                        </label>
                        <label>
                            <button id='formbutton-csv' onClick={downloadCSV}><FaFileCsv id='formbutton-csvicon' /></button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Transformer</th>
                                <th>Vr</th>
                                <th>Vb</th>
                                <th>Vy</th>
                                <th>KWh (initial)</th>
                                <th>KWh (final)</th>
                                <th>KWh</th>
                                <th>PF</th>
                                <th>Oil Temp - °C</th>
                                <th>Winding Temp - °C</th>
                                <th>Oil Level Main - °C</th>
                                <th>Oil Level OLTC - °C</th>
                                <th>Tapposition</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredLogs.length === 0 ? (
                                <tr>
                                    <td colSpan="15">No data available for selected filters.</td>
                                </tr>
                            ) : (
                                filteredLogs.map((log, index) => (
                                    <tr key={log._id || index}>
                                        <td>{log.date}</td>
                                        <td>{log.transformer}</td>
                                        <td>{log.vr}</td>
                                        <td>{log.vb}</td>
                                        <td>{log.vy}</td>
                                        <td>{log.KWhinitial}</td>
                                        <td>{log.KWhfinal}</td>
                                        <td>{log.KWh}</td>
                                        <td>{log.pf}</td>
                                        <td>{log.oilTemp}</td>
                                        <td>{log.windingTemp}</td>
                                        <td>{log.oilLevelMain}</td>
                                        <td>{log.oilLevelOLTC}</td>
                                        <td>{log.Tapposition}</td>
                                        <td><button onClick={() => handleEditClick(log)}>Edit</button></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
                        <div id='transformermonfetch-popup'>
                            <h3>Edit Transformer Monitoring Form</h3>
                            {selectedLog && (
                                <form>
                                    <div id='transformermonfetch-popup1'>
                                        <label>
                                            <span> Transformer<span id='all-mandetorymark'>*</span> </span>
                                            <select
                                                className="tplan-input"
                                                name="transformer"
                                                value={selectedLog.transformer}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select</option>
                                                {transformers.map((item, index) => (
                                                    <option key={index} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                        <label>
                                            <span> Date   <span id='all-mandetorymark'>*</span></span>
                                            <input type='date' name="date" value={selectedLog.date} onChange={handleChange} max={getTodayDate()} required />
                                        </label>

                                    </div>

                                    <div id='transformermonfetch-popup1'>
                                        <label>
                                            <span>  Vr  <span id='all-mandetorymark'>*</span></span>
                                            <input name="vr" value={selectedLog.vr} onChange={handleChange} required   maxLength="8"  step="0.01" />
                                        </label>
                                        <label>
                                            <span> Vb  <span id='all-mandetorymark'>*</span></span>
                                            <input name="vb" value={selectedLog.vb} onChange={handleChange} required  maxLength="8"  step="0.01" />
                                        </label>

                                    </div>

                                    <div id='transformermonfetch-popup1'>
                                        <label>
                                            <span>  Vy  <span id='all-mandetorymark'>*</span></span>
                                            <input name="vy" value={selectedLog.vy} onChange={handleChange} required  maxLength="8"  step="0.01" />
                                        </label>
                                        <label>
                                            <span>KWh (initial)  <span id='all-mandetorymark'>*</span></span>
                                            <input name="KWhinitial" value={selectedLog.KWhinitial} onChange={handleChange} required  maxLength="15"  step="0.01" />
                                            {errors.KWhinitial && <span id='transformermonfetch-errormessage' style={{ color: 'red' }}>{errors.KWhinitial}</span>}
                                        </label>

                                    </div>

                                    <div id='transformermonfetch-popup1'>
                                        <label>
                                            <span> KWh (final) <span id='all-mandetorymark'>*</span></span>
                                            <input name="KWhfinal" value={selectedLog.KWhfinal} onChange={handleChange} required  maxLength="15"  step="0.01" />
                                        </label>
                                        <label>
                                            <span> KWh Consumption <span id='all-mandetorymark'>*</span></span>
                                            <input name="KWh" value={selectedLog.KWh} onChange={handleChange} disabled />
                                        </label>

                                    </div>

                                    <div id='transformermonfetch-popup1'>
                                        <label>
                                            <span>  PF <span id='all-mandetorymark'>*</span></span>
                                            <input name="pf" value={selectedLog.pf} onChange={handleChange} required   maxLength="4" />
                                        </label>
                                        <label>
                                            <span> Oil Temp - °C <span id='all-mandetorymark'>*</span></span>
                                            <input name="oilTemp" value={selectedLog.oilTemp} onChange={handleChange} required  maxLength="5"  step="0.01" />
                                        </label>

                                    </div>
                                    <div id='transformermonfetch-popup1'>
                                        <label>
                                            <span> Winding Temp - °C <span id='all-mandetorymark'>*</span></span>
                                            <input name="windingTemp" value={selectedLog.windingTemp} onChange={handleChange} required   maxLength="5"  step="0.01"/>
                                        </label>
                                        <label>
                                            <span> Oil Level Main - °C <span id='all-mandetorymark'>*</span></span>
                                            <input name="oilLevelMain" value={selectedLog.oilLevelMain} onChange={handleChange} required  maxLength="5"  step="0.01"/>
                                        </label>

                                    </div>
                                    <div id='transformermonfetch-popup1'>
                                        <label>
                                            <span> Oil Level OLTC - °C <span id='all-mandetorymark'>*</span></span>
                                            <input name="oilLevelOLTC" value={selectedLog.oilLevelOLTC} onChange={handleChange} required maxLength="5"  step="0.01"/>
                                        </label>
                                        <label>
                                            <span>Tapposition  <span id='all-mandetorymark'>*</span></span>
                                            <input name="Tapposition" value={selectedLog.Tapposition} onChange={handleChange} required maxLength="4"  step="0.01"/>
                                        </label>
                                    </div>
                                    <button id='transformermonfetch-popup-update' type="button" onClick={handleUpdate}>Update</button>
                                </form>
                            )}
                        </div>
                    </Popup>
                </div>
            </div>
        </div>
    );
};

export default Transformermonitoringfetch;
