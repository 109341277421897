import { errorToast } from "../../../utils/toast";
import axiosInstance from "../axios";
import routes from "../routes.json";

export const getScoreCardData = async (month) => {
  const params = {
    month,
  };

  try {
    return (
      await axiosInstance.get(routes.API.REPORTS.SCORE_CARD_URL, {
        params,
      })
    ).data;
  } catch (error) {
    errorToast(error.msg || "Error fetching Tod trends");
  }
};
