
// import React, { useEffect, useState } from 'react';
// import './Historydata.css';
// import Operatorsidebar from '../Sidebar/Sidebar';
// import { alertData2 } from '../Data';
// import Stationpoints from '../../Commonfns/Stationpoints';
// import { FaFileCsv } from "react-icons/fa6";
// // Helper function to get today's date in YYYY-MM-DD format
// const getTodayDate = () => {
//   const today = new Date();
//   const yyyy = today.getFullYear();
//   const mm = String(today.getMonth() + 1).padStart(2, '0');
//   const dd = String(today.getDate()).padStart(2, '0');
//   return `${yyyy}-${mm}-${dd}`;
// };

// const Historydata = () => {
//   const [data, setData] = useState([]);
//   const [section, setSection] = useState('');
//   const [fromDate, setFromDate] = useState(getTodayDate());
//   const [toDate, setToDate] = useState(getTodayDate());
//   const [filters, setFilters] = useState({
//     deviceEUi: '',
//     division: '',
//     stationPoint: '',
//     utcTimestamp: '',
//     Vr: '',
//     Vy: '',
//     Vb: '',
//     Vavg: '',
//     Ir: '',
//     Iy: '',
//     Ib: '',
//     Iavg: '',
//     Imax: '',
//     Fr: '',
//     THDvr: '',
//     THDvy: '',
//     THDvb: '',
//     THDir: '',
//     THDiy: '',
//     THDib: '',
//     Vry: '',
//     Vyb: '',
//     Vbr: '',
//     Pf: '',
//     kW: '',
//     kVA: '',
//     kWh: '',
//     KVArh: '',
//     KVAh: ''
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let url = `${process.env.REACT_APP_API_EMS_URL}/api/historydata`;

//         if (fromDate && toDate) {
//           url += `?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
//         }

//         const response = await fetch(url);
//         const result = await response.json();
//         setData(result);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [fromDate, toDate]);

//   const handleStationPointChange = (selectedStationPoint) => {
//     setSection(selectedStationPoint);
//   };

//   const filterData = () => {
//     let filtered = data;

//     if (fromDate && toDate) {
//       const fromDateObj = new Date(fromDate);
//       const toDateObj = new Date(toDate);
//       fromDateObj.setHours(0, 0, 0, 0);
//       toDateObj.setHours(23, 59, 59, 999);

//       filtered = filtered.filter(item => {
//         const year = parseInt(item.utcTimestamp.substring(0, 4));
//         const month = parseInt(item.utcTimestamp.substring(4, 6)) - 1;
//         const day = parseInt(item.utcTimestamp.substring(6, 8));
//         const hours = parseInt(item.utcTimestamp.substring(8, 10));
//         const minutes = parseInt(item.utcTimestamp.substring(10, 12));
//         const seconds = parseInt(item.utcTimestamp.substring(12, 14));
//         const timestamp = new Date(year, month, day, hours, minutes, seconds);
//         const itemDate = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate());

//         return itemDate >= fromDateObj && itemDate <= toDateObj;
//       });
//     }

//     if (section) {
//       filtered = filtered.filter(item => item.stationPoint === section);
//     }

//     return filtered;
//   };

//   const handleFromDateChange = (event) => {
//     setFromDate(event.target.value);
//   };

//   const handleToDateChange = (event) => {
//     setToDate(event.target.value);
//   };

//   const downloadCSV = () => {
//     const filteredData = filterData();

//     const headers = [
//       'S No', 'deviceEUi', 'division', 'stationPoint', 'Date', 'Time', 'utcTimestamp', 'Vr', 'Vy', 'Vb',
//       'Vavg', 'Ir', 'Iy', 'Ib', 'Iavg', 'Imax', 'Fr', 'THDvr', 'THDvy', 'THDvb', 'THDir',
//       'THDiy', 'THDib', 'Vry', 'Vyb', 'Vbr', 'Pf', 'kW', 'kVA', 'kWh', 'KVArh', 'KVAh'
//     ];

//     const rows = filteredData.map((item, index) => [
//       index + 1, item.deviceEUi, item.division, item.stationPoint, item.Date, item.Time, item.utcTimestamp, item.Vr, item.Vy, item.Vb,
//       item.Vavg, item.Ir, item.Iy, item.Ib, item.Iavg, item.Imax, item.Fr, item.THDvr, item.THDvy, item.THDvb,
//       item.THDir, item.THDiy, item.THDib, item.Vry, item.Vyb, item.Vbr, item.Pf, item.kW, item.kVA, item.kWh,
//       item.KVArh, item.KVAh
//     ]);

//     let csvContent = 'data:text/csv;charset=utf-8,';
//     csvContent += headers.join(',') + '\n';
//     rows.forEach(row => {
//       csvContent += row.join(',') + '\n';
//     });

//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement('a');
//     link.setAttribute('href', encodedUri);
//     link.setAttribute('download', 'historydata.csv');
//     document.body.appendChild(link);

//     link.click();
//     document.body.removeChild(link);
//   };


//   const filteredData = filterData();

//   return (
//     <div id='historydata-total'>
//       <div>
//         <Operatorsidebar />
//       </div>
//       <div id='historydata-right'>
//         <div id='historydata-right-1'>
//           <div id='historydata-heading'>
//             {alertData2.alertHeading}
//           </div>
//           <div id='historydata-heading1'>
//             {alertData2.alertActions.map((action, index) => (
//               <button key={index} aria-label={action.label}>
//                 {action.icon}
//               </button>
//             ))}
//             <img src={alertData2.logoSrc} alt='logo' />
//           </div>
//         </div>

//         <div id='historydata-right-2'>
//           <div id='alert-right-21'>
//             <label>
//               <span>From date:</span>
//             <input type='date' value={fromDate} onChange={handleFromDateChange}></input>
//             </label>
//             <label>
//               <span>To date:</span>
//             <input type='date' value={toDate} onChange={handleToDateChange}></input>
//             </label>
//             <label>
//               <Stationpoints onStationPointChange={handleStationPointChange} />
//             </label>

//             <button onClick={downloadCSV}><FaFileCsv /></button>
//           </div>

//           <div className="table-wrapper">
//             <table>
//               <thead>
//                 <tr>
//                   <th>S. No.</th>
//                   {/* <th>Device EUI</th> */}
//                   <th>Division</th>
//                   <th>Section</th>
//                   {/* <th>utcTimestamp</th> */}
//                   <th>Date</th>
//                   <th>Time</th>
//                   <th>Vr</th>
//                   <th>Vy</th>
//                   <th>Vb</th>
//                   <th>Vavg</th>
//                   <th>Ir</th>
//                   <th>Iy</th>
//                   <th>Ib</th>
//                   <th>Iavg</th>
//                   <th>Imax</th>
//                   <th>Fr</th>
//                   <th>THDvr</th>
//                   <th>THDvy</th>
//                   <th>THDvb</th>
//                   <th>THDir</th>
//                   <th>THDiy</th>
//                   <th>THDib</th>
//                   <th>Vry</th>
//                   <th>Vyb</th>
//                   <th>Vbr</th>
//                   <th>Pf</th>
//                   <th>kW</th>
//                   <th>kVA</th>
//                   <th>kWh</th>
//                   <th>KVArh</th>
//                   <th>KVAh</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredData.length === 0 ? (
//                   <tr>
//                     <td colSpan="31">No data available for selected filters.</td>
//                   </tr>
//                 ) : (
//                   filteredData.map((item, index) => (
//                     <tr key={item._id}>
//                       <td>{index + 1}</td>
//                       {/* <td>{item.deviceEUi}</td> */}
//                       <td>{item.division}</td>
//                       <td>{item.stationPoint}</td>
//                       {/* <td>{item.utcTimestamp}</td> */}
//                       <td>{item.Date}</td>
//                       <td>{item.Time}</td>
//                       <td>{item.Vr}</td>
//                       <td>{item.Vy}</td>
//                       <td>{item.Vb}</td>
//                       <td>{item.Vavg}</td>
//                       <td>{item.Ir}</td>
//                       <td>{item.Iy}</td>
//                       <td>{item.Ib}</td>
//                       <td>{item.Iavg}</td>
//                       <td>{item.Imax}</td>
//                       <td>{item.Fr}</td>
//                       <td>{item.THDvr}</td>
//                       <td>{item.THDvy}</td>
//                       <td>{item.THDvb}</td>
//                       <td>{item.THDir}</td>
//                       <td>{item.THDiy}</td>
//                       <td>{item.THDib}</td>
//                       <td>{item.Vry}</td>
//                       <td>{item.Vyb}</td>
//                       <td>{item.Vbr}</td>
//                       <td>{item.Pf}</td>
//                       <td>{item.kW}</td>
//                       <td>{item.kVA}</td>
//                       <td>{item.kWh}</td>
//                       <td>{item.KVArh}</td>
//                       <td>{item.KVAh}</td>
//                     </tr>
//                   ))
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Historydata;



import React, { useEffect, useState, useRef } from 'react';
import './Historydata.css';
import Operatorsidebar from '../Sidebar/Sidebar';
import { alertData2 } from '../Data';
import Stationpoints from '../../Commonfns/Stationpoints';
import { FaFileCsv } from "react-icons/fa6";
import TopBar from '../../Commonfns/Topbar/Topbar';
// Helper function to get today's date in YYYY-MM-DD format
const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const dd = String(today.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

const Historydata = () => {
  const [data, setData] = useState([]);
  const [section, setSection] = useState('');
  const [fromDate, setFromDate] = useState(getTodayDate());
  const [toDate, setToDate] = useState(getTodayDate());
  const [filteredData, setFilteredData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 50; // Number of items to display initially and on each scroll

  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `${process.env.REACT_APP_API_EMS_URL}/api/historydata`;

        if (fromDate && toDate) {
          url += `?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
        }

        const response = await fetch(url);
        const result = await response.json();
        setData(result);
        setFilteredData(result);
        setDisplayedData(result.slice(0, itemsPerPage)); // Load the first batch of items
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [fromDate, toDate]);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = data;

      if (fromDate && toDate) {
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        fromDateObj.setHours(0, 0, 0, 0);
        toDateObj.setHours(23, 59, 59, 999);

        filtered = filtered.filter(item => {
          const year = parseInt(item.utcTimestamp.substring(0, 4));
          const month = parseInt(item.utcTimestamp.substring(4, 6)) - 1;
          const day = parseInt(item.utcTimestamp.substring(6, 8));
          const hours = parseInt(item.utcTimestamp.substring(8, 10));
          const minutes = parseInt(item.utcTimestamp.substring(10, 12));
          const seconds = parseInt(item.utcTimestamp.substring(12, 14));
          const timestamp = new Date(year, month, day, hours, minutes, seconds);
          const itemDate = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate());

          return itemDate >= fromDateObj && itemDate <= toDateObj;
        });
      }

      if (section) {
        filtered = filtered.filter(item => item.stationPoint === section);
      }

      setFilteredData(filtered);
      setDisplayedData(filtered.slice(0, page * itemsPerPage)); // Reset displayed data based on filters
    };

    applyFilters();
  }, [data, section, fromDate, toDate, page]);

  const loadMoreItems = () => {
    const newPage = page + 1;
    const newItems = filteredData.slice(0, newPage * itemsPerPage);
    setDisplayedData(newItems);
    setPage(newPage);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        tableRef.current &&
        tableRef.current.scrollTop + tableRef.current.clientHeight >= tableRef.current.scrollHeight
      ) {
        loadMoreItems();
      }
    };

    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [filteredData, page]);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const downloadCSV = () => {
    const headers = [
      'S No', 'deviceEUi', 'division', 'stationPoint', 'Date', 'Time', 'utcTimestamp', 'Vr', 'Vy', 'Vb',
      'Vavg', 'Ir', 'Iy', 'Ib', 'Iavg', 'Imax', 'Fr', 'THDvr', 'THDvy', 'THDvb', 'THDir',
      'THDiy', 'THDib', 'Vry', 'Vyb', 'Vbr', 'Pf', 'kW', 'kVA', 'kWh', 'KVArh', 'KVAh'
    ];

    const rows = filteredData.map((item, index) => [
      index + 1, item.deviceEUi, item.division, item.stationPoint, item.Date, item.Time, item.utcTimestamp, item.Vr, item.Vy, item.Vb,
      item.Vavg, item.Ir, item.Iy, item.Ib, item.Iavg, item.Imax, item.Fr, item.THDvr, item.THDvy, item.THDvb,
      item.THDir, item.THDiy, item.THDib, item.Vry, item.Vyb, item.Vbr, item.Pf, item.kW, item.kVA, item.kWh,
      item.KVArh, item.KVAh
    ]);

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    rows.forEach(row => {
      csvContent += row.join(',') + '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'historydata.csv');
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id='historydata-total'>
      <div>
        <Operatorsidebar />
      </div>
      <div id='historydata-right'>

        <TopBar section="HistorydataHeader" />

        <div id='historydata-right-2'>
          <div id='alert-right-21'>
            <label>
              <span>From date:</span>
              <input type='date' value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            </label>
            <label>
              <span>To date:</span>
              <input type='date' value={toDate} onChange={(e) => setToDate(e.target.value)} />
            </label>
            <label>
              <Stationpoints onStationPointChange={handleStationPointChange} />
            </label>
            <label>
              <button onClick={downloadCSV}><FaFileCsv /></button>

            </label>
          </div>

          <div className="table-wrapper">
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Division</th>
                  <th>Section</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Vr</th>
                  <th>Vy</th>
                  <th>Vb</th>
                  <th>Vavg</th>
                  <th>Ir</th>
                  <th>Iy</th>
                  <th>Ib</th>
                  <th>Iavg</th>
                  <th>Imax</th>
                  <th>Fr</th>
                  <th>THDvr</th>
                  <th>THDvy</th>
                  <th>THDvb</th>
                  <th>THDir</th>
                  <th>THDiy</th>
                  <th>THDib</th>
                  <th>Vry</th>
                  <th>Vyb</th>
                  <th>Vbr</th>
                  <th>Pf</th>
                  <th>kW</th>
                  <th>kVA</th>
                  <th>kWh</th>
                  <th>KVArh</th>
                  <th>KVAh</th>
                </tr>
              </thead>
              <tbody>
                {displayedData.length === 0 ? (
                  <tr>
                    <td colSpan="31">No data available for selected filters.</td>
                  </tr>
                ) : (
                  displayedData.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item.division}</td>
                      <td>{item.stationPoint}</td>
                      <td>{item.Date}</td>
                      <td>{item.Time}</td>
                      <td>{item.Vr}</td>
                      <td>{item.Vy}</td>
                      <td>{item.Vb}</td>
                      <td>{item.Vavg}</td>
                      <td>{item.Ir}</td>
                      <td>{item.Iy}</td>
                      <td>{item.Ib}</td>
                      <td>{item.Iavg}</td>
                      <td>{item.Imax}</td>
                      <td>{item.Fr}</td>
                      <td>{item.THDvr}</td>
                      <td>{item.THDvy}</td>
                      <td>{item.THDvb}</td>
                      <td>{item.THDir}</td>
                      <td>{item.THDiy}</td>
                      <td>{item.THDib}</td>
                      <td>{item.Vry}</td>
                      <td>{item.Vyb}</td>
                      <td>{item.Vbr}</td>
                      <td>{item.Pf}</td>
                      <td>{item.kW}</td>
                      <td>{item.kVA}</td>
                      <td>{item.kWh}</td>
                      <td>{item.KVAR}</td>
                      <td>{item.KVAh}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historydata;
