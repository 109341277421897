

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import './Unfilledforms.css';

// const formatMonth = (date) => {
//   const yyyy = date.getFullYear();
//   const mm = String(date.getMonth() + 1).padStart(2, '0');
//   return `${mm}-${yyyy}`; // Convert to MM-YYYY format
// };

// const parseMonthToDate = (monthString) => {
//   const [mm, yyyy] = monthString.split('-');
//   return new Date(`${yyyy}-${mm}-01`);
// };

// const MonthlyUnfilledforms = ({ setMonthlyCount }) => {
//   const [missingMonths, setMissingMonths] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const today = new Date();
//   const currentMonth = today.getMonth();
//   const currentYear = today.getFullYear();

//   const secondDayOfCurrentMonth = new Date(currentYear, currentMonth, 2);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // const firstFilledResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/first-filled-powerbillsdate`);
//         const firstFilledResponse = await axios.get(`http://localhost:3006/api/first-filled-powerbillsdate`);
//         const firstMonth = firstFilledResponse.data.firstFilledMonth;

//         const powerBillsResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/power-bills`);
//         const powerBillsEntries = Array.isArray(powerBillsResponse.data) ? powerBillsResponse.data : [];

//         const calculateMissingMonths = () => {
//           const missing = [];
//           const startDate = parseMonthToDate(firstMonth); // Convert to Date object
//           const endDate = new Date(currentYear, currentMonth);
        
//           for (let d = new Date(startDate); d <= endDate; d.setMonth(d.getMonth() + 1)) {
//             const formattedMonth = formatMonth(d);
//             const entryExists = powerBillsEntries.some(item => item.month === formattedMonth);
        
//             if (!entryExists) {
//               missing.push(formattedMonth);
//             }
//           }
        
//           // Check if current month data exists
//           const currentMonthFormatted = formatMonth(new Date(currentYear, currentMonth));
//           const currentMonthDataExists = powerBillsEntries.some(item => item.month === currentMonthFormatted);
        
//           if (!currentMonthDataExists && today >= secondDayOfCurrentMonth) {
//             missing.push(currentMonthFormatted);
//           }
        
//           return missing;
//         };

//         let missingMonthsList = calculateMissingMonths();

//         // Sort missing months in descending order
//         missingMonthsList = missingMonthsList.sort((a, b) => parseMonthToDate(b) - parseMonthToDate(a));

//         setMissingMonths(missingMonthsList);
//         setMonthlyCount(missingMonthsList.length);

//       } catch (err) {
//         setError('Error fetching data');
//         console.error('Error fetching data:', err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [currentMonth, currentYear, setMonthlyCount, today, secondDayOfCurrentMonth]);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div>
//       {missingMonths.map(month => (
//         <div id='dashunfilled-div' key={month}>
//           <div>No Power Bills data available for {month}. Please fill the form</div>
//             <Link to={`/Forms?section=PowerBills-1&month=${month}`}>
//               <button id='dashunfilled-divbtn'>Form</button>
//             </Link>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default MonthlyUnfilledforms;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Unfilledforms.css';

const formatMonth = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  return `${mm}-${yyyy}`; // Convert to MM-YYYY format
};

const parseMonthToDate = (monthString) => {
  const [mm, yyyy] = monthString.split('-');
  return new Date(`${yyyy}-${mm}-01`);
};

const MonthlyUnfilledforms = ({ setMonthlyCount }) => {
  const [missingMonths, setMissingMonths] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const secondDayOfCurrentMonth = new Date(currentYear, currentMonth, 2);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the first filled month from your API
        const firstFilledResponse = await axios.get(`http://localhost:3006/api/first-filled-powerbillsdate`);
        const firstMonth = firstFilledResponse.data.firstFilledMonth;

        // If the first filled month is "N/A", do not proceed with calculating missing months
        if (firstMonth === "N/A") {
          setMissingMonths([]);  // Clear the missing months list
          setMonthlyCount(0);    // Set the monthly count to 0
          return;                // Exit early as there's no need to proceed further
        }

        const powerBillsResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/power-bills`);
        const powerBillsEntries = Array.isArray(powerBillsResponse.data) ? powerBillsResponse.data : [];

        const calculateMissingMonths = () => {
          const missing = [];
          const startDate = parseMonthToDate(firstMonth); // Convert to Date object
          const endDate = new Date(currentYear, currentMonth);

          for (let d = new Date(startDate); d <= endDate; d.setMonth(d.getMonth() + 1)) {
            const formattedMonth = formatMonth(d);
            const entryExists = powerBillsEntries.some(item => item.month === formattedMonth);

            if (!entryExists) {
              missing.push(formattedMonth);
            }
          }

          // Check if current month data exists
          const currentMonthFormatted = formatMonth(new Date(currentYear, currentMonth));
          const currentMonthDataExists = powerBillsEntries.some(item => item.month === currentMonthFormatted);

          if (!currentMonthDataExists && today >= secondDayOfCurrentMonth) {
            missing.push(currentMonthFormatted);
          }

          return missing;
        };

        let missingMonthsList = calculateMissingMonths();

        // Sort missing months in descending order
        missingMonthsList = missingMonthsList.sort((a, b) => parseMonthToDate(b) - parseMonthToDate(a));

        setMissingMonths(missingMonthsList);
        setMonthlyCount(missingMonthsList.length);

      } catch (err) {
        setError('Error fetching data');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentMonth, currentYear, setMonthlyCount, today, secondDayOfCurrentMonth]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
   
        {missingMonths.map(month => (
          <div id='dashunfilled-div' key={month}>
            <div>No Power Bills data available for {month}. Please fill the form</div>
            <Link to={`/Forms?section=PowerBills-1&month=${month}`}>
              <button id='dashunfilled-divbtn'>Form</button>
            </Link>
          </div>
        ))
      }
    </div>
  );
};

export default MonthlyUnfilledforms;
