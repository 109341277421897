
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
// import './Designation.css'
// import { Designationheader } from '../../Operator/Data';
// const Designation = () => {

//     const [formData, setFormData] = useState({
//         level: '',
//         designation: '',
//         createdby: '',
//         modifiedby: ''
//     });
//     const [loggedInUser, setLoggedInUser] = useState(null);
//     const { designation, level } = formData;


//     useEffect(() => {
//         const user = JSON.parse(localStorage.getItem('user'));
//         if (user) {
//             setLoggedInUser(user);
//             //   console.log(user)
//             setFormData((prevData) => ({
//                 ...prevData,
//                 createdby: user.username
//             }));
//         }
//     }, []);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         const designationData = {
//             ...formData
//         };

//         try {
//             const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/designations`, designationData);
//             console.log('Designation created:', response.data);
//             setFormData({
//                 level: '',
//                 designation: '',
//                 createdby: loggedInUser.username,
//                 modifiedby: ''
//             });
//             alert('Designation Added Successfully');
//         } catch (error) {
//             console.error('Error creating designation:', error);
//             if (error.response && error.response.data.message === 'Designation already exists') {
//                 alert('Designation name already exists. Please choose a different name.');
//             } else {
//                 alert('Failed to add designation. Please try again later.');
//             }
//         }
//     };

//     return (

//         <div id='designation-total'>
//             <div>
//                 <Superadminsidebar />
//             </div>
//             <div id='designation-right'>
//                 <div id='designation-right-1'>
//                     <div id='designation-heading'>
//                         {Designationheader.alertHeading}
//                     </div>
//                     <div id='designation-heading1'>
//                         {Designationheader.alertActions.map((action, index) => (
//                             <button key={index} aria-label={action.label}>
//                                 {action.icon}
//                             </button>
//                         ))}
//                         <img src={Designationheader.logoSrc} alt='logo' />
//                     </div>
//                 </div>
//                 <div id='designation-right-2'>
//                     <form onSubmit={handleSubmit}>
//                         <div id='designation-divtop'>
//                             <label>
//                                 <span>User Name :</span>
//                                 <span>#123456</span>
//                             </label>
//                             <label>
//                                 <Link to='/DesignationTable'><button type="button">View Data</button></Link>
//                             </label>
//                         </div>
//                         <div id='designation-div1'>
//                             <label>
//                                 <span>Select level</span>
//                                 <select
//                                     name="level"
//                                     value={level}
//                                     onChange={handleChange}
//                                     required
//                                 >
//                                     <option value=''>Select Level</option>
//                                     <option value='Level-1'>Level-1</option>
//                                     <option value='Level-2'>Level-2</option>
//                                     <option value='Level-3'>Level-3</option>
//                                     <option value='Level-4'>Level-4</option>
//                                 </select>
//                             </label>
//                             <label>
//                                 <span>Designation Name</span>
//                                 <input
//                                     type="text"
//                                     name="designation"
//                                     value={designation}
//                                     onChange={handleChange}
//                                     required
//                                 />
//                             </label>
//                         </div>
//                         <button id='designation-save' type="submit">Create Designation</button>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Designation;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
import './Designation.css';
import { Designationheader } from '../../Operator/Data';

const Designation = () => {
  const [formData, setFormData] = useState({
    level: '',
    designation: '',
    modifiedby: ''
  });
  const [loggedInUser, setLoggedInUser] = useState(null);
  const { designation, level, createdby } = formData;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setLoggedInUser(user);
      setFormData((prevData) => ({
        ...prevData,
        createdby: user.username 
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const designationData = {
      ...formData,
      createdby: loggedInUser.username 
    };

    console.log('Submitting Designation Data:', designationData);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/designations`, designationData);
      console.log('Designation created:', response.data);
      alert('Designation Added Successfully');
      setFormData({
        level: '',
        designation: '',
        createdby: '',
        modifiedby: ''
      });
    } catch (error) {
      console.error('Error creating designation:', error);
      if (error.response && error.response.data.message === 'Designation already exists') {
        alert('Designation name already exists. Please choose a different name.');
      } else {
        alert('Failed to add designation. Please try again later.');
      }
    }
  };

  return (
    <div id='designation-total'>
      <div>
        <Superadminsidebar />
      </div>
      <div id='designation-right'>
        <div id='designation-right-1'>
          <div id='designation-heading'>
            {Designationheader.alertHeading}
          </div>
          <div id='designation-heading1'>
            {Designationheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Designationheader.logoSrc} alt='logo' />
          </div>
        </div>
        <div id='designation-right-2'>
          <form onSubmit={handleSubmit}>
            <div id='designation-divtop'>
              <label>
                <span>User Name :</span>
                <span>#123456</span>
              </label>
              <label>
                <Link to='/DesignationTable'><button type="button">View Data</button></Link>
              </label>
            </div>
            <div id='designation-div1'>
              <label>
                <span>Select level</span>
                <select
                  name="level"
                  value={level}
                  onChange={handleChange}
                  required
                >
                  <option value=''>Select Level</option>
                  <option value='Level-1'>Level-1</option>
                  <option value='Level-2'>Level-2</option>
                  <option value='Level-3'>Level-3</option>
                  <option value='Level-4'>Level-4</option>
                </select>
              </label>
              <label>
                <span>Designation Name</span>
                <input
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <button id='designation-save' type="submit">Create Designation</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Designation;


