import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Sections.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Sections = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        NAME: '',
        SELECT: '',
        Sectionid: '',
        "METER MAKE": '',
        "METER SL_NO": '',
        PHASE: '',
        "Load rating": '',
        "TYPE OF LOAD": '',
        "Total AMPS": '',
        "PROPOSED PERIODICITY OF MAINTENANCE": '',
        "PREVIOUS MAINTENANCE DONE DATE": '',
        MAC: '',
        Sid: '',
        Division: '',
        "Station point": '',
        Key: '',
        "Low voltage": '',
        "High voltage": '',
        "Low frequency": '',
        "High frequency": '',
        "High warning current": '',
        "High alert current": '',
        "High alert PF": '',
        "Low alert PF": '',
        "Full load current": '',
        "Idle Amps": '',
        "distributionpoint": ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                // "METER SL_NO": Number(formData["METER SL_NO"]),
                "Load rating": Number(formData["Load rating"]),
                "Total AMPS": Number(formData["Total AMPS"]),
                Sid: Number(formData.Sid),
                "Low voltage": Number(formData["Low voltage"]),
                "High voltage": Number(formData["High voltage"]),
                "Low frequency": Number(formData["Low frequency"]),
                "High frequency": Number(formData["High frequency"]),
                "High warning current": Number(formData["High warning current"]),
                "High alert current": Number(formData["High alert current"]),
                "High alert PF": Number(formData["High alert PF"]),
                "Low alert PF": Number(formData["Low alert PF"]),
                "Full load current": Number(formData["Full load current"]),
                "Idle Amps": Number(formData["Idle Amps"])
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/sections`, dataToSend);

            toast.success('Section added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='sections-total'>
            <div id='sections-right'>

                <div id='sections-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='sections-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.SectionsHeading}</span>
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="SELECT" value={formData.SELECT} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Name</span>
                                <input name="NAME" value={formData.NAME} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Meter Make</span>
                                <input name="METER MAKE" value={formData["METER MAKE"]} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Meter Serial No.</span>
                                <input name="METER SL_NO" value={formData["METER SL_NO"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Phase</span>
                                <select name="PHASE" value={formData.PHASE} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3 Phase</option>
                                    <option value='1-Phase-Vr'>1 Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1 Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1 Phase-Vb</option>
                                </select>
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Load Rating (in kVA)</span>
                                <input type='number' name="Load rating" value={formData["Load rating"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Type of Load</span>
                                <input name="TYPE OF LOAD" value={formData["TYPE OF LOAD"]} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Total AMPS</span>
                                <input type='number' name="Total AMPS" value={formData["Total AMPS"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Low Voltage</span>
                                <input type='number' name="Low voltage" value={formData["Low voltage"]} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>High Voltage</span>
                                <input type='number' name="High voltage" value={formData["High voltage"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Low Frequency</span>
                                <input type='number' name="Low frequency" value={formData["Low frequency"]} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>High Frequency</span>
                                <input type='number' name="High frequency" value={formData["High frequency"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>High Warning Current</span>
                                <input type='number' name="High warning current" value={formData["High warning current"]} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>High Alert Current</span>
                                <input type='number' name="High alert current" value={formData["High alert current"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>High Alert PF</span>
                                <input type='number' name="High alert PF" value={formData["High alert PF"]} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Low Alert PF</span>
                                <input type='number' name="Low alert PF" value={formData["Low alert PF"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Full Load Current</span>
                                <input type='number' name="Full load current" value={formData["Full load current"]} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Idle Amps</span>
                                <input type='number' name="Idle Amps" value={formData["Idle Amps"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>MAC</span>
                                <input name="MAC" value={formData.MAC} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Sid</span>
                                <input type='number' name="Sid" value={formData.Sid} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Division</span>
                                <input name="Division" value={formData.Division} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Station Point</span>
                                <input name="Station point" value={formData["Station point"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Key</span>
                                <input name="Key" value={formData.Key} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="PROPOSED PERIODICITY OF MAINTENANCE" value={formData["PROPOSED PERIODICITY OF MAINTENANCE"]} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type='date' name="PREVIOUS MAINTENANCE DONE DATE" value={formData["PREVIOUS MAINTENANCE DONE DATE"]} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='sections-div1'>
                            <label>
                                <span>Distribution Point</span>
                                <select name="distributionpoint" value={formData["distributionpoint"]} onChange={handleChange} required>
                                    <option value=''>Select Value</option>
                                    <option value='Feeding Point'>Feeding Point</option>
                                    <option value='Loading Point'>Loading Point</option>
                                </select>
                            </label>
                            
                        </div>
                        <div id='sections-divbottom'>
                            <label>
                                <button id='sections-save' type='submit'> {Masterformsheadingsss.Submitheading}</button>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Sections;
