
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Supervisorsidebar from '../../Supervisor/Supersidebar/Supersidebar';
import './Diagnosepage.css';
import { Diagnosesheader } from '../../Operator/Data';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaFilePdf } from "react-icons/fa6";
import Operatorsidebar from '../../Operator/Sidebar/Sidebar';
const Diagnose = () => {
  const { token_id } = useParams();
  const navigate = useNavigate();
  const [diagnoseData, setDiagnoseData] = useState([]);
  const [isAssignEnabled, setIsAssignEnabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [users, setUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showUserSelection, setShowUserSelection] = useState(false);
  const [accessedLevel, setAccessedLevel] = useState(null);

  useEffect(() => {
    // Fetch the accessed level from localStorage
    const level = localStorage.getItem('accessedLevel');
    setAccessedLevel(level);
  }, []);
  const conditionToRouteMap = {
    'Fill voltage regulator form': '/Voltageregulator',
    'Fill Voltage Regulator form again': '/Voltageregulator',
    'Fill Stabilizer form': '/Stabilizerform',
    'Fill Stabiliser form again': '/Stabilizerform',
    'Fill environmental factors form': '/EnvironmentalFactors',
    'Fill environmental factor form again': '/EnvironmentalFactors',
    'Fill grounding point form': '/VoltageGroundingresistance',
    'Fill grounding point form again': '/VoltageGroundingresistance',
    // ---------------powersurge
    'Fill the form Power Surge grounding point': '/Powersurge_Groundingresistance',
    'Fill Power Surge grounding point form again': '/Powersurge_Groundingresistance',
    'Fill the form Internal Fault': '/Internal_Fault',
    'Fill Internal Fault Diagnose Manual Entry Form Again': '/Internal_Fault',
    // ---------Harmonics
    'Fill the Harmonic Form': '/Harmonic_Form',
    'Fill Harmonic Form again': '/Harmonic_Form',
    'Fill the Power Quality Form': '/Power_Quality_Form',
    'Fill Power Quality Form again': '/Power_Quality_Form',
    // --------------unbalancedloads
    'Fill the Impedance Test Form': '/Impedance_Test_Form',
    'Fill Impedance Test Form again': '/Impedance_Test_Form',
    'Fill the Oil Winding Temperature Form': '/Oil_Winding_Temperature_Form',
    'Fill Oil Winding Temperature Form again': '/Oil_Winding_Temperature_Form',
    'Fill the Loose Connection and corrostion Form': '/Loose_Connection_and_Corrosion_Form',
    'Fill Loose Connection and corrostion Form again': '/Loose_Connection_and_Corrosion_Form',
    'Fill the Hotspot Test Form': '/Hotspot_Test_Form',
    'Fill Hotspot Test Form again': '/Hotspot_Test_Form',
    // ----------lowpf
    'Fill the Capacitance Manual Form': '/Capacitance_Manual_Form',
    'Fill Capacitance Manual Form again': '/Capacitance_Manual_Form',
    // ----------highpf
    'Fill the APFC Signalling Manual Form': '/APFC_Signalling_Manual_Form',
    'Fill APFC Signalling Manual Form again': '/APFC_Signalling_Manual_Form',
    // Add more conditions and routes here as needed...
  };

  useEffect(() => {
    const fetchVoltageData = async () => {
      try {
        const responses = await Promise.all([
          fetch(`${process.env.REACT_APP_API_EMS_URL}/api/voltagediagnose`),
          fetch(`${process.env.REACT_APP_API_EMS_URL}/api/powersurgediagnose`),
          fetch(`${process.env.REACT_APP_API_EMS_URL}/api/unbalncedloaddiagnose`),
          fetch(`${process.env.REACT_APP_API_EMS_URL}/api/leadingpfdiagnose`),
          fetch(`${process.env.REACT_APP_API_EMS_URL}/api/lowpfdiagnose`),
          fetch(`${process.env.REACT_APP_API_EMS_URL}/api/thddiagnose`),
        ]);
        const data = await Promise.all(responses.map(response => response.json()));
        const allData = data.flat();
        const filteredData = allData.filter(item => item.token_id === token_id);

        console.log("Fetched and filtered voltage data for token ID:", token_id, filteredData);
        setDiagnoseData(filteredData);

        // Determine if the button should be disabled and if user selection should be shown
        const condition = filteredData[0]?.condition;
        const isKnownCondition = conditionToRouteMap.hasOwnProperty(condition);
        setIsButtonDisabled(!isKnownCondition);
        setShowUserSelection(isKnownCondition);

      } catch (error) {
        console.error('Error fetching voltage data:', error);
      }
    };

    fetchVoltageData();
  }, [token_id]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/users`);
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setLoggedInUser(user);
    }
  }, []);

  const handleUserSelect = (event) => {
    const user = event.target.value;
    setSelectedUser(user);
    setIsAssignEnabled(user !== '');
  };

  const handleAssign = async () => {
    const user = users.find(user => user.username === selectedUser);

    if (user && loggedInUser) {
      const assignedTaskDetails = {
        token_id: token_id,
        alert_heading: diagnoseData[0]?.alert_heading,
        assignedto: selectedUser,
        assignedtouserid: user.user_id,
        assignedtouserlevel: user.level,
        assignedtodesignation: user.designation,
        assigneeuserlevel: loggedInUser.level,
        assigneedesignation: loggedInUser.designation,
        createdby: loggedInUser.username,
        modifiedby: loggedInUser.username,
        status: 'TO-DO',
        assinedat: '',
        completedat: '',
        closedat: '',
        reassignedat: '',
      };

      console.log("Assigned Task Details:", assignedTaskDetails);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(assignedTaskDetails),
        });

        if (response.ok) {
          console.log('Assigned task details successfully posted.');
          alert('Task Assigned Sucessfully')
        } else {
          console.error('Failed to post assigned task details.');
        }
      } catch (error) {
        console.error('Error posting assigned task details:', error);
      }

      setSelectedUser('');
      setIsAssignEnabled(false);
    } else {
      console.error("User or logged-in user information is missing.");
    }
  };

  const handleDoItLater = async () => {
    if (loggedInUser) {
      const taskDetails = {
        token_id: token_id,
        alert_heading: diagnoseData[0]?.alert_heading,
        createdby: loggedInUser.username,
      };

      console.log("Do it Later Task Details:", taskDetails);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks/mark-as-do-it-later`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(taskDetails),
        });

        if (response.ok) {
          console.log('Task marked as DO IT LATER successfully.');
          alert("Task added to DO IT LATER");
        } else {
          console.error('Failed to mark task as DO IT LATER.');
        }
      } catch (error) {
        console.error('Error marking task as DO IT LATER:', error);
      }
    } else {
      console.error("Logged-in user information is missing.");
    }
  };

  const handleOpenForm = () => {
    const condition = diagnoseData[0]?.condition;
    const route = conditionToRouteMap[condition];

    if (route) {
      navigate(`${route}/${token_id}`);
    } else {
      console.error('Unknown condition:', condition);
    }
  };


  const handleSaveAsPDF = () => {
    const input = document.getElementById('diagnosepage-right');
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth;
      const imgHeight = canvas.height * pdfWidth / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(`Diagnose_${token_id}.pdf`);
    });
  };



  return (
    <div id='diagnosepage-total'>
      <div>
        {accessedLevel === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
      </div>
      <div id='diagnosepage-right'>
        <div id='diagnosepage-right-1'>
          <div id='diagnosepage-heading'>
            {diagnoseData[0]?.alert_heading}
          </div>
          <div id='diagnosepage-heading1'>
            {Diagnosesheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Diagnosesheader.logoSrc} alt='logo' />
          </div>
        </div>

        <div id='diagnosepage-right-2'>
          <div id='diagnosepage-right-21'>
            <p>Problems:</p>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_1}</span>  <span>{diagnoseData[0]?.head_1_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_2}</span>  <span>{diagnoseData[0]?.head_2_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_3}</span> <span>{diagnoseData[0]?.head_3_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_21}</span> <span>{diagnoseData[0]?.head_21_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_22}</span> <span>{diagnoseData[0]?.head_22_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_23}</span> <span>{diagnoseData[0]?.head_23_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_24}</span> <span>{diagnoseData[0]?.head_24_subhead}</span>
            </label>
          </div>

          <div id='diagnosepage-right-21'>
            <p>Effect on Machine Performance:</p>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_4} </span> <span>{diagnoseData[0]?.head_4_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_5} </span> <span>{diagnoseData[0]?.head_5_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_6} </span> <span>{diagnoseData[0]?.head_6_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_7} </span> <span>{diagnoseData[0]?.head_7_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_8} </span> <span>{diagnoseData[0]?.head_8_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_9} </span> <span>{diagnoseData[0]?.head_9_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_10} </span> <span>{diagnoseData[0]?.head_10_subhead}</span>
            </label>
            <label>
              <span id='diagnosepage-prob'>{diagnoseData[0]?.head_11} </span> <span>{diagnoseData[0]?.head_11_subhead}</span>
            </label>
          </div>
          <div id='diagnosepage-right-3'>
            <div className='diagnosepage-data'>
              {diagnoseData.map((data, index) => (
                <div key={index} className='diagnosepage-data-item'>
                  <p><strong>Token ID:</strong> {data.token_id}</p>
                  <p><strong>Section:</strong> {data.section}</p>
                  <p><strong>Alert Parameter:</strong> {data.critical_alert_parameter}</p>
                  <p><strong>Critical Alert Value:</strong> {data.critical_alert_value}</p>
                  <p><strong>Start Time:</strong> {data.start_time}</p>
                  <p><strong>Suspected Section:</strong> {data.suspected_sections?.join(', ')}</p>
                </div>
              ))}
            </div>
          </div>

          <div id='diagnosepage-right-lastcontent'>
            <div>
              <p><strong>Status:</strong> {diagnoseData[0]?.condition}</p>
            </div>
            {conditionToRouteMap.hasOwnProperty(diagnoseData[0]?.condition) && (
              <div>
                <label>
                  <button onClick={handleOpenForm} disabled={isButtonDisabled}>
                    Open form
                  </button>
                </label>
              </div>
            )}
          </div>

          <div id='diagnosepage-right-4'>
            <strong>Suggestions:</strong>
            <div className='diagnosepage-suggestion'>
              <p>{diagnoseData[0]?.suggestion}</p>
            </div>

            {loggedInUser?.level !== 'Level-4' && showUserSelection && (
              <div id='diagnosepage-right-4-content'>
                <div>
                  <select
                    value={selectedUser}
                    onChange={handleUserSelect}
                  >
                    <option value="">Select the User</option>
                    {users.map(user => (
                      <option key={user._id} value={user.username}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={handleAssign}
                    className={isAssignEnabled ? 'enabled' : 'disabled'}
                    disabled={!isAssignEnabled}
                  >
                    Assign
                  </button>
                </div>
                <div>
                  <button
                    onClick={handleDoItLater}
                    id='diagnosepage-dolater'
                  >
                    Do it Later
                  </button>
                </div>
              </div>
            )}
          </div>
          <div id='diagnosepage-pdfbtn'>
            <button onClick={handleSaveAsPDF}><FaFilePdf /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diagnose;


