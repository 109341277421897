import { useState, React } from "react";
import Status from "./Status";
import Analog from "./Analog";
import Accumulator from "./Accumulator";
import Operatorsidebar from "../Sidebar/Sidebar";
import { Operatorheadingss } from "../Data";
import "./Sectionpoints.css";
import Stationpoints from "../../Commonfns/Stationpoints";
import TopBar from "../../Commonfns/Topbar/Topbar";
const Sectionpoints = () => {
  const [activeSection, setActiveSection] = useState("Status-1");
  const [section, setSection] = useState("");

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  return (
    <div id="sectionpoints-total">
      <div>
        <Operatorsidebar />
      </div>
      <div id="sectionpoints-right">
        {/* <div id='sectionpoints-right-1'>
                    <div id='sectionpoints-heading'>
                        {Operatorheadingss.SectionsHeader}
                    </div>
                    <div id='sectionpoints-heading1'>
                        {Operatorheadingss.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Operatorheadingss.logoSrc} alt='logo' />
                    </div>
                </div> */}

        <TopBar section="SectionsHeader" />

        <div id="sectionpoints-right-2">
          <div id="sectionpoints-right-21">
            <div id="sectionpoints-right-23">
              <button
                className={activeSection === "Status-1" ? "active" : ""}
                onClick={() => handleButtonClick("Status-1")}
              >
                Status
              </button>
              <button
                className={activeSection === "Analog-1" ? "active" : ""}
                onClick={() => handleButtonClick("Analog-1")}
              >
                Data Logs
              </button>
              <button
                className={activeSection === "Accumulator-1" ? "active" : ""}
                onClick={() => handleButtonClick("Accumulator-1")}
              >
                Accumulator
              </button>
            </div>

            <div id="sectionpoints-right-22">
              <label>
                <Stationpoints
                  onStationPointChange={handleStationPointChange}
                />
              </label>
            </div>
          </div>

          <div id="sectionpoints-right-3">
            <div>
              <div>
                {activeSection === "Status-1" && (
                  <Status selectedSection={section} />
                )}
                {activeSection === "Analog-1" && (
                  <Analog selectedSection={section} />
                )}
                {activeSection === "Accumulator-1" && (
                  <Accumulator selectedSection={section} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sectionpoints;
