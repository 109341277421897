// import React, { useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';

// const SuperadminRegistration = () => {
//   const [formData, setFormData] = useState({
//     designation: '',
//     name: '',
//     username: '',
//     password: '',
//     confirmpassword: '',
//     createdby: '',
//     modifiedby: ''
//   });

//   const { designation, name, username, password, confirmpassword } = formData;

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (password !== confirmpassword) {
//         alert('Passwords do not match. Please re-enter.');
//         return;
//       }

//     const superadminData = {
//       ...formData
//     };

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/superadmins`, superadminData);
//       console.log('Superadmin created:', response.data);
//       setFormData({
//         designation: '',
//         name: '',
//         username: '',
//         password: '',
//         confirmpassword: '',
//         createdby: '',
//         modifiedby: ''
//       });
//       alert('Superadmin Registered Successfully');
//     } catch (error) {
//       console.error('Error creating superadmin:', error);
//       if (error.response && error.response.data.message === 'Username already exists') {
//         alert('Username already exists. Please choose a different username.');
//       } else {
//         alert('Failed to register superadmin. Please try again later.');
//       }
//     }
//   };

//   return (
//     <div>
//       <Link to='/SuperadminTable'>Fetch</Link>
//       <form onSubmit={handleSubmit}>
//         <label>
//           <span>Designation</span>
//           <input
//             type="text"
//             name="designation"
//             value={designation}
//             onChange={handleChange}
//             required
//           />
//         </label>
//         <label>
//           <span>Name</span>
//           <input
//             type="text"
//             name="name"
//             value={name}
//             onChange={handleChange}
//             required
//           />
//         </label>
//         <label>
//           <span>Username</span>
//           <input
//             type="text"
//             name="username"
//             value={username}
//             onChange={handleChange}
//             required
//           />
//         </label>
//         <label>
//           <span>Password</span>
//           <input
//             type="password"
//             name="password"
//             value={password}
//             onChange={handleChange}
//             required
//           />
//         </label>
//         <label>
//           <span>Confirm Password</span>
//           <input
//             type="password"
//             name="confirmpassword"
//             value={confirmpassword}
//             onChange={handleChange}
//             required
//           />
//         </label>
//         <button type="submit">Register Superadmin</button>
//       </form>
//     </div>
//   );
// };

// export default SuperadminRegistration;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';

// const SuperadminRegistration = () => {
//   const [formData, setFormData] = useState({
//     level:'',
//     designation: '',
//     name: '',
//     username: '',
//     password: '',
//     confirmpassword: '',
//     createdby: '',
//     modifiedby: ''
//   });

//   const [errors, setErrors] = useState({});

//   const {level, designation, name, username, password, confirmpassword } = formData;

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     let errorMessages = { ...errors };

//     // Validation for username
//     if (name === 'username') {
//       const usernameRegex = /^[a-z0-9]+$/; // Only lowercase letters and numbers
//       if (!usernameRegex.test(value)) {
//         errorMessages.username = 'Username should only contain lowercase letters and numbers.';
//       } else {
//         delete errorMessages.username;
//       }
//     }

//     // Validation for password and confirm password
//     if (name === 'password' || name === 'confirmpassword') {
//       if (name === 'password' && value === username) {
//         errorMessages.password = 'Password should not be the same as Username.';
//       } else if (name === 'confirmpassword' && value !== formData.password) {
//         errorMessages.confirmpassword = 'Passwords do not match. Please re-enter.';
//       } else {
//         delete errorMessages.password;
//         delete errorMessages.confirmpassword;
//       }
//     }

//     setErrors(errorMessages);
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (password !== confirmpassword) {
//       setErrors({ ...errors, confirmpassword: 'Passwords do not match. Please re-enter.' });
//       alert('Please fix the alerts before submitting.');
//       return;
//     }

//     const superadminData = {
//       ...formData
//     };

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/superadmins`, superadminData);
//       console.log('Superadmin created:', response.data);
//       setFormData({
//         level:'',
//         designation: '',
//         name: '',
//         username: '',
//         password: '',
//         confirmpassword: '',
//         createdby: '',
//         modifiedby: ''
//       });
//       alert('Superadmin Registered Successfully');
//     } catch (error) {
//       console.error('Error creating superadmin:', error);
//       if (error.response && error.response.data.message === 'Username already exists') {
//         alert('Username already exists. Please choose a different username.');
//       } else {
//         alert('Failed to register superadmin. Please try again later.');
//       }
//     }
//   };

//   const [designations, setDesignations] = useState([]);
//   useEffect(() => {
//     fetchDesignations();
//   }, []);

//   const fetchDesignations = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
//       setDesignations(response.data);
//     } catch (error) {
//       console.error('Error fetching designations:', error);
//     }
//   };

//   return (
//     <div>
//       <Link to='/SuperadminTable'>Fetch</Link>
//       <form onSubmit={handleSubmit}>
//         <label>
//           <span>Level</span>
//           <input
//           type="text"
//           name="level"
//           value={level}
//           onChange={handleChange}
//           required
//           disabled>
//           </input>
//         </label>
//         <label>
//           <span>Designation</span>
//           <input
//           type="text"
//           name="designation"
//           value={designation}
//           onChange={handleChange}
//           required
//           disabled>
//           </input>
//         </label>
//         <label>
//           <span>Name</span>
//           <input
//             type="text"
//             name="name"
//             value={name}
//             onChange={handleChange}
//             required
//           />
//         </label>
//         <label>
//           <span>Username</span>
//           <input
//             type="text"
//             name="username"
//             value={username}
//             onChange={handleChange}
//             required
//           />
//           {errors.username && <div style={{ color: 'red' }}>{errors.username}</div>}
//         </label>
//         <label>
//           <span>Password</span>
//           <input
//             type="password"
//             name="password"
//             value={password}
//             onChange={handleChange}
//             required
//           />
//           {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
//         </label>
//         <label>
//           <span>Confirm Password</span>
//           <input
//             type="password"
//             name="confirmpassword"
//             value={confirmpassword}
//             onChange={handleChange}
//             required
//           />
//           {errors.confirmpassword && <div style={{ color: 'red' }}>{errors.confirmpassword}</div>}
//         </label>
//         <button type="submit">Register Superadmin</button>
//       </form>
//     </div>
//   );
// };

// export default SuperadminRegistration;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './SuperadminRegistration.css'
import { Higherlevelheader } from '../../Operator/Data';
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';

const SuperadminRegistration = () => {
  const [formData, setFormData] = useState({
    level: 'Level-1',
    designation: '',
    name: '',
    username: '',
    password: '',
    confirmpassword: '',
    createdby: '',
    modifiedby: ''
  });

  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setLoggedInUser(user);
      setFormData((prevData) => ({
        ...prevData,
        createdby: user.username
      }));
    }
  }, []);

  const [errors, setErrors] = useState({});

  const { level, designation, name, username, password, confirmpassword } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;

    let errorMessages = { ...errors };

    // Validation for username
    if (name === 'username') {
      const usernameRegex = /^[a-z0-9]+$/; // Only lowercase letters and numbers
      if (!usernameRegex.test(value)) {
        errorMessages.username = 'Username should only contain lowercase letters and numbers.';
      } else {
        delete errorMessages.username;
      }
    }

    // Validation for password and confirm password
    if (name === 'password' || name === 'confirmpassword') {
      if (name === 'password' && value === username) {
        errorMessages.password = 'Password should not be the same as Username.';
      } else if (name === 'confirmpassword' && value !== formData.password) {
        errorMessages.confirmpassword = 'Passwords do not match. Please re-enter.';
      } else {
        delete errorMessages.password;
        delete errorMessages.confirmpassword;
      }
    }

    setErrors(errorMessages);
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmpassword) {
      setErrors({ ...errors, confirmpassword: 'Passwords do not match. Please re-enter.' });
      alert('Please fix the alerts before submitting.');
      return;
    }

    const superadminData = {
      ...formData
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/superadmins`, superadminData);
      console.log('Superadmin created:', response.data);
      setFormData({
        level: 'Level-1',
        designation: '',
        name: '',
        username: '',
        password: '',
        confirmpassword: '',
        createdby: '',
        modifiedby: ''
      });
      alert('Superadmin Registered Successfully');
    } catch (error) {
      console.error('Error creating superadmin:', error);
      if (error.response && error.response.data.message === 'Username already exists') {
        alert('Username already exists. Please choose a different username.');
      } else {
        alert('Failed to register superadmin. Please try again later.');
      }
    }
  };

  useEffect(() => {
    fetchDesignationForLevel1();
  }, []);

  const fetchDesignationForLevel1 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
      const level1Designation = response.data.find(desig => desig.level === 'Level-1');
      if (level1Designation) {
        setFormData(prevData => ({
          ...prevData,
          designation: level1Designation.designation
        }));
      }
    } catch (error) {
      console.error('Error fetching designations:', error);
    }
  };

  return (


    <div id='super-reg-total'>
      <div>
        <Superadminsidebar />
      </div>
      <div id='super-reg-right'>
        <div id='super-reg-right-1'>
          <div id='super-reg-heading'>
            {Higherlevelheader.alertHeading}
          </div>
          <div id='super-reg-heading1'>
            {Higherlevelheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Higherlevelheader.logoSrc} alt='logo' />
          </div>
        </div>
        <div id='super-reg-right-2'>
          <form onSubmit={handleSubmit}>
            <div id='super-reg-divtop'>
              <label>
                <span>User Name :</span>
                <span>#123456</span>
              </label>
              <label>
                <Link to='/SuperadminTable'><button type="button">View Data</button></Link>
              </label>
            </div>
            <div id='super-reg-div1'>
              <label>
                <span>Level</span>
                <input
                  type="text"
                  name="level"
                  value={level}
                  onChange={handleChange}
                  required
                  disabled
                />
              </label>
              <label>
                <span>Designation</span>
                <input
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={handleChange}
                  required
                  disabled
                />
              </label>
            </div>
            <div id='super-reg-div1'>
              <label>
                <span>Name</span>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                <span>Username</span>
                <input
                  type="text"
                  name="username"
                  value={username}
                  onChange={handleChange}
                  required
                />
                {errors.username && <div style={{ color: 'red' }}>{errors.username}</div>}
              </label>
            </div>
            <div id='super-reg-div1'>
              <label>
                <span>Password</span>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  required
                />
                {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
              </label>
              <label>
                <span>Confirm Password</span>
                <input
                  type="password"
                  name="confirmpassword"
                  value={confirmpassword}
                  onChange={handleChange}
                  required
                />
                {errors.confirmpassword && <div style={{ color: 'red' }}>{errors.confirmpassword}</div>}
              </label>
            </div>
            <div id='super-reg-div1'>

            </div>
            <button id='super-reg-save' type="submit">Register Superadmin</button>
          </form>
        </div>
      </div>
    </div>


    // <div>
    //   <Link to='/SuperadminTable'>Fetch</Link>
    //   <form onSubmit={handleSubmit}>
    //     <label>
    //       <span>Level</span>
    //       <input
    //         type="text"
    //         name="level"
    //         value={level}
    //         onChange={handleChange}
    //         required
    //         disabled
    //       />
    //     </label>
    //     <label>
    //       <span>Designation</span>
    //       <input
    //         type="text"
    //         name="designation"
    //         value={designation}
    //         onChange={handleChange}
    //         required
    //         disabled
    //       />
    //     </label>
    //     <label>
    //       <span>Name</span>
    //       <input
    //         type="text"
    //         name="name"
    //         value={name}
    //         onChange={handleChange}
    //         required
    //       />
    //     </label>
    //     <label>
    //       <span>Username</span>
    //       <input
    //         type="text"
    //         name="username"
    //         value={username}
    //         onChange={handleChange}
    //         required
    //       />
    //       {errors.username && <div style={{ color: 'red' }}>{errors.username}</div>}
    //     </label>
    //     <label>
    //       <span>Password</span>
    //       <input
    //         type="password"
    //         name="password"
    //         value={password}
    //         onChange={handleChange}
    //         required
    //       />
    //       {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
    //     </label>
    //     <label>
    //       <span>Confirm Password</span>
    //       <input
    //         type="password"
    //         name="confirmpassword"
    //         value={confirmpassword}
    //         onChange={handleChange}
    //         required
    //       />
    //       {errors.confirmpassword && <div style={{ color: 'red' }}>{errors.confirmpassword}</div>}
    //     </label>
    //     <button type="submit">Register Superadmin</button>
    //   </form>
    // </div>
  );
};

export default SuperadminRegistration;
