

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Revenuelosspending.css'
const DashRevenueloss_Actions = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-utilizationpercantage`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-transformerloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-voltagediagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-lowpfdiagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-powersurgediagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-idleloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-todloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-leadingpfdiagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-unbalncedloaddiagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-thddiagnose`),
                ]);

                const allData = responses.flatMap(response => response.data);
                console.log('Fetched data:', allData); // Add this line to log fetched data
                setData(allData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleLocate = (item) => {
        console.log('Navigating with item:', item); // Add this line to log item details

        let route = '';
        switch (item.alert_heading) {
            case 'Utilization Loss':
                route = '/Utilization_Loss_Revenue_Diagnose';
                break;
            case 'Transformer Loss':
                route = '/Transformer_Loss_Revenue_Diagnose';
                break;
            case 'Voltage Diagnoses':
                route = '/Voltagediagnose';
                break;
            case 'Low PF':
                route = '/LowPFdiagnose';
                break;
            case 'Power Surges':
                route = '/Powersurgediagnose';
                break;
            case 'Idle Loss':
                route = '/Idle_Loss_Revenue_Diagnose';
                break;
            case 'TOD Loss':
                route = '/TOD_loss_Revenue_Diagnose';
                break;
            case 'Unbalanced Loads':
                route = '/Unbalancedloaddiagnose';
                break;
            case 'Harmonics Distortion':
                route = '/Harmonicdistortiondiagnose';
                break;
            case 'Leading PF':
                route = '/LeadPFdiagnose';
                break;

            default:
                console.warn('Unknown alert heading:', item.alert_heading);
        }

        navigate(route, { state: { selectedMonth: item.Month } });
    };

    return (
        <div id='dashrevenuelosscount-total' >
            {data.length > 0 ? (
                data.map((item, index) => (
                    <div key={index} id='dashrevenuelosscount-content'>
                        <div >
                            <strong  >Date:</strong>
                            <span>{item.Month}</span>
                        </div>
                        <div>
                            <strong  >Type of Revenue Loss:</strong>
                            <span>{item.alert_heading}</span>
                        </div>
                        <div>
                            <button onClick={() => handleLocate(item)}>Open</button>
                        </div>
                    </div>
                ))
            ) : (
                    <div className="content-value">No Pending Revenueloss Suggestions available</div>
            )}
        </div>

    );
};

export default DashRevenueloss_Actions;

