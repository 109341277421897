// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dashunfilled-total{
    padding: 10px;
    overflow-y: scroll;
    height:66vh;
    width: 26.85vw;

}
#dashunfilled-div{
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 1.5vh;
    font-size: 12.5px;
    justify-content: space-between;
}
#dashunfilled-divbtn{
    padding: 3px 10px;
    background-color: #F3F6FE;
    border: 1px solid #d3d9e7;
    border-radius: 4px;
    cursor: pointer;
}

#unfilled-message{
    font-size: 12.5px;
}

@media screen and (min-width: 1024px) {
    
}

@media screen and (min-width: 1440px) {
    #dashunfilled-total{
        width: 27.28vw;
    }
    #dashunfilled-div{
        justify-content: space-between;
    }
   
}
@media screen and (min-width: 1920px) {
    #dashunfilled-total{
        width: 27.65vw;
    }
    #dashunfilled-div{
        justify-content: space-between;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Tab1/DashPending_Task/Unfilledforms/Unfilledforms.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,cAAc;;AAElB;AACA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;IACjB,8BAA8B;AAClC;AACA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;AAEA;;AAEA;IACI;QACI,cAAc;IAClB;IACA;QACI,8BAA8B;IAClC;;AAEJ;AACA;IACI;QACI,cAAc;IAClB;IACA;QACI,8BAA8B;IAClC;AACJ","sourcesContent":["#dashunfilled-total{\n    padding: 10px;\n    overflow-y: scroll;\n    height:66vh;\n    width: 26.85vw;\n\n}\n#dashunfilled-div{\n    display: flex;\n    gap: 5px;\n    align-items: center;\n    margin-top: 1.5vh;\n    font-size: 12.5px;\n    justify-content: space-between;\n}\n#dashunfilled-divbtn{\n    padding: 3px 10px;\n    background-color: #F3F6FE;\n    border: 1px solid #d3d9e7;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n#unfilled-message{\n    font-size: 12.5px;\n}\n\n@media screen and (min-width: 1024px) {\n    \n}\n\n@media screen and (min-width: 1440px) {\n    #dashunfilled-total{\n        width: 27.28vw;\n    }\n    #dashunfilled-div{\n        justify-content: space-between;\n    }\n   \n}\n@media screen and (min-width: 1920px) {\n    #dashunfilled-total{\n        width: 27.65vw;\n    }\n    #dashunfilled-div{\n        justify-content: space-between;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
