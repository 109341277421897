


import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import './Homealerts.css';
import { Link } from 'react-router-dom';

const MAX_ALERTS = 100;
const LOCAL_ALERTS_KEY = 'alerts';

const Homealerts = () => {
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        // Load initial alerts from local storage
        const storedAlerts = JSON.parse(localStorage.getItem(LOCAL_ALERTS_KEY)) || [];
        const initialAlerts = storedAlerts.slice(0, 100); // Get only the latest 10 alerts
        setAlerts(initialAlerts);

        const socket = io('http://localhost:5000');

        socket.on('mqtt_alert', (data) => {
            console.log('Alert received:', data);
            const alertKey = `${data.stationPoint}-${data.parameterName}-${data.eventDescription}`;

            setAlerts((prevAlerts) => {
                const existingAlertIndex = prevAlerts.findIndex(alert => alert.key === alertKey);

                let newAlerts;
                if (existingAlertIndex !== -1) {
                    // Update the existing alert
                    newAlerts = [...prevAlerts];
                    newAlerts[existingAlertIndex] = {
                        ...newAlerts[existingAlertIndex],
                        eventValue: data.eventValue,
                        dateTime: data.dateTime,
                        start_time: data.start_time
                    };
                } else {
                    // Add a new alert
                    newAlerts = [{ ...data, key: alertKey }, ...prevAlerts];
                }

                // Ensure we do not exceed MAX_ALERTS
                if (newAlerts.length > MAX_ALERTS) {
                    newAlerts = newAlerts.slice(0, MAX_ALERTS);
                }

                localStorage.setItem(LOCAL_ALERTS_KEY, JSON.stringify(newAlerts.slice(0, 10))); // Update local storage
                return newAlerts;
            });
        });

        socket.on('connect_error', (err) => {
            console.error('Connection Error:', err);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <div id='opehomealerts-total'>
            <div id='opehomealerts-right'>
                <div id='opehomealerts-right-2'>
                    <div id='opehomealerts-right-21'>
                        <div id='opehomealerts-topflex'>
                            <span id='opehomealerts-alert'>Alerts</span>
                             <Link to='/Alertspage?section=OperatorAlertstable-1'>
                                    <button id='opehomealerts-viewmore'>View More</button>
                                </Link>
                        </div>

                        <div id='opehomealerts-right-211'>
                            {alerts.map((alert) => (
                                <div key={alert.key} className="opehomealerts-item">
                                    <div id="opehomealerts-details">
                                        <div id='opehomealerts-div'>
                                            <label>
                                                <strong>Section:</strong>
                                                <span>{alert.stationPoint}</span>
                                            </label>
                                            <label>
                                                <strong>Parameter Name:</strong>
                                                <span>{alert.parameterName}</span>
                                            </label>
                                        </div>
                                        <div id='opehomealerts-div'>
                                            <label>
                                                <strong>Event Description:</strong>
                                                <span>{alert.eventDescription}</span>
                                            </label>
                                            <label>
                                                <strong>Event Type:</strong>
                                                <span>{alert.eventType}</span>
                                            </label>
                                        </div>
                                        <div id='opehomealerts-div'>
                                            <label>
                                                <strong>Event Value:</strong>
                                                <span>{alert.eventValue}</span>
                                            </label>
                                            <label>
                                                <strong>Start Time:</strong>
                                                <span>{alert.start_time}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Homealerts;

