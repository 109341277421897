
import React, { useEffect, useState } from 'react';
import Operatorsidebar from '../Sidebar/Sidebar';
import { Operatorheadingss } from '../../Operator/Data';
import './DailyConsumption.css';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import DailyConsumptionprint from './Dailyconsumptionprint';
import TopBar from '../../Commonfns/Topbar/Topbar';
const DailyConsumption = () => {
    const [dailyconsumptionData, setDailyconsumptionData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
    const [showLV1data, setShowLV1data] = useState(false);
    const [showLV2data, setShowLV2data] = useState(false);
    const [showProductiondata, setShowProductiondata] = useState(false);
    const [showDG1data, setShowDG1data] = useState(false);
    const [showDG2data, setShowDG2data] = useState(false);


    function getYesterdayDate() {
        const today = new Date();
        today.setDate(today.getDate() - 1); // Subtract one day
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const fetchDailyConsumptionData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/dailyconsumption`);
            const data = await response.json();
            if (Array.isArray(data)) {
                setDailyconsumptionData(data);
            } else {
                console.error('Daily Consumption data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching Daily Consumption data:', error);
        }
    };

    useEffect(() => {
        fetchDailyConsumptionData();
    }, [selectedDate]); // Update data when date changes

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const toggleLV1Data = () => {
        setShowLV1data(!showLV1data);
    };

    const toggleLV2Data = () => {
        setShowLV2data(!showLV2data);
    };
    const toggleProductionData = () => {
        setShowProductiondata(!showProductiondata);
    };
    const toggleDG1Data = () => {
        setShowDG1data(!showDG1data);
    };
    const toggleDG2Data = () => {
        setShowDG2data(!showDG2data);
    };

    return (
        <div id='dailyconsumption-total'>
            <div>
                <Operatorsidebar/>
            </div>
            <div id='dailyconsumption-right'>
                 <TopBar section="ConsumptionHeader" />
                <div id='dailyconsumption-right-2'>
                    <div id='dailyconsumption-right-21'>
                        <label>
                            <span>Select Date:</span>
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={handleDateChange}
                            />
                        </label>
                        <label>
                            <DailyConsumptionprint />
                        </label>
                    </div>
                    <div id='dailyconsumption-right-22'>
                        <div id='dailyconsumption-right-221'>
                            <div id='dailyconsumption-right-head'>Section Name</div>
                            <div id='dailyconsumption-right-head1'>Date</div>
                            <div id='dailyconsumption-right-head2'>kWh Consumption</div>
                        </div>

                        {dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'HV')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row'>
                                    <div id='dailyconsumption-right-data1'>{item.Section} <button id='dailyconsumption-dropbtn12' onClick={toggleLV1Data}>{showLV1data ? <FaChevronUp /> : <FaChevronDown />}</button></div>
                                    <div id='dailyconsumption-right-data2'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Induction Heater')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Compressor (11kw)')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Cut Bend')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Compressor (75kw)')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Compressor (30kw)')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Epoxy Coating Blower')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Epoxy Coating Booth Stabilizer')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Shot Blasting-1')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Shot Blasting-2')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Pump House')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Work Conveyor Panel')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Lighting Panel ')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showLV1data && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Utility Panel')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}

                        {dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'DG 62.5 KVA')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row'>
                                    <div id='dailyconsumption-right-data1'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data2'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Solar 734.66 KVA')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row'>
                                    <div id='dailyconsumption-right-data1'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data2'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
                                </div>
                            ))}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyConsumption;
