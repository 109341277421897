

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DGlogs.css';
import { formatDateToDDMMYYYY, formatDateTimeToDDMMYYYYHHMM } from '../Dateformat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DGlogs = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const [formData, setFormData] = useState({
        starttime: '',
        endtime: '',
        kwhinitial: '',
        kwhfinal: '',
        kwhconsumption: '',
        dieselconsumption: '',
        duration: '',
        createdby: '',
        date: getCurrentDate(),
    });

    const [errors, setErrors] = useState({
        kwhinitial: '',
        kwhfinal: '',
        starttime: '',
        endtime: ''
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
            setFormData((prevData) => ({
                ...prevData,
                createdby: user.username
            }));
            setUserDesignation(user.level);
        }
    }, []);

    const calculateDuration = (starttime, endtime) => {
        if (starttime && endtime) {
            const startTime = new Date(starttime);
            const endTime = new Date(endtime);

            let duration = endTime - startTime;

            const days = Math.floor(duration / (1000 * 60 * 60 * 24));
            const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

            const formattedDuration = `${days}d ${hours < 10 ? '0' + hours : hours}h ${minutes < 10 ? '0' + minutes : minutes}m`;

            setFormData(prevState => ({
                ...prevState,
                duration: formattedDuration
            }));
        }
    };

    const calculateKWhConsumption = (kwhinitial, kwhfinal) => {
        const initial = parseFloat(kwhinitial);
        const final = parseFloat(kwhfinal);

        if (!isNaN(initial) && !isNaN(final)) {
            return (final - initial).toFixed(2);
        }
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Validation logic for each field
        if (name === 'kwhinitial' || name === 'kwhfinal') {
            if (value.match(/^\d{0,15}(\.\d{0,2})?$/)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            } else {
                setErrors((prev) => ({ ...prev, [name]: 'Enter a valid number up to 15 digits.' }));
                return;
            }
        } else if (name === 'dieselconsumption') {
            if (value.match(/^\d{0,4}(\.\d{0,2})?$/)) {
                setErrors((prev) => ({ ...prev, dieselconsumption: '' }));
            } else {
                setErrors((prev) => ({ ...prev, dieselconsumption: 'Enter a valid number up to 4 digits.' }));
                return;
            }
        }

        setFormData(prevState => {
            const updatedFormData = {
                ...prevState,
                [name]: value
            };

            if (name === 'starttime' || name === 'endtime') {
                const now = new Date();
                const startTime = new Date(updatedFormData.starttime);
                const endTime = new Date(updatedFormData.endtime);

                if (startTime > now || endTime > now) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        [name]: 'Date cannot be in the future.'
                    }));
                } else {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        [name]: ''
                    }));

                    if (name === 'endtime' && startTime > endTime) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            endtime: 'End Date & Time cannot be earlier than start date & time.'
                        }));
                    } else if (name === 'starttime' && endTime < startTime) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            endtime: 'End Date & Time cannot be earlier than start date & time.'
                        }));
                    } else {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            endtime: ''
                        }));
                    }

                    calculateDuration(updatedFormData.starttime, updatedFormData.endtime);
                }
            }

            if (name === 'kwhinitial' || name === 'kwhfinal') {
                const { kwhinitial, kwhfinal } = updatedFormData;
                const initial = parseFloat(kwhinitial);
                const final = parseFloat(kwhfinal);

                if (!isNaN(initial) && !isNaN(final) && initial > final) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        kwhinitial: 'KWH Initial value cannot be greater than KWh final value.'
                    }));
                } else {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        kwhinitial: ''
                    }));
                    updatedFormData.kwhconsumption = calculateKWhConsumption(kwhinitial, kwhfinal);
                }
            }

            return updatedFormData;
        });
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!errors.kwhinitial && !errors.kwhfinal && !errors.starttime && !errors.endtime) {
    //         const parsedFormData = {
    //             ...formData,
    //             vr: parseFloat(formData.vr),
    //             vb: parseFloat(formData.vb),
    //             vy: parseFloat(formData.vy),
    //             kwhinitial: parseFloat(formData.kwhinitial),
    //             kwhfinal: parseFloat(formData.kwhfinal),
    //             kwhconsumption: parseFloat(formData.kwhconsumption),
    //             dieselconsumption: parseFloat(formData.dieselconsumption)
    //         };

    //         try {
    //             const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/dg-logs`, parsedFormData);
    //             if (response.data.success) {
    //                 setFormData({
    //                     starttime: '',
    //                     endtime: '',
    //                     kwhinitial: '',
    //                     kwhfinal: '',
    //                     kwhconsumption: '',
    //                     dieselconsumption: '',
    //                     duration: '',
    //                     createdby: loggedInUser.username,
    //                     date: getCurrentDate(),
    //                 });
    //                 alert('DG data submitted successfully');
    //             } else {
    //                 alert('Failed to submit data');
    //             }
    //         } catch (error) {
    //             console.error('Error submitting form', error);
    //         }
    //     } else {
    //         alert('Please fix the errors before submitting.');
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Format the date using the dateformat function
        const formattedDate = formatDateToDDMMYYYY(formData.date);
        const formattedStartTime = formatDateTimeToDDMMYYYYHHMM(formData.starttime);
        const formattedEndTime = formatDateTimeToDDMMYYYYHHMM(formData.endtime);
        const parsedFormData = {
            ...formData,
            date: formattedDate,
            starttime: formattedStartTime,
            endtime: formattedEndTime,
            vr: parseFloat(formData.vr),
            vb: parseFloat(formData.vb),
            vy: parseFloat(formData.vy),
            kwhinitial: parseFloat(formData.kwhinitial),
            kwhfinal: parseFloat(formData.kwhfinal),
            kwhconsumption: parseFloat(formData.kwhconsumption),
            dieselconsumption: parseFloat(formData.dieselconsumption)
        };

        if (!errors.kwhinitial && !errors.kwhfinal && !errors.starttime && !errors.endtime) {

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/dg-logs`, parsedFormData);
                if (response.data.success) {
                    setFormData({
                        starttime: '',
                        endtime: '',
                        kwhinitial: '',
                        kwhfinal: '',
                        kwhconsumption: '',
                        dieselconsumption: '',
                        duration: '',
                        createdby: loggedInUser.username,
                        date: getCurrentDate(),
                    });
                    toast.success('DG Form submitted successfully');
                }
            } catch (error) {
                console.error('Error Adding DG log', error);
                if (error.response && error.response.data.message === 'A log already exists for the date') {
                    toast.error('Data already exists for the Selected Date, Please select any other Date');
                } else {
                    toast.error('Failed to add DG log. Please try again later.');
                }
            }
        } else {
            toast.error('Please fix the errors before submitting.');
        }
    };


    return (
        <div id='dglogs-total'>

            <div id='dglogs-right'>


                <div id='dglogs-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='dglogs-divtop'>

                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='dglogs-div1'>
                            <label>
                                <span>Date <span id='all-mandetorymark'>*</span></span>
                                <input type='date' name="date" value={formData.date} onChange={handleChange} max={getCurrentDate()} />
                            </label>
                            <label>
                                <span>Start Time <span id='all-mandetorymark'>*</span></span>
                                <input type='datetime-local' name="starttime" value={formData.starttime} onChange={handleChange} />
                                {errors.starttime && <span id='dglogs-errormessage' style={{ color: 'red' }}>{errors.starttime}</span>}
                            </label>

                        </div>
                        <div id='dglogs-div1'>

                            <label>
                                <span>End Time <span id='all-mandetorymark'>*</span></span>
                                <input type='datetime-local' name="endtime" value={formData.endtime} onChange={handleChange} />
                                {errors.endtime && <span id='dglogs-errormessage' style={{ color: 'red' }}>{errors.endtime}</span>}
                            </label>

                            <label>
                                <span>KWh (initial readings) <span id='all-mandetorymark'>*</span></span>
                                <input type='number' name="kwhinitial" value={formData.kwhinitial} onChange={handleChange} />
                                {errors.kwhinitial && <span id='dglogs-errormessage' style={{ color: 'red' }}>{errors.kwhinitial}</span>}
                            </label>

                        </div>

                        <div id='dglogs-div1'>
                            <label>
                                <span>KWh (final readings) <span id='all-mandetorymark'>*</span></span>
                                <input type='number' name="kwhfinal" value={formData.kwhfinal} onChange={handleChange} />
                                {errors.kwhfinal && <span id='dglogs-errormessage' style={{ color: 'red' }}>{errors.kwhfinal}</span>}
                            </label>
                            <label>
                                <span>KWh Consumption <span id='all-mandetorymark'>*</span></span>
                                <input name="kwhconsumption" value={formData.kwhconsumption} onChange={handleChange} disabled />
                            </label>


                        </div>
                        <div id='dglogs-div1'>
                            <label>
                                <span>Duration <span id='all-mandetorymark'>*</span></span>
                                <input type='text' name="duration" value={formData.duration} onChange={handleChange} disabled />
                            </label>
                            <label>
                                <span>Diesel Consumption  <span id='all-mandetorymark'>*</span></span>
                                <input type='number' name="dieselconsumption" value={formData.dieselconsumption} onChange={handleChange} />
                            </label>
                        </div>
                        <button id='dglogs-save' type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DGlogs;
