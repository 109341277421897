
// import React, { useState, useContext, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { UserContext } from '../Details/UserContext';
// import logo from '../../../Images/logo1.png'
// import './Login.css'
// const LoginPage = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [allUsers, setAllUsers] = useState([]);
//   const { setUser } = useContext(UserContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchAllUsers();
//   }, []);

//   const fetchAllUsers = async () => {
//     try {
//       const responseSuperadmins = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/superadmins`);
//       const responseUsers = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/users`);

//       // Combine data from both APIs into one array
//       const superadmins = responseSuperadmins.data.map(admin => ({ ...admin, type: 'superadmin' }));
//       const users = responseUsers.data.map(user => ({ ...user, type: 'user' }));
//       const combinedUsers = [...superadmins, ...users];


//       //       const superadmins = responseSuperadmins.data.map(admin => ({ ...admin, type: 'superadmin', sublevel: admin.sublevel }));
//       // const users = responseUsers.data.map(user => ({ ...user, type: 'user', sublevel: user.sublevel }));


//       setAllUsers(combinedUsers);
//     } catch (error) {
//       console.error('Error fetching users:', error);
//     }
//   };

//   const handleLogin = (e) => {
//     e.preventDefault();

//     const user = allUsers.find(
//       (u) => u.username === username && u.password === password
//     );

//     if (!user) {
//       alert('Username and password do not match');
//       return;
//     }

//     if (!user.status) {
//       alert('Contact admin, you are inactive');
//       return;
//     }

//     // Save user details in context
//     setUser(user);

//     // Save user details in localStorage
//     localStorage.setItem('user', JSON.stringify(user));

//     // Navigate based on designation
//     switch (user.level) {
//       case 'Level-1':
//         navigate('/Designation');
//         break;
//       case 'Level-2':
//         navigate('/Loginscreens');
//         break;
//       case 'Level-3':
//         navigate('/Loginscreens');
//         break;
//       case 'Level-4':
//         navigate('/Operator-Dash');
//         break;
//       default:
//         navigate('/');
//         break;
//     }
//   };


//   // const handleLogin = (e) => {
//   //   e.preventDefault();

//   //   const user = allUsers.find(
//   //     (u) => u.username === username && u.password === password
//   //   );

//   //   if (!user) {
//   //     alert('Username and password do not match');
//   //     return;
//   //   }

//   //   if (!user.status) {
//   //     alert('Contact admin, you are inactive');
//   //     return;
//   //   }

//   //   // Save user details in context
//   //   setUser(user);

//   //   // Save user details in localStorage
//   //   localStorage.setItem('user', JSON.stringify(user));

//   //   // Navigate based on level and sublevel
//   //   switch (user.level) {
//   //     case 'Level-1':
//   //       switch (user.sublevel) {
//   //         case 'Sublevel-1':
//   //           navigate('/Level1Sublevel1');
//   //           break;
//   //         case 'Sublevel-2':
//   //           navigate('/Level1Sublevel2');
//   //           break;
//   //         // Add more cases for additional sublevels if needed
//   //         default:
//   //           navigate('/Level1Default');
//   //           break;
//   //       }
//   //       break;
//   //     case 'Level-2':
//   //       switch (user.sublevel) {
//   //         case 'Sublevel-1':
//   //           navigate('/Level2Sublevel1');
//   //           break;
//   //         case 'Sublevel-2':
//   //           navigate('/Level2Sublevel2');
//   //           break;
//   //         // Add more cases for additional sublevels if needed
//   //         default:
//   //           navigate('/Level2Default');
//   //           break;
//   //       }
//   //       break;
//   //     case 'Level-3':
//   //       switch (user.sublevel) {
//   //         case 'Sublevel-1':
//   //           navigate('/Level3Sublevel1');
//   //           break;
//   //         case 'Sublevel-2':
//   //           navigate('/Level3Sublevel2');
//   //           break;
//   //         // Add more cases for additional sublevels if needed
//   //         default:
//   //           navigate('/Level3Default');
//   //           break;
//   //       }
//   //       break;
//   //     case 'Level-4':
//   //       switch (user.sublevel) {
//   //         case 'Sublevel-1':
//   //           navigate('/Level4Sublevel1');
//   //           break;
//   //         case 'Sublevel-2':
//   //           navigate('/Level4Sublevel2');
//   //           break;
//   //         // Add more cases for additional sublevels if needed
//   //         default:
//   //           navigate('/Level4Default');
//   //           break;
//   //       }
//   //       break;
//   //     default:
//   //       navigate('/');
//   //       break;
//   //   }
//   // };


//   return (
//     <div id='login-totalcontainer'>
//       <div id='login-total'>
//         <div className="loginimage">
//           <img src={logo} alt="" />
//         </div>
//         <form onSubmit={handleLogin}>
//           <label>
//             <span> Username</span>
//             <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
//           </label>
//           <label>
//             <span>Password</span>
//             <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
//           </label>
//           <button id='login-loginbtn' type="submit">Login</button>
//         </form>
//       </div>
//     </div>

//   );
// };

// export default LoginPage;








import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Details/UserContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import logo from '../../../Images/logo1.svg'
import './Login.css'

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [allUsers, setAllUsers] = useState([]);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const responseSuperadmins = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/superadmins`);
      const responseUsers = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/users`);

      // Combine data from both APIs into one array
      const superadmins = responseSuperadmins.data.map(admin => ({ ...admin, type: 'superadmin' }));
      const users = responseUsers.data.map(user => ({ ...user, type: 'user' }));
      const combinedUsers = [...superadmins, ...users];

      setAllUsers(combinedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    const user = allUsers.find(
      (u) => u.username === username && u.password === password
    );

    if (!user) {
      alert('Username and password do not match');
      return;
    }

    if (!user.status) {
      alert('Contact admin, you are inactive');
      return;
    }

    // Save user details in context
    setUser(user);

    // Save user details in localStorage
    localStorage.setItem('user', JSON.stringify(user));


    if (user.level === 'Level-4') {
      localStorage.setItem('accessedLevel', 'Level-4');
    }

    // Navigate based on designation
    switch (user.level) {
      case 'Level-1':
        navigate('/Designation');
        break;
      case 'Level-2':
        navigate('/Loginscreens');
        break;
      case 'Level-3':
        navigate('/Loginscreens');
        break;
      case 'Level-4':
        navigate('/Operator-Dash');
        break;
      default:
        navigate('/');
        break;
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <div id='login-totalcontainer'>
      <div id='login-total'>
        <div className="loginimage">
          <img src={logo} alt="" />
        </div>
        <form onSubmit={handleLogin}>
          <label>
            <span> Username</span>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
          </label>
          <label>
            <span>Password</span>
            <div id='password-container'>
              <input
                type={showPassword ? "text" : "password"}  // Toggle input type
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span onClick={togglePasswordVisibility} className='password-toggle-icon'>
                {showPassword ? <FaEyeSlash /> : <FaEye />} 
              </span>
            </div>
          </label>
          <label  id='login-loginbtn-flex'>
          <button id='login-loginbtn' type="submit">Login</button>

          </label>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;




