import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import "./Alertscount.css";
import { getAlertsCount } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import Stationpoints from "../../Commonfns/Stationpoints";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import manIcon from "../../../Images/man.svg";
import { whatSholdYouDo } from "../../../utils/trends";
import lightIcon from "../../../Images/light.svg";
import { Link } from "react-router-dom";
import { errorToast } from "../../../utils/toast";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Alertscount = () => {
  const { t } = useTranslation();
  const monthInputRef = useRef(null);

  const { register, setValue, watch } = useForm({
    defaultValues: {
      station_point: "",
      month: moment().format("YYYY-MM"),
    },
  });

  const [chartData, setChartData] = useState({});

  const { station_point, month } = watch();

  useEffect(() => {
    getAlertsCount(station_point, month)
      .then((res) => {
        setChartData(res.alert_counts);
      })
      .catch((error) => {
        errorToast(error.message || "failed to fetch Alert Count");
      });
  }, [station_point, month]);

  const getData = () => {
    const data = {
      labels: Object.keys(chartData).map((key) => key),
      datasets: [
        {
          label: "Alert Count",
          data: Object.values(chartData).map((value) => value),
          backgroundColor: Array.from({
            length: Object.entries(chartData).length,
          }).map((_color) => "#DD4848"),
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: true,
          text: "Parameters",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Count",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    animation: {
      duration: 50,
      easing: undefined,
    },
    plugins: {
      tooltip: {
        animation: false,
        duration: 0,
      },
    },
  };

  const handleStationPointChange = (value) => {
    if (value.length === 0) {
      setValue(station_point, "HV");
    } else {
      setValue("station_point", value);
    }
  };

  return (
    <div>
      <Paper className="filter-container">
        <Box>
          <div>
            <Stationpoints onStationPointChange={handleStationPointChange} />
          </div>
        </Box>
        <Box>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>

          <div>
            <input
             {...register("month")}
             style={{ height: "30px" }}
             type="month"
             ref={monthInputRef}
             defaultValue={month}
             max={new Date().toISOString().slice(0, 7)}
             onClick={() => monthInputRef.current.showPicker()}
             onChange={(e) => setValue('month', e.target.value)}
            />
          </div>
        </Box>
      </Paper>

      <Paper className="chart-container">
        <Typography variant="h6">
          {t("alertTrendsComponent.alertsTrend")}
        </Typography>
        <Bar className="alerts-chart" data={getData()} options={options} />

        <Divider sx={{ mb: 2 }} />

        <Typography sx={{ mb: 2 }}>
          {t("common.howToUseTrendsEffectively")}
        </Typography>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={chatIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.whyIsThisImportanQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("alertTrendsComponent.whyIsThisImportantAnswer")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box
            className="imp-container"
            sx={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                style={{ alignSelf: "start" }}
                src={manIcon}
                width={"40px"}
                height={"40px"}
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#447F44", fontWeight: "bold" }}
                >
                  {t("common.WhatShouldYouDoQuestion")}
                </Typography>
                <Typography variant="body1">
                  <li>
                  {t("alertTrendsComponent.whatShouldYouDoAnswer")}
                  </li>
                 
                </Typography>

                
                <Typography variant="body1">
                <ul
                  style={{
                    margin: 5,
                    listStyleType: "square",
                  }}
                >
                  {t("alertTrendsComponent.whatShouldYouDoAnswerBullets", {
                    returnObjects: true,
                  }).map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </Typography>
              </Box>
            </Box>

            <Box>
              <Link to={"/Alertspage?section=OperatorAlertstable-1"}>
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#447F44",
                    color: "white",
                    width: "200px",
                    justifySelf: "start",
                    alignSelf: "start",
                    borderRadius: "4px",
                  }}
                >
                  {t("alertTrendsComponent.alertsHistory")}
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={lightIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.howWillYouImproveQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("alertTrendsComponent.howWillYouImproveAnswer")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default Alertscount;
