// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dailypf {
    min-height: 45vh;
    width: 30vw;
    background-color: white;
    border: 1px solid #DFE6FA;
    border-radius: 10px;
    padding: 15px;
}

#gauge-chart {
    height: 400px;
    width: 350px;
}

#dailypf-div1 {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

        button {
            z-index: -1;
            margin-left: 27.5vw;
            padding: 6px 8px;
            border-radius: 5px;
            background-color: #F3F6FE;
            border: 1px solid #abb9e7;
            cursor: pointer;
        }
}

#dailypf-flex{
    display: flex;

    /* #daily-pf-inside{
        margin-top: 20vh;
        span{

        }
    } */
}

#daily-pf-inside{
  margin-top: -120px;
  font-size: 10px;
}


@media screen and (min-width: 1024px) {
    #dailypf {
        min-height: 27vh;
        min-width: 31.3vw;
    }

}

@media screen and (min-width: 1440px) {
   
    #dailypf {
        min-height: 26vh;
      
    }

    #gauge-chart {
        height: 415px;
        width: 450px;
    }
}
@media screen and (min-width: 1920px) {
    #dailypf {
        min-height: 30vh;
      
    }
    #gauge-chart {
        height: 530px;
        width: 570px;
    }
   
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Daily-Dash/DailyPF/DailyPF.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,oCAAoC;;QAEhC;YACI,WAAW;YACX,mBAAmB;YACnB,gBAAgB;YAChB,kBAAkB;YAClB,yBAAyB;YACzB,yBAAyB;YACzB,eAAe;QACnB;AACR;;AAEA;IACI,aAAa;;IAEb;;;;;OAKG;AACP;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;;AAGA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;;AAEJ;;AAEA;;IAEI;QACI,gBAAgB;;IAEpB;;IAEA;QACI,aAAa;QACb,YAAY;IAChB;AACJ;AACA;IACI;QACI,gBAAgB;;IAEpB;IACA;QACI,aAAa;QACb,YAAY;IAChB;;AAEJ","sourcesContent":["#dailypf {\n    min-height: 45vh;\n    width: 30vw;\n    background-color: white;\n    border: 1px solid #DFE6FA;\n    border-radius: 10px;\n    padding: 15px;\n}\n\n#gauge-chart {\n    height: 400px;\n    width: 350px;\n}\n\n#dailypf-div1 {\n    display: flex;\n    align-items: center;\n    /* justify-content: space-between; */\n\n        button {\n            z-index: -1;\n            margin-left: 27.5vw;\n            padding: 6px 8px;\n            border-radius: 5px;\n            background-color: #F3F6FE;\n            border: 1px solid #abb9e7;\n            cursor: pointer;\n        }\n}\n\n#dailypf-flex{\n    display: flex;\n\n    /* #daily-pf-inside{\n        margin-top: 20vh;\n        span{\n\n        }\n    } */\n}\n\n#daily-pf-inside{\n  margin-top: -120px;\n  font-size: 10px;\n}\n\n\n@media screen and (min-width: 1024px) {\n    #dailypf {\n        min-height: 27vh;\n        min-width: 31.3vw;\n    }\n\n}\n\n@media screen and (min-width: 1440px) {\n   \n    #dailypf {\n        min-height: 26vh;\n      \n    }\n\n    #gauge-chart {\n        height: 415px;\n        width: 450px;\n    }\n}\n@media screen and (min-width: 1920px) {\n    #dailypf {\n        min-height: 30vh;\n      \n    }\n    #gauge-chart {\n        height: 530px;\n        width: 570px;\n    }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
