import React from 'react'
import './Tab1.css';
import Dashcriticalalerts from './Dashcriticalalerts/Dashcriticalalerts';
import DashCalendar from './DashCalender/DashCalender';
import DashTOD_runtime from './TOD_runtime/TOD_runtime';
import DashPending_Task from './DashPending_Task/DashPending_Task';
const Tab1 = () => {
    return (
        <div id='tab1-total'>
            <div id='tab1-1'>
                <div>
                    <DashPending_Task />
                </div>
                <div id='tab1-insided1' >
                    <Dashcriticalalerts />

                </div>
            </div>
            <div>
                <DashTOD_runtime />
                <DashCalendar />
            </div>

            <div id='tab1-2'>
                <div>

                </div>
                <div>

                </div>
            </div>
        </div>
    )
}

export default Tab1