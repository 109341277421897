// import React, { useState, useEffect } from 'react'
// import axios from 'axios';

// const Stationpoints = ({ onStationPointChange }) => {

//     const [stationpointsdata, setStationpointsdata] = useState([]);
//     const [error, setError] = useState(null);
//     useEffect(() => {

//         const fetchStationpointsdata = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/allstation-points`);
//                 setStationpointsdata(response.data);
//             } catch (error) {
//                 setError(error);
//             }
//         };

//         fetchStationpointsdata();
//     }, []);

//     if (error) {
//         return <div>Error: {error.message}</div>;
//     }

//     const handleStationPointChange = (e) => {
//         onStationPointChange(e.target.value);
//     };

//     return (
//         <div>
//             <select style={{ borderRadius: '5px', border: '1.5px solid #DFE6FA', height: '4.7vh' }} id="station-points" onChange={handleStationPointChange}>
//                 {stationpointsdata.map((point, index) => (
//                     <option key={index} value={point["Station point"]}>
//                         {point["Station point"]}
//                     </option>
//                 ))}
//             </select>
//         </div>
//     )
// }

// export default Stationpoints

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Stationpoints.css";
const Stationpoints = ({
  onStationPointChange,
  defaultStationPoint = "",
  showAll = false,
}) => {
  const [stationpointsdata, setStationpointsdata] = useState([]);
  const [error, setError] = useState(null);
  const [selectedStationPoint, setSelectedStationPoint] = useState(defaultStationPoint);


  useEffect(() => {
    if (defaultStationPoint) {
      setSelectedStationPoint(defaultStationPoint);
    }
  }, [defaultStationPoint]);

  useEffect(() => {
    const fetchStationpointsdata = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_EMS_URL}/api/allstation-points`
        );
        setStationpointsdata(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchStationpointsdata();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleStationPointChange = (e) => {
    setSelectedStationPoint(e.target.value);
    onStationPointChange(e.target.value);
  };

  return (
    <div className="station-select-container">
      <label>
        <span>Select Sections:</span>
        <select
          style={{ height: "36px" }}
          id="station-points"
          value={selectedStationPoint}
          onChange={handleStationPointChange}
        >
          {(showAll || !defaultStationPoint) && (
            <option value="">Select All</option>
          )}

          {stationpointsdata.map((point, index) => (
            <option key={index} value={point["Station point"]}>
              {point["Station point"]}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default Stationpoints;
