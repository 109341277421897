import React, { useEffect, useState } from "react";
import "./CriticalAlerts.css";
import { isWithinInterval } from "date-fns";
import { FaFileCsv } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import Supervisorsidebar from "../Supervisor/Supersidebar/Supersidebar";
import Stationpoints from "../Commonfns/Stationpoints";
import TopBar from "../Commonfns/Topbar/Topbar";
const CriticalAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const [section, setSection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [alertTypes, setAlertTypes] = useState([]);
  const [selectedAlertType, setSelectedAlertType] = useState("");
  const navigate = useNavigate();

  const location = useLocation();

  // const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const sectionParam = queryParams.get("section") || "";
    const fromParam = queryParams.get("from") || "";
    const toParam = queryParams.get("to") || "";
    const selectedTypeParam = queryParams.get("selectedType") || "";

    setSection(sectionParam);
    setFromDate(fromParam);
    setToDate(toParam);
    setSelectedAlertType(selectedTypeParam);
  }, []);

  

  // Update query parameters when any of the state values change
  useEffect(() => {
    const queryParams = new URLSearchParams();

    if (section) queryParams.set("section", section);
    if (fromDate) queryParams.set("from", fromDate);
    if (toDate) queryParams.set("to", toDate);
    if (selectedAlertType) queryParams.set("selectedType", selectedAlertType);

    navigate(`?${queryParams.toString()}`);
  }, [section, fromDate, toDate, selectedAlertType, navigate]);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_EMS_URL}/api/closed-criticalalerts`
        );
        const data = await response.json();

        // No formatting of the date and time
        const formattedData = data.map((alert) => ({
          ...alert,
          rawStartDate: new Date(alert.start_time.split("T")[0]),
          rawEndDate: new Date(alert.end_time.split("T")[0]),
        }));
        setAlerts(formattedData);
        setFilteredAlerts(formattedData);
      } catch (error) {
        console.error("Error fetching alerts:", error);
      }
    };

    const fetchAlertTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_EMS_URL}/api/unique-alert-types-criticalalerts`
        );
        const types = await response.json();
        setAlertTypes(types);
      } catch (error) {
        console.error("Error fetching alert types:", error);
      }
    };

    fetchAlerts();
    fetchAlertTypes();
  }, []);

  useEffect(() => {
    const filtered = alerts.filter((alert) => {
      const matchesSection = section
        ? alert.section_name.toLowerCase().includes(section.toLowerCase())
        : true;
      const matchesDate =
        fromDate && toDate
          ? isWithinInterval(alert.rawStartDate, {
              start: new Date(fromDate),
              end: new Date(toDate),
            })
          : true;
      const matchesAlertType = selectedAlertType
        ? alert.specific_alert_type === selectedAlertType
        : true;

      return matchesSection && matchesDate && matchesAlertType;
    });
    setFilteredAlerts(filtered);
  }, [alerts, section, fromDate, toDate, selectedAlertType]);

  const downloadCSV = () => {
    const csvContent = [
      [
        "S NO",
        "Section",
        "Critical Alert Parameter",
        "Critical Alert Value",
        "Threshold",
        "Start Time",
        "End Time",
        "Duration",
      ],
      ...filteredAlerts.map((alert, index) => [
        index + 1,
        alert.section_name,
        `"${alert.critical_alert_parameter}"`,
        `"${alert.critical_alert_value}"`,
        alert.threshold,
        alert.start_time,
        alert.end_time,
        alert.duration,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "CriticalAlertsHistory.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDiagnoseClick = (token_id) => {
    console.log("Navigating to diagnose page for token ID:", token_id);
    navigate(`/Diagnose/${token_id}`);
  };

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const handleAlertTypeChange = (e) => {
    setSelectedAlertType(e.target.value);
  };

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);

    if (toDate && toDate < selectedFromDate) {
      setToDate("");
    }
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
  };
  return (
    <div id="criticalalets-total">
      <div>
        <Supervisorsidebar />
      </div>
      <div id="criticalalets-right">
        <TopBar section="Criticalalertsheading" />
        <div id="criticalalets-right-2">
          <div id="criticalalets-right-21">
            <label>
              <span>From Date:</span>
              <input
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                max={getTodayDate()} // No future dates allowed
              />
            </label>
            <label>
              <span>To Date:</span>
              <input
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                max={getTodayDate()} // No future dates allowed
                min={fromDate || ""}
                disabled={!fromDate}
              />
            </label>
            <label>
              <span>Alert Type:</span>
              <select
                value={selectedAlertType}
                onChange={handleAlertTypeChange}
              >
                <option value="">Please select</option>
                {alertTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <Stationpoints
                defaultStationPoint={section}
                showAll={true}
                onStationPointChange={handleStationPointChange}
              />
            </label>

            <button onClick={downloadCSV}>
              <FaFileCsv />
            </button>
          </div>

          <div id="criticalalets-right-3">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Token ID</th>
                  <th>Alert Type</th>
                  <th>Section</th>
                  <th>Critical Alert Parameter</th>
                  <th>Critical Alert Value</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Duration</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredAlerts.length === 0 ? (
                  <tr>
                    <td colSpan="10">
                      No data available for selected filters.
                    </td>
                  </tr>
                ) : (
                  filteredAlerts.map((alert, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{alert.token_id}</td>
                      <td>{alert.specific_alert_type}</td>
                      <td>{alert.section_name}</td>
                      <td>{alert.critical_alert_parameter}</td>
                      <td>{alert.critical_alert_value}</td>
                      <td>{alert.start_time}</td>
                      <td>{alert.end_time}</td>
                      <td>{alert.duration}</td>
                      <td>
                        {!alert.token_id.startsWith("Kv") && (
                          <button
                            onClick={() => handleDiagnoseClick(alert.token_id)}
                          >
                            Diagnose
                          </button>
                        )}
                      </td>
                      {/* <td><button onClick={() => handleDiagnoseClick(alert.token_id)}>Diagnose</button></td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CriticalAlerts;
