import { useState, React, useEffect } from "react";
import "./Alltrends.css";
import TopBar from "../Commonfns/Topbar/Topbar";
import Supervisorsidebar from "../Supervisor/Supersidebar/Supersidebar";
import Alertscount from "./Alerts/Alertscount";
import DownTimeVsUpTime from "./DownTimeVsUpTime/DownTimeVsUpTime";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RevenueLossTrend from "./RevenueLoss/RevenueLoss";
import RcpeReportTrend from "./RcpeReport/RcpeReport";
import TodRunHoursTrend from "./TodRunHours/TodRunHours";
import CommonTabs from "../Common/CommonTabs/CommonTabs";

const tabs = [
  {
    name: "RCPE Report",
    label: "rcpe-report",
    component: <RcpeReportTrend />,
  },
  {
    name: "TOD & Run Hours Trend",
    label: "tod-run-hours",

    component: <TodRunHoursTrend />,
  },
  {
    name: "Alerts Trend",
    label: "alert-count",
    component: <Alertscount />,
  },

  {
    name: "Downtime Trend",
    label: "downtime",
    component: <DownTimeVsUpTime />,
  },

  {
    name: "Revenue Loss Trend",
    label: "revenue-loss",
    component: <RevenueLossTrend />,
  },
];

const AllTrends = () => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <div>
        <Supervisorsidebar />
      </div>
      <div id="trendspage-right">
        <TopBar section="Trendsheading" />
        <CommonTabs tabs={tabs} defaultTab={tabs[0].label} />
      </div>
    </div>
  );
};

export default AllTrends;
