import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import "./RcpeReport.css";
import { getRcpeReports } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import manIcon from "../../../Images/man.svg";
import {
  getData,
  getRunHoursOptions,
  timeStringToDecimalHours,
} from "../../../utils/trends";
import lightIcon from "../../../Images/light.svg";
import { Link } from "react-router-dom";
import { errorToast } from "../../../utils/toast";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const RcpeTrend = () => {
  const { t } = useTranslation();

  const monthInputRef = useRef(null);
  const { register, watch, setValue } = useForm({
    defaultValues: {
      month: moment().format("YYYY-MM"),
    },
  });

  const [energyIntensity, setEnergyIntensity] = useState({
    dateLabels: [],
    baseValue: 0,
    values: [],
  });
  const [runHours, setRunHours] = useState({
    dateLabels: [],
    values: [],
  });
  const [consumption, setConsumption] = useState({
    dateLabels: [],
    values: [],
  });
  const [production, setProduction] = useState({
    dateLabels: [],
    values: [],
  });

  const [productionVsRunHours, setProductionVsRunHours] = useState({
    dateLabels: [],
    prodcutionValues: [],
    runHoursValues: [],
  });

  const { month } = watch();

  useEffect(() => {
    getRcpeReports(month)
      .then((res) => {
        setEnergyIntensity({
          dateLabels: res.energy_intensity.date_labels,
          baseValue: res.energy_intensity.base_value,
          values: res.energy_intensity.values,
        });
        setRunHours({
          dateLabels: res.run_hours.date_labels,
          values: res.run_hours.values,
        });

        setConsumption({
          dateLabels: res.consumption.date_labels,
          values: res.consumption.values,
        });

        setProduction({
          dateLabels: res.production.date_labels,
          values: res.production.values,
        });

        setProductionVsRunHours({
          dateLabels: res.productionvsrunhours.date_labels,
          prodcutionValues: res.productionvsrunhours.prodcution_values,
          runHoursValues: res.productionvsrunhours.run_hours_values,
        });
      })
      .catch((error) => {
        errorToast(error.message || "failed to fetch Alert Count");
      });
  }, [month]);

  const getEnergyIntensityData = () => {
    return {
      labels: energyIntensity.dateLabels,
      datasets: [
        {
          type: "line",
          label: "Base Line",
          data: energyIntensity.dateLabels.map(
            (_date) => energyIntensity.baseValue
          ),
          backgroundColor: "#DB4545",
          borderColor: "#DB4545",
          borderWidth: 2,
          borderDash: [5, 5],
          pointRadius: 5,
          tension: 0,
        },
        {
          label: "Energy Intensity",
          data: energyIntensity.values,
          backgroundColor: energyIntensity.dateLabels.map((_date) => "#6174D7"),
          borderWidth: 1,
          maxBarThickness: 100,
        },
      ],
    };
  };

  const getOptions = (yLabel = "") => {
    return {
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            display: false, // Hides grid lines on the x-axis
          },
          title: {
            display: true,
            text: "Date",
            font: {
              size: 15, // Font size for x-axis title
              weight: "bold", // Makes the x-axis title bold
            },
          },
        },
        y: {
          grid: {
            display: false, // Hides grid lines on the x-axis
          },
          beginAtZero: true,
          title: {
            display: true,
            text: yLabel,
            font: {
              size: 15, // Font size for x-axis title
              weight: "bold", // Makes the x-axis title bold
            },
          },
        },
      },
      animation: {
        duration: 50,
        easing: undefined,
      },
      plugins: {
        tooltip: {
          animation: false,
          duration: 0,
        },
      },
    };
  };

  const getProdVsRunHoursData = () => {
    return {
      labels: productionVsRunHours.dateLabels,
      datasets: [
        {
          label: "Production",
          data: productionVsRunHours.prodcutionValues,
          backgroundColor: productionVsRunHours.dateLabels.map(
            (_date) => "#D7E0EA"
          ),
          borderWidth: 1,
          maxBarThickness: 100,
        },
        {
          label: "Run Hours",
          data: productionVsRunHours.runHoursValues.map((data) =>
            timeStringToDecimalHours(data)
          ),
          backgroundColor: productionVsRunHours.dateLabels.map(
            (_date) => "#BBAEAE"
          ),
          borderWidth: 1,
          maxBarThickness: 100,
        },
      ],
    };
  };

  console.log(productionVsRunHours);

  return (
    <div>
      <Paper className="rcpe-filter-container">
        <Box>
          <Typography className="description">
            {t(
              "rcpeTrendComponent.RunHoursConsumptionProductionEnergyIntensity"
            )}
          </Typography>
        </Box>
        <Box>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>

          <div>
            <input
              {...register("month")}
              style={{ height: "30px" }}
              type="month"
              ref={monthInputRef}
              defaultValue={month}
              max={new Date().toISOString().slice(0, 7)}
              onClick={() => monthInputRef.current.showPicker()}
              onChange={(e) => setValue("month", e.target.value)}
            />
          </div>
        </Box>
      </Paper>

      {/* energy_intensity */}

      <Paper className="chart-container">
        <Typography variant="h6">
          {t("rcpeTrendComponent.energyIntensity")}
        </Typography>
        <Bar
          className="alerts-chart"
          data={getEnergyIntensityData()}
          options={getOptions("Intensity")}
        />

        <Divider sx={{ mb: 2 }} />

        <Typography sx={{ mb: 2 }}>
          {t("common.howToUseTrendsEffectively")}
        </Typography>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={chatIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.whyIsThisImportanQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("rcpeTrendComponent.whyIsThisImportantAnswer")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box
            className="imp-container"
            sx={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                style={{ alignSelf: "start" }}
                src={manIcon}
                width={"40px"}
                height={"40px"}
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#447F44", fontWeight: "bold" }}
                >
                  {t("common.WhatShouldYouDoQuestion")}
                </Typography>
                <Typography variant="body1">
                  <ul
                    style={{
                      padding: 0,
                      margin: 0,
                      listStylePosition: "inside",
                    }}
                  >
                    {t("rcpeTrendComponent.whatShouldYouDoAnswer", {
                      returnObjects: true,
                    }).map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={lightIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.howWillYouImproveQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("rcpeTrendComponent.howWillYouImproveAnswer")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>

      {/* runHours */}

      <Paper className="chart-container">
        <Typography variant="h6">{t("rcpeTrendComponent.runHours")}</Typography>
        <Bar
          className="alerts-chart"
          data={getData(
            "Run Hours",
            runHours.dateLabels,
            runHours.values.map((val) => timeStringToDecimalHours(val))
          )}
          options={getRunHoursOptions()}
        />

        <Divider sx={{ mb: 2 }} />

        <Typography sx={{ mb: 2 }}>
          {t("common.howToUseTrendsEffectively")}
        </Typography>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={chatIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.whyIsThisImportanQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("rcpeTrendComponent.whyIsThisImportantAnswerRunHours")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box
            className="imp-container"
            sx={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                style={{ alignSelf: "start" }}
                src={manIcon}
                width={"40px"}
                height={"40px"}
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#447F44", fontWeight: "bold" }}
                >
                  {t("common.WhatShouldYouDoQuestion")}
                </Typography>
                <Typography variant="body1">
                  {t("rcpeTrendComponent.whatShouldYouDoAnswerRunHours")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={lightIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.howWillYouImproveQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("rcpeTrendComponent.howWillYouImproveAnswerRunHours")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>

      {/* consumption */}

      <Paper className="chart-container">
        <Typography variant="h6">
          {t("rcpeTrendComponent.consumption")}
        </Typography>
        <Bar
          className="alerts-chart"
          data={getData(
            "Consumption",
            consumption.dateLabels,
            consumption.values
          )}
          options={getOptions("Kwh")}
        />

        <Divider sx={{ mb: 2 }} />

        <Typography sx={{ mb: 2 }}>
          {t("common.howToUseTrendsEffectively")}
        </Typography>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img
              style={{ alignSelf: "start" }}
              src={chatIcon}
              width={"40px"}
              height={"40px"}
            />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.whyIsThisImportanQuestion")}
              </Typography>
              <Typography variant="body1">
               
                  {t("rcpeTrendComponent.whyIsThisImportantAnswerConsumption")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box
            className="imp-container"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                style={{ alignSelf: "start" }}
                src={manIcon}
                width={"40px"}
                height={"40px"}
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#447F44", fontWeight: "bold" }}
                >
                  {t("common.WhatShouldYouDoQuestion")}
                </Typography>
                <Typography variant="body1">
                  <ul
                    style={{
                      padding: 0,
                      margin: 0,
                      listStylePosition: "inside",
                    }}
                  >
                    {t("rcpeTrendComponent.whatShouldYouDoAnswerConsumption", {
                      returnObjects: true,
                    }).map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </Typography>
              </Box>
            </Box>

            <Box>
              <Link to={"/AllReports?section=daily-consumption"}>
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#447F44",
                    color: "white",
                    width: "200px",
                    justifySelf: "end",
                    alignSelf: "end",
                    borderRadius: "4px",
                  }}
                >
                  {t("rcpeTrendComponent.dailyConsumption")}
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={lightIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.howWillYouImproveQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("rcpeTrendComponent.howWillYouImproveAnswerConsumption")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>

      {/* production */}
      <Paper className="chart-container">
        <Typography variant="h6">
          {t("rcpeTrendComponent.production")}
        </Typography>
        <Bar
          className="alerts-chart"
          data={getData("Production", production.dateLabels, production.values)}
          options={getOptions("Production")}
        />

        <Divider sx={{ mb: 2 }} />

        <Typography sx={{ mb: 2 }}>
          {t("common.howToUseTrendsEffectively")}
        </Typography>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={chatIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.whyIsThisImportanQuestion")}
              </Typography>

              <Typography variant="body1">
                {t("rcpeTrendComponent.whyIsThisImportantAnswerProduction")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box
            className="imp-container"
            sx={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                style={{ alignSelf: "start" }}
                src={manIcon}
                width={"40px"}
                height={"40px"}
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#447F44", fontWeight: "bold" }}
                >
                  {t("common.WhatShouldYouDoQuestion")}
                </Typography>
                <Typography variant="body1">
                  <ul
                    style={{
                      padding: 0,
                      margin: 0,
                      listStylePosition: "inside",
                    }}
                  >
                    {t("rcpeTrendComponent.whatShouldYouDoAnswerProduction", {
                      returnObjects: true,
                    }).map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={lightIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.howWillYouImproveQuestion")}
              </Typography>
              <Typography variant="body1">
                {t("rcpeTrendComponent.howWillYouImproveAnswerProduction")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>

      {/* production vs run hours */}

      <Paper className="chart-container">
        <Typography variant="h6">
          {t("rcpeTrendComponent.productionVsRunHours")}
        </Typography>
        <Bar
          className="alerts-chart"
          data={getProdVsRunHoursData()}
          options={getRunHoursOptions("Time")}
        />

        <Divider sx={{ mb: 2 }} />

        <Typography sx={{ mb: 2 }}>
          {t("common.howToUseTrendsEffectively")}
        </Typography>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={chatIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.whyIsThisImportanQuestion")}
              </Typography>
              <Typography variant="body1">
                {t(
                  "rcpeTrendComponent.whyIsThisImportantAnswerProductionVsRunHours"
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box
            className="imp-container"
            sx={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                style={{ alignSelf: "start" }}
                src={manIcon}
                width={"40px"}
                height={"40px"}
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#447F44", fontWeight: "bold" }}
                >
                  {t("common.WhatShouldYouDoQuestion")}
                </Typography>
            
                <Typography variant="body1">
                  <ul
                    style={{
                      padding: 0,
                      margin: 0,
                      listStylePosition: "inside",
                    }}
                  >
                    {t("rcpeTrendComponent.whatShouldYouDoAnswerProductionVsRunHours", {
                      returnObjects: true,
                    }).map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="static-content-container">
          <Box className="imp-container">
            <img src={lightIcon} width={"40px"} height={"40px"} />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ color: "#447F44", fontWeight: "bold" }}
              >
                {t("common.howWillYouImproveQuestion")}
              </Typography>
          

              <Typography variant="body1">
                  <ul
                    style={{
                      padding: 0,
                      margin: 0,
                      listStylePosition: "inside",
                    }}
                  >
                    {t("rcpeTrendComponent.howWillYouImproveAnswerProductionVsRunHours", {
                      returnObjects: true,
                    }).map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default RcpeTrend;
