// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#opehome-total {
    display: flex;
    background-color: #F3F6FE;
    height: 100%;
    background: cover;
}

#opehome-right {
    margin-left: 4vw;
}


#opehome-right-2{
    display: flex;
    padding:15px 20px 25px 20px;

    #opehome-right-21{
        height: 84vh;
        background-color: white;
        width: 45vw;
        padding: 3px 0px 3px 0px;
        border-radius: 7px;
        border: 1px solid #DFE6FA;
        background-color: white;
    }
}



#opehome-content{
    display: flex;
    flex-direction: row;

    #opehome-topleft{
        height: 84vh;
        background-color: white;
        width: 50vw;
        padding: 3px 0px 3px 0px;
        border-radius: 7px;
        border: 1px solid #DFE6FA;
        background-color: red;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/Home/Home.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,aAAa;IACb,2BAA2B;;IAE3B;QACI,YAAY;QACZ,uBAAuB;QACvB,WAAW;QACX,wBAAwB;QACxB,kBAAkB;QAClB,yBAAyB;QACzB,uBAAuB;IAC3B;AACJ;;;;AAIA;IACI,aAAa;IACb,mBAAmB;;IAEnB;QACI,YAAY;QACZ,uBAAuB;QACvB,WAAW;QACX,wBAAwB;QACxB,kBAAkB;QAClB,yBAAyB;QACzB,qBAAqB;IACzB;AACJ","sourcesContent":["\n#opehome-total {\n    display: flex;\n    background-color: #F3F6FE;\n    height: 100%;\n    background: cover;\n}\n\n#opehome-right {\n    margin-left: 4vw;\n}\n\n\n#opehome-right-2{\n    display: flex;\n    padding:15px 20px 25px 20px;\n\n    #opehome-right-21{\n        height: 84vh;\n        background-color: white;\n        width: 45vw;\n        padding: 3px 0px 3px 0px;\n        border-radius: 7px;\n        border: 1px solid #DFE6FA;\n        background-color: white;\n    }\n}\n\n\n\n#opehome-content{\n    display: flex;\n    flex-direction: row;\n\n    #opehome-topleft{\n        height: 84vh;\n        background-color: white;\n        width: 50vw;\n        padding: 3px 0px 3px 0px;\n        border-radius: 7px;\n        border: 1px solid #DFE6FA;\n        background-color: red;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
