import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import "./LiveAlerts.css";
import Stationpoints from "../../Commonfns/Stationpoints";

const MAX_ALERTS = 500;

const LiveAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [sectionFilter, setSectionFilter] = useState("");

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_LIVEALERTS_URL);

  //   socket.on('mqtt_alert', (data) => {
  //     console.log('Alert received:', data);
  //     const alertKey = `${data.stationPoint}-${data.parameterName}-${data.eventDescription}`;

  //     setAlerts((prevAlerts) => {
  //       const existingAlertIndex = prevAlerts.findIndex(alert => alert.key === alertKey);

  //       if (existingAlertIndex !== -1) {
  //         // Update the existing alert
  //         const updatedAlerts = [...prevAlerts];
  //         updatedAlerts[existingAlertIndex] = {
  //           ...updatedAlerts[existingAlertIndex],
  //           eventValue: data.eventValue,
  //           dateTime: data.dateTime,
  //           start_time: data.start_time
  //         };
  //         localStorage.setItem('alerts', JSON.stringify(updatedAlerts));
  //         return updatedAlerts;
  //       } else {
  //         // Add a new alert
  //         const newAlerts = [{ ...data, key: alertKey }, ...prevAlerts].slice(0, MAX_ALERTS);
  //         localStorage.setItem('alerts', JSON.stringify(newAlerts));
  //         return newAlerts;
  //       }
  //     });
  //   });

  //   socket.on('connect_error', (err) => {
  //     console.error('Connection Error:', err);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    // Open a WebSocket connection to the server
    const ws = new WebSocket(process.env.REACT_APP_LIVEALERTS_URL);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      console.log(data);
      

      const alertKey = `${data.stationPoint}-${data.parameterName}-${data.eventDescription}`;

      setAlerts((prevAlerts) => {
        const existingAlertIndex = prevAlerts.findIndex(
          (alert) => alert.key === alertKey
        );

        if (existingAlertIndex !== -1) {
          // Update the existing alert
          const updatedAlerts = [...prevAlerts];
          updatedAlerts[existingAlertIndex] = {
            ...updatedAlerts[existingAlertIndex],
            eventValue: data.eventValue,
            dateTime: data.dateTime,
            start_time: data.start_time,
          };
          localStorage.setItem("alerts", JSON.stringify(updatedAlerts));
          return updatedAlerts;
        } else {
          // Add a new alert
          const newAlerts = [{ ...data, key: alertKey }, ...prevAlerts].slice(
            0,
            MAX_ALERTS
          );
          localStorage.setItem("alerts", JSON.stringify(newAlerts));
          return newAlerts;
        }
      });
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      ws.close();
      setAlerts([])
    }
  }, []);
  // useEffect(() => {
  //   const storedAlerts = JSON.parse(localStorage.getItem("alerts") || "[]");
  //   setAlerts(storedAlerts);
  // }, []);

  const handleStationPointChange = (selectedStationPoint) => {
    setSectionFilter(selectedStationPoint);
  };

  const filteredAlerts = alerts.filter((alert) =>
    alert.stationPoint.toLowerCase().includes(sectionFilter.toLowerCase())
  );

  return (
    <div id="livealerts-total">
      <div id="livealerts-right">
        <div id="livealerts-right-2">
          <div id="livealerts-right-21">
            <label>
              <Stationpoints onStationPointChange={handleStationPointChange} />
            </label>
          </div>

          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Section</th>
                <th>Parameter</th>
                <th>Event</th>
                <th>Event</th>
                <th>Event</th>
                <th>Date & Start Time</th>
              </tr>
            </thead>
            <tbody>
              {filteredAlerts.map((alert, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{alert.stationPoint}</td>
                  <td>{alert.parameterName}</td>
                  <td>{alert.eventDescription}</td>
                  <td>{alert.eventType}</td>
                  <td>{alert.eventValue}</td>
                  <td>{alert.start_time}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LiveAlerts;
