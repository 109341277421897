export const whatSholdYouDo = [
  "alert occurrence time",
  "Alert duration",
  "location of alert",
  "Filter the type of alert",
  "Diagnose the alert to solve the problem.",
];

export const timeStringToDecimalHours = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours + minutes / 60;
};

export const hoursToTimeString = (hours) => {
  const totalMinutes = Math.round(hours * 60);
  const formattedHours = String(Math.floor(totalMinutes / 60)).padStart(2, "0");
  const formattedMinutes = String(totalMinutes % 60).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

export const decimalHoursToTimeString = (decimalHours) => {
  const totalMinutes = Math.round(decimalHours * 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = Math.round(((decimalHours % 1) * 3600) % 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
};

export const todImpPoints = [
  "TOD1 & TOD5: Incentives - 00:00:00 to 06:00:00 & 22:00:00 to 24:00:00",
  "TOD2 & TOD4: Penalty - 06:00:00 to 10:00:00 & 18:00:00 to 22:00:00",
  "TOD3: Common - 10:00:00 to 18:00:00",
];


export const getRunHoursOptions = (yLabel = 'Hours') => {
  return {
    scales: {
      x: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: true,
          text: "Date",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
  
        ticks: {
          callback: (value) => hoursToTimeString(value),
          beginAtZero: true,
        },
        title: {
          display: true,
          text: yLabel,
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    animation: {
      duration: 50,
      easing: undefined,
    },
    plugins: {
      tooltip: {
        animation: false,
        duration: 0,
        callbacks: {
          label: (context) => {
            const datasetLabel = context.dataset.label;
            const value = context.raw;
  
            const timeString = decimalHoursToTimeString(value);
  
            if(datasetLabel === 'Production') {
              return `Production Count: ${value}`;
            }
  
            return `${datasetLabel}: ${timeString}`;
          },
        },
      },
    },
  };
  
}

export const getData = (label = "", labels = [], data = []) => {
  return {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor: labels.map((_date) => "#6174D7"),
        borderWidth: 1,
        maxBarThickness: 100,
      },
    ],
  };
};

export const secondsToTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600); // Get the hours part
  const minutes = Math.floor((totalSeconds % 3600) / 60); // Get the minutes part
  const seconds = totalSeconds % 60; // Get the seconds part

  // Pad the hours, minutes, and seconds with leading zeros if necessary
  const pad = (num) => String(num).padStart(2, '0');

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};



export const sampleData = [
  {
    Date: "2024-09-01",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:00:00", End_time: "01:00:00", Consumption: 100, Duration: 3600 },
        { Start_time: "03:00:00", End_time: "04:00:00", Consumption: 150, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:00:00", End_time: "02:00:00", Consumption: 50, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:00:00", End_time: "07:00:00", Consumption: 200, Duration: 3600 },
        { Start_time: "08:00:00", End_time: "09:00:00", Consumption: 250, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:00:00", End_time: "08:00:00", Consumption: 100, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:00:00", End_time: "12:00:00", Consumption: 300, Duration: 7200 },
        { Start_time: "14:00:00", End_time: "16:00:00", Consumption: 350, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:00:00", End_time: "14:00:00", Consumption: 200, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:00:00", End_time: "19:00:00", Consumption: 150, Duration: 3600 },
        { Start_time: "20:00:00", End_time: "21:00:00", Consumption: 180, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:00:00", End_time: "20:00:00", Consumption: 120, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 100, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:00:00", End_time: "24:00:00", Consumption: 60, Duration: 3600 }
      ]
    }
  },
  {
    Date: "2024-09-02",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-03",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-04",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-05",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-06",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-07",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-08",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-09",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-10",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-11",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-12",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-13",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-14",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-15",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-16",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-17",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-18",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-19",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-20",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-21",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },

  {
    Date: "2024-09-22",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-23",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  {
    Date: "2024-09-24",
    Tod1: {
      uptime_segment: [
        { Start_time: "00:30:00", End_time: "01:30:00", Consumption: 120, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "01:30:00", End_time: "02:30:00", Consumption: 70, Duration: 3600 }
      ]
    },
    Tod2: {
      uptime_segment: [
        { Start_time: "06:15:00", End_time: "07:15:00", Consumption: 220, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "07:15:00", End_time: "08:15:00", Consumption: 110, Duration: 3600 }
      ]
    },
    Tod3: {
      uptime_segment: [
        { Start_time: "10:30:00", End_time: "12:30:00", Consumption: 320, Duration: 7200 }
      ],
      downtime_segment: [
        { Start_time: "12:30:00", End_time: "14:30:00", Consumption: 250, Duration: 7200 }
      ]
    },
    Tod4: {
      uptime_segment: [
        { Start_time: "18:15:00", End_time: "19:15:00", Consumption: 160, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "19:15:00", End_time: "20:15:00", Consumption: 130, Duration: 3600 }
      ]
    },
    Tod5: {
      uptime_segment: [
        { Start_time: "22:00:00", End_time: "23:00:00", Consumption: 110, Duration: 3600 }
      ],
      downtime_segment: [
        { Start_time: "23:15:00", End_time: "24:00:00", Consumption: 50, Duration: 2700 }
      ]
    }
  },
  
];
