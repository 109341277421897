import React, { useState, useEffect } from 'react'
import './Pending_Diagnosis.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Pending_Diagnosis = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [isAssignEnabled, setIsAssignEnabled] = useState({});
    const [loggedInUser, setLoggedInUser] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/users`);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchDoItLaterTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/do-it-later`);
                setTasks(response.data);
            } catch (error) {
                console.error('Error fetching DO IT LATER tasks:', error);
            }
        };

        fetchDoItLaterTasks();
    }, []);

    const handleUserSelect = (taskId, event) => {
        const user = event.target.value;
        setSelectedUser((prevSelectedUser) => ({
            ...prevSelectedUser,
            [taskId]: user,
        }));
        setIsAssignEnabled((prevIsAssignEnabled) => ({
            ...prevIsAssignEnabled,
            [taskId]: user !== '',
        }));
    };

    const handleAssign = async (taskId) => {
        const user = users.find(user => user.username === selectedUser[taskId]);

        if (user && loggedInUser) {
            const assignedTaskDetails = {
                assignedto: user.username,
                assignedtouserid: user.user_id,
                assignedtouserlevel: user.level,
                assignedtodesignation: user.designation,
                modifiedby: loggedInUser.username,
                assigneeuserlevel: loggedInUser.level,
                assigneedesignation: loggedInUser.designation,
            };

            try {
                const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/${taskId}/do-it-later`, assignedTaskDetails);

                if (response.data) {
                    setTasks((prevTasks) =>
                        prevTasks.map((t) => (t._id === response.data._id ? response.data : t))
                    );
                    alert('Task Assigned Successfully');
                } else {
                    console.error('Failed to assign task');
                }
            } catch (error) {
                console.error('Error assigning task:', error);
            }

            setSelectedUser((prevSelectedUser) => ({
                ...prevSelectedUser,
                [taskId]: '',
            }));
            setIsAssignEnabled((prevIsAssignEnabled) => ({
                ...prevIsAssignEnabled,
                [taskId]: false,
            }));
        } else {
            console.error("User or logged-in user information is missing.");
        }
    };

    const handleDiagnoseClick = (token_id) => {
        console.log("Navigating to diagnose page for token ID:", token_id);
        navigate(`/Diagnose/${token_id}`);
    };
    return (

        <div id='pending_diagnosis-total'>
            <table>
                <thead>
                    <tr>
                        <th>S. No.</th>
                        <th>Token ID</th>
                        <th>Pending Diagnose</th>
                        <th>Created By</th>
                        <th>Created At</th>
                        <th>Assign Agin</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.length > 0 ? (
                        tasks.map((task, index) => (
                            <tr key={task._id}>
                                <td>{index + 1}</td>
                                <td>{task.token_id}</td>
                                <td>{task.alert_heading}</td>
                                <td>{task.createdby}</td>
                                <td>{task.createdat}</td>

                                {loggedInUser?.level !== 'Level-4' && (
                                    <td style={{ display: 'flex', gap: '8px' }}>
                                        <select
                                            value={selectedUser[task._id] || ''}
                                            onChange={(e) => handleUserSelect(task._id, e)}
                                        >
                                            <option value="">Select the User</option>
                                            {users.map(user => (
                                                <option key={user._id} value={user.username}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                             onClick={() => handleAssign(task._id)}
                                             className={isAssignEnabled[task._id] ? 'enabled' : 'disabled'}
                                             disabled={!isAssignEnabled[task._id]}
                                        >
                                            Assign
                                        </button>
                                    </td>
                                )}
                                {/* <td style={{display:'flex', gap:'8px'}}>
                                    <select
                                        value={selectedUser[task._id] || ''}
                                        onChange={(e) => handleUserSelect(task._id, e)}
                                    >
                                        <option value="">Select the User</option>
                                        {users.map(user => (
                                            <option key={user._id} value={user.username}>
                                                {user.name}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={() => handleAssign(task._id)}
                                        disabled={!isAssignEnabled[task._id]}
                                    >
                                        Assign
                                    </button>
                                    
                                </td> */}
                                <td><button onClick={() => handleDiagnoseClick(task.token_id)} >View</button></td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" style={{ textAlign: 'center' }}>No Pending Diagnose available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default Pending_Diagnosis






// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import './Doitlater.css';

// const Doitlater = () => {
//     const [tasks, setTasks] = useState([]);
//     const [users, setUsers] = useState([]);
//     const [selectedUser, setSelectedUser] = useState({});
//     const [isAssignEnabled, setIsAssignEnabled] = useState({});
//     const [loggedInUser, setLoggedInUser] = useState(null);

//     useEffect(() => {
//         const user = JSON.parse(localStorage.getItem('user'));
//         if (user && user.username) {
//             setLoggedInUser(user);
//         }
//     }, []);

//     useEffect(() => {
//         const fetchUsers = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/users`);
//                 setUsers(response.data);
//             } catch (error) {
//                 console.error('Error fetching users:', error);
//             }
//         };

//         fetchUsers();
//     }, []);

//     useEffect(() => {
//         const fetchDoItLaterTasks = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/do-it-later`);
//                 setTasks(response.data);
//             } catch (error) {
//                 console.error('Error fetching DO IT LATER tasks:', error);
//             }
//         };

//         fetchDoItLaterTasks();
//     }, []);

//     const handleUserSelect = (taskId, event) => {
//         const user = event.target.value;
//         setSelectedUser((prevSelectedUser) => ({
//             ...prevSelectedUser,
//             [taskId]: user,
//         }));
//         setIsAssignEnabled((prevIsAssignEnabled) => ({
//             ...prevIsAssignEnabled,
//             [taskId]: user !== '',
//         }));
//     };

//     const handleAssign = async (taskId) => {
//         const user = users.find(user => user.username === selectedUser[taskId]);

//         if (user && loggedInUser) {
//             const assignedTaskDetails = {
//                 assignedto: user.username,
//                 assignedtouserid: user.user_id,
//                 assignedtouserlevel: user.level,
//                 assignedtodesignation: user.designation,
//                 modifiedby: loggedInUser.username,
//                 assigneeuserlevel: loggedInUser.level,
//                 assigneedesignation: loggedInUser.designation,
//             };

//             try {
//                 const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/${taskId}/do-it-later`, assignedTaskDetails);

//                 if (response.data) {
//                     setTasks((prevTasks) =>
//                         prevTasks.map((t) => (t._id === response.data._id ? response.data : t))
//                     );
//                     alert('Task Assigned Successfully');
//                 } else {
//                     console.error('Failed to assign task');
//                 }
//             } catch (error) {
//                 console.error('Error assigning task:', error);
//             }

//             setSelectedUser((prevSelectedUser) => ({
//                 ...prevSelectedUser,
//                 [taskId]: '',
//             }));
//             setIsAssignEnabled((prevIsAssignEnabled) => ({
//                 ...prevIsAssignEnabled,
//                 [taskId]: false,
//             }));
//         } else {
//             console.error("User or logged-in user information is missing.");
//         }
//     };

//     return (
//         <div id="doitlater-total">
//             <div id="doitlater-right">
//                 <div id="doitlater-right-1">
//                     <div id="doitlater-heading">DO IT LATER Tasks</div>
//                 </div>
//                 <div id="doitlater-right-2">
//                     {tasks.length === 0 ? (
//                         <div>No tasks to show.</div>
//                     ) : (
//                         <table>
//                             <thead>
//                                 <tr>
//                                     <th>Task ID</th>
//                                     <th>Token ID</th>
//                                     <th>Assigned To</th>
//                                     <th>Created By</th>
//                                     <th>Created At</th>
//                                     <th>Status</th>
//                                     <th>Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {tasks.map((task) => (
//                                     <tr key={task._id}>
//                                         <td>{task.task_id}</td>
//                                         <td>{task.token_id}</td>
//                                         <td>{task.assignedto || 'Unassigned'}</td>
//                                         <td>{task.createdby}</td>
//                                         <td>{task.createdat}</td>
//                                         <td>{task.status}</td>
//                                         <td>
//                                             <select
//                                                 value={selectedUser[task._id] || ''}
//                                                 onChange={(e) => handleUserSelect(task._id, e)}
//                                             >
//                                                 <option value="">Select the User</option>
//                                                 {users.map(user => (
//                                                     <option key={user._id} value={user.username}>
//                                                         {user.name}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                             <button
//                                                 onClick={() => handleAssign(task._id)}
//                                                 disabled={!isAssignEnabled[task._id]}
//                                             >
//                                                 Assign
//                                             </button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Doitlater;