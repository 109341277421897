

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../../Images/logo.svg';
import "../../Operator/Sidebar/Sidebar.css";
import './Supersidebar.css'
import { IoMdLogOut } from "react-icons/io";

import { BiSolidHome } from "react-icons/bi";
import { TbAlertSquareFilled } from "react-icons/tb";
import { MdDescription } from "react-icons/md";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { BiSolidReport } from "react-icons/bi";
import { SiGoogleforms } from "react-icons/si";
import { GrTasks } from "react-icons/gr";
import { GiPowerGenerator } from "react-icons/gi";
import { TbDeviceIpadMinus } from "react-icons/tb";
import { FaRegUserCircle } from "react-icons/fa";
import { PiUserSwitchBold } from "react-icons/pi";
import { FaChartBar } from "react-icons/fa";
const Supervisorsidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [username, setUsername] = useState('');
    const [designation, setDesignation] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        // Retrieve the user data from local storage
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setUsername(user.username);
            setDesignation(user.designation); // Assuming "level" is used for the designation
        }
    }, []);
    const onleave = () => {
        setIsOpen(false);
        setActiveDropdown(null); // Close any open dropdown when the sidebar loses focus
    };

    const onenter = () => {
        setIsOpen(true);
    };

    const toggleDropdown = (dropdownName) => {
        if (activeDropdown === dropdownName) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(dropdownName);
        }
    };

    const handleLogout = () => {
        // setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('accessedLevel');
        navigate('/');
    };

    return (
        <div>
            <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`} onMouseLeave={onleave} onMouseEnter={onenter}>
                <div className="trigger">
                    <Link to='/Alldash'>
                        <div className="sidbarMainlogo">
                            <img src={Logo} alt="" />
                        </div>
                    </Link>
                    <FontAwesomeIcon icon={isOpen ? '' : faBars} />
                </div>

                <Link className="sidebar-position">
                    <FaRegUserCircle className="sidebar-iconss" />
                    <label style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Username: {username}</span>
                        <span style={{ marginTop: '3px' }}> Designation: Supervisor</span>
                    </label>
                </Link>

                <Link to='/Alldash' className="sidebar-position">
                    <BiSolidHome />
                    <span>Home</span>
                </Link>

                {/* <Link to='/Trends' className="sidebar-position">
                    <FaHome />
                    <span>Trends</span>
                </Link> */}


                <Link to='/CriticalAlerts' className="sidebar-position">
                    <TbAlertSquareFilled />
                    <span>Critical Alerts</span>
                </Link>

                <Link to='/LiveSummary' className="sidebar-position">
                    <MdDescription />
                    <span>Live Plant Summary</span>
                </Link>

                <Link to='/Calenderscombine' className="sidebar-position">
                    <CalendarMonthIcon />
                    <span>Monthly Calender</span>
                </Link>

                <Link to='/AllReports' className="sidebar-position">
                    <BiSolidReport />
                    <span>Reporting</span>
                </Link>
                <Link to='/Forms' className="sidebar-position">
                    <SiGoogleforms />
                    <span>Forms</span>
                </Link>

                <Link to='/Tasks' className="sidebar-position">
                    <GrTasks />
                    <span>Tasks</span>
                </Link>

                <Link to='/GeneratorsKPI' className="sidebar-position">
                    <GiPowerGenerator />
                    <span>Energy Generators</span>
                </Link>

                <Link to='/Masterforms' className="sidebar-position">
                    <TbDeviceIpadMinus />
                    <span>Master - Forms</span>
                </Link>
                <Link to='/AllTrends' className="sidebar-position">
                    <FaChartBar className="sidebar-iconss1" />
                    <span>Trends</span>
                </Link>
                <Link to='/Loginscreens' className="sidebar-position">
                    <PiUserSwitchBold className="sidebar-iconss1" />
                    <span>Switch</span>
                </Link>

                {/* <Link to='/Masterforms' className="sidebar-position">
                    <IoMdLogOut  />
                    <span>Logout</span>
                </Link> */}

                <div className="sidebar-position" onClick={() => toggleDropdown('operatorForms')}>
                    <GrTasks />
                    <span>Diagnose Manual forms</span>
                    <FontAwesomeIcon icon={activeDropdown === 'operatorForms' ? faCaretUp : faCaretDown} />
                </div>
                {activeDropdown === 'operatorForms' && (
                    <div className="dropdown-menu">
                        <Link to='/Voltageregulator' className="sidebar-position">
                            <BiSolidHome />
                            <span>Voltageregulator</span>
                        </Link>
                        <Link to='/Stabilizerform' className="sidebar-position">
                            <BiSolidHome />
                            <span> Stabilizerform</span>
                        </Link>
                        <Link to='/VoltageGroundingresistance' className="sidebar-position">
                            <BiSolidHome />
                            <span> VoltageGroundingresistance</span>
                        </Link>
                        <Link to='/EnvironmentalFactors' className="sidebar-position">
                            <BiSolidHome />
                            <span> EnvironmentalFactors</span>
                        </Link>
                        <Link to='/Powersurge_Groundingresistance' className="sidebar-position">
                            <BiSolidHome />
                            <span> Powersurge Groundingresistance</span>
                        </Link>
                        <Link to='/Internal_Fault' className="sidebar-position">
                            <BiSolidHome />
                            <span> Internal Fault</span>
                        </Link>
                        <Link to='/Harmonic_Form' className="sidebar-position">
                            <BiSolidHome />
                            <span> Harmonic Form</span>
                        </Link>
                        <Link to='/Power_Quality_Form' className="sidebar-position">
                            <BiSolidHome />
                            <span> Power Quality Form</span>
                        </Link>
                        <Link to='/Oil_Winding_Temperature_Form' className="sidebar-position">
                            <BiSolidHome />
                            <span> Oil Winding Temperature Form</span>
                        </Link>

                        <Link to='/Hotspot_Test_Form' className="sidebar-position">
                            <BiSolidHome />
                            <span> Hotspot Test Form</span>
                        </Link>
                        <Link to='/Impedance_Test_Form' className="sidebar-position">
                            <BiSolidHome />
                            <span> Impedance Test Form</span>
                        </Link>

                        <Link to='/Loose_Connection_and_Corrosion_Form' className="sidebar-position">
                            <BiSolidHome />
                            <span> Loose Connection and Corrosion Form</span>
                        </Link>
                        <Link to='/APFC_Signalling_Manual_Form' className="sidebar-position">
                            <BiSolidHome />
                            <span> APFC Signalling Manual Form</span>
                        </Link>
                        <Link to='/Capacitance_Manual_Form' className="sidebar-position">
                            <BiSolidHome />
                            <span> Capacitance Manual Form</span>
                        </Link>
                    </div>
                )}

                <Link onClick={handleLogout} to='/' className="sidebar-position">
                    <IoMdLogOut />
                    <span>Logout</span>
                </Link>

            </div>
        </div>
    );
};

export default Supervisorsidebar;