

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Unfilled_forms.css';

const parseDate = (dateString) => {
  const [dd, mm, yyyy] = dateString.split('-');
  return new Date(`${yyyy}-${mm}-${dd}`);
};

const parseMonth = (monthString) => {
  const [mm, yyyy] = monthString.split('-');
  return new Date(`${yyyy}-${mm}-01`);
};

const formatDate = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${dd}-${mm}-${yyyy}`;
};

const formatMonth = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  return `${mm}-${yyyy}`;
};

const Unfilled_forms = ({ setYesterdayCount = () => {}, setMonthlyCount = () => {} }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const secondDayOfCurrentMonth = new Date(currentYear, currentMonth, 2);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstFilledResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/first-filled-date`);
        const { firstProductionDate, firstMonitoringDate } = firstFilledResponse.data;

        const productionResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry`);
        const fetchedProductionData = productionResponse.data;

        const monitoringResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformer-monitoring-logs`);
        const fetchedMonitoringData = monitoringResponse.data;

        const firstFilledPowerBillsResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/first-filled-powerbillsdate`);
        const firstMonth = firstFilledPowerBillsResponse.data.firstFilledMonth;

        const powerBillsResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/power-bills`);
        const powerBillsEntries = Array.isArray(powerBillsResponse.data) ? powerBillsResponse.data : [];

        const calculateMissingDates = (startDate, fetchedData) => {
          const missingDates = [];
          const start = parseDate(startDate);
          const end = yesterday;

          for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
            const formattedDate = formatDate(d);
            const entryExists = fetchedData.some(item => item.date === formattedDate);

            if (!entryExists) {
              missingDates.push(formattedDate);
            }
          }
          return missingDates.sort((a, b) => parseDate(b) - parseDate(a)); // Sort by date in descending order
        };

        const calculateMissingMonths = () => {
          const missing = [];

          // If the firstFilledMonth is "N/A", skip the calculation and return an empty array
          if (firstMonth === "N/A") {
            return missing;
          }
          const startDate = parseMonth(firstMonth);
          const endDate = new Date(currentYear, currentMonth);

          for (let d = new Date(startDate); d <= endDate; d.setMonth(d.getMonth() + 1)) {
            const formattedMonth = formatMonth(d);
            const entryExists = powerBillsEntries.some(item => item.month === formattedMonth);

            if (!entryExists) {
              missing.push(formattedMonth);
            }
          }

          const currentMonthFormatted = formatMonth(new Date(currentYear, currentMonth));
          const currentMonthDataExists = powerBillsEntries.some(item => item.month === currentMonthFormatted);

          if (!currentMonthDataExists && today >= secondDayOfCurrentMonth) {
            missing.push(currentMonthFormatted);
          }

          return missing.sort((a, b) => parseMonth(b) - parseMonth(a)); // Sort by month in descending order
        };

        const missingProductionDates = calculateMissingDates(firstProductionDate, fetchedProductionData);
        const missingMonitoringDates = calculateMissingDates(firstMonitoringDate, fetchedMonitoringData);
        const missingMonthsList = calculateMissingMonths();

        const allTasks = [
          ...missingProductionDates.map((date, index) => ({
            sNo: index + 1,
            formName: 'Production Entry',
            date,
            // <Link to={`/Forms?section=Productionentry-1&date=${date}`}>
            link: `/Forms?section=Productionentry-1&date=${date}`,
            action: 'Form',
          })),
          ...missingMonitoringDates.map((date, index) => ({
            sNo: missingProductionDates.length + index + 1,
            formName: 'Transformer Monitoring',
            date,
            link: `/Forms?section=Transformermonitoring-1&date=${date}`,
            action: 'Form',
          })),
          ...missingMonthsList.map((month, index) => ({
            sNo: missingProductionDates.length + missingMonitoringDates.length + index + 1,
            formName: 'Power Bills',
            date: month,
            link: `/Forms?section=PowerBills-1&month=${month}`,
            action: 'Form',
          })),
        ];

        setTasks(allTasks);

        if (setYesterdayCount) {
          setYesterdayCount(missingProductionDates.length + missingMonitoringDates.length);
        }
        if (setMonthlyCount) {
          setMonthlyCount(missingMonthsList.length);
        }

      } catch (err) {
        setError('Error fetching data');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [yesterday, currentMonth, currentYear, setYesterdayCount, setMonthlyCount]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='unfilled_forms_total'>
      <table>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Form Name</th>
            <th>Date/Month</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tasks.length > 0 ? (
            tasks.map((task) => (
              <tr key={task.sNo}>
                <td>{task.sNo}</td>
                <td>{task.formName}</td>
                <td>{task.date}</td>
                <td>
                  <Link to={task.link}>
                    <button>{task.action}</button>
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>No Unfilled Forms</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Unfilled_forms;

