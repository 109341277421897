// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dashtypeofload-total{
    margin-top: 2vh;
    height: 100%;   
    background-color: white;
    border: 1px solid #DFE6FA;
    border-radius: 10px;
    padding: 15px;

    span{
        font-weight: bold;
        font-size: 20px;
    }
}

#dashtypeofload-piechart{
    margin-top: 3vh;
    width: 95%;
    height: 500px;
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Monthly-Dash/Typeofload/DashTypeofload.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;;IAEb;QACI,iBAAiB;QACjB,eAAe;IACnB;AACJ;;AAEA;IACI,eAAe;IACf,UAAU;IACV,aAAa;AACjB","sourcesContent":["#dashtypeofload-total{\n    margin-top: 2vh;\n    height: 100%;   \n    background-color: white;\n    border: 1px solid #DFE6FA;\n    border-radius: 10px;\n    padding: 15px;\n\n    span{\n        font-weight: bold;\n        font-size: 20px;\n    }\n}\n\n#dashtypeofload-piechart{\n    margin-top: 3vh;\n    width: 95%;\n    height: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
