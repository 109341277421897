// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pending_results-total {
    background-color: white;
    border: 1px solid #DFE6FA;
    min-height: 70vh;
    border-radius: 10px;
    align-content: center;
    justify-content: center;
    table {
        margin: 20px;
        width: 88vw;
        min-height: 62vh;
        display: block;
        overflow-y: scroll;

        /* th{
            padding: 15px 51px;
        }

        td{
            padding: 15px 0px;
        } */


        th,
        td {
            width: calc(88vw/ 4);
            /* Adjust this to fit your column count */
        }

        button {
            background-color: #D8E1FF;
            color: black;
            border: 1px solid white;
            border: none;
            border-radius: 4px;
            padding: 10px 20px;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 1440px) {

#pending_results-total {
    table {
        width: 90vw;
    }
}
}

@media screen and (min-width: 1920px) {
    #pending_results-total {
        table {
            width: 91vw;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Diagnose/Pendingtasks/Pending_Results/Pending_Results.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;IACvB;QACI,YAAY;QACZ,WAAW;QACX,gBAAgB;QAChB,cAAc;QACd,kBAAkB;;QAElB;;;;;;WAMG;;;QAGH;;YAEI,oBAAoB;YACpB,yCAAyC;QAC7C;;QAEA;YACI,yBAAyB;YACzB,YAAY;YACZ,uBAAuB;YACvB,YAAY;YACZ,kBAAkB;YAClB,kBAAkB;YAClB,eAAe;QACnB;IACJ;AACJ;;AAEA;;AAEA;IACI;QACI,WAAW;IACf;AACJ;AACA;;AAEA;IACI;QACI;YACI,WAAW;QACf;IACJ;AACJ","sourcesContent":["#pending_results-total {\n    background-color: white;\n    border: 1px solid #DFE6FA;\n    min-height: 70vh;\n    border-radius: 10px;\n    align-content: center;\n    justify-content: center;\n    table {\n        margin: 20px;\n        width: 88vw;\n        min-height: 62vh;\n        display: block;\n        overflow-y: scroll;\n\n        /* th{\n            padding: 15px 51px;\n        }\n\n        td{\n            padding: 15px 0px;\n        } */\n\n\n        th,\n        td {\n            width: calc(88vw/ 4);\n            /* Adjust this to fit your column count */\n        }\n\n        button {\n            background-color: #D8E1FF;\n            color: black;\n            border: 1px solid white;\n            border: none;\n            border-radius: 4px;\n            padding: 10px 20px;\n            cursor: pointer;\n        }\n    }\n}\n\n@media screen and (min-width: 1440px) {\n\n#pending_results-total {\n    table {\n        width: 90vw;\n    }\n}\n}\n\n@media screen and (min-width: 1920px) {\n    #pending_results-total {\n        table {\n            width: 91vw;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
