
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';

// const DesignationTable = () => {
//   const [designations, setDesignations] = useState([]);
//   const [editData, setEditData] = useState(null);

//   useEffect(() => {
//     fetchDesignations();
//   }, []);

//   const fetchDesignations = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
//       setDesignations(response.data);
//     } catch (error) {
//       console.error('Error fetching designations:', error);
//     }
//   };

//   const handleEditClick = (designation) => {
//     setEditData(designation);
//   };

//   const handleEditChange = (e) => {
//     const { name, value } = e.target;
//     setEditData({ ...editData, [name]: value });
//   };

//   const handleEditSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/designations/${editData._id}`, editData);
//       fetchDesignations();
//       setEditData(null);
//       alert("Designation updated successfully");
//     } catch (error) {
//       console.error('Error updating designation:', error.response ? error.response.data : error.message);
//     }
//   };

//   const handleToggleStatus = async (id, currentStatus) => {
//     try {
//       await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/designations/${id}`, { status: !currentStatus });
//       fetchDesignations();
//     } catch (error) {
//       console.error('Error toggling status:', error);
//     }
//   };

//   return (
//     <div>
//       <h2>Designations</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>Designation ID</th>
//             <th>Designation Name</th>
//             <th>Status</th>
//             <th>Created By</th>
//             <th>Created At</th>
//             <th>Modified By</th>
//             <th>Modified At</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {designations.map((designation) => (
//             <tr key={designation._id}>
//               <td>{designation.designation_id}</td>
//               <td>{designation.designation}</td>
//               <td>
//                 <button onClick={() => handleToggleStatus(designation._id, designation.status)}>
//                   {designation.status ? 'Active' : 'Inactive'}
//                 </button>
//               </td>
//               <td>{designation.createdby}</td>
//               <td>{designation.createdat}</td>
//               <td>{designation.modifiedby}</td>
//               <td>{designation.modifiedat}</td>
//               <td>
//                 <button onClick={() => handleEditClick(designation)}>Edit</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {editData && (
//         <Popup open={true} onClose={() => setEditData(null)}>
//           <div>
//             <h3>Edit Designation</h3>
//             <form onSubmit={handleEditSubmit}>
//               <label>
//                 <span>Designation ID</span>
//                 <input type="text" name="designation_id" value={editData.designation_id} onChange={handleEditChange} disabled />
//               </label>
//               <label>
//                 <span>Designation Name</span>
//                 <input type="text" name="designation" value={editData.designation} onChange={handleEditChange} required />
//               </label>
//               <label>
//                 <span>Created By</span>
//                 <input type="text" name="createdby" value={editData.createdby} onChange={handleEditChange} disabled />
//               </label>
//               <label>
//                 <span>Created At</span>
//                 <input type="text" name="createdat" value={editData.createdat} onChange={handleEditChange} disabled />
//               </label>
//               <label>
//                 <span>Modified By</span>
//                 <input type="text" name="modifiedby" value={editData.modifiedby} onChange={handleEditChange} disabled/>
//               </label>
//               <label>
//                 <span>Modified At</span>
//                 <input type="text" name="modifiedat" value={editData.modifiedat} onChange={handleEditChange} disabled />
//               </label>
//               <button type="submit">Update</button>
//               <button type="button" onClick={() => setEditData(null)}>Cancel</button>
//             </form>
//           </div>
//         </Popup>
//       )}
//     </div>
//   );
// };

// export default DesignationTable;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';

// const DesignationTable = () => {
//     const [designations, setDesignations] = useState([]);
//     const [editData, setEditData] = useState(null);
//     const [loggedInUser, setLoggedInUser] = useState(null);

//    useEffect(() => {
//     fetchDesignations();
//     fetchUsers();
//     // Fetch logged-in user information from local storage or context
//     const user = JSON.parse(localStorage.getItem('user'));
//     setLoggedInUser(user);
//   }, []);

//     const fetchDesignations = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
//             setDesignations(response.data);
//         } catch (error) {
//             console.error('Error fetching designations:', error);
//         }
//     };

//     const handleEditClick = (designation) => {
//         setEditData(designation);
//     };

//     const handleEditChange = (e) => {
//         const { name, value } = e.target;
//         setEditData({ ...editData, [name]: value });
//     };

//     const handleEditSubmit = async (e) => { 
//         e.preventDefault();
//         const updateddesignationData = {
//             ...editData,
//             modifiedby: loggedInUser.username 
//           };

//         try {
//             console.log('Submitting Edit Data:', updateddesignationData);
//             const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/designations/${editData._id}`, editData);
//             console.log('Response:', response.data);
//             fetchDesignations();
//             setEditData(null);
//             alert("Designation updated successfully");
//         } catch (error) {
//             console.error('Error updating designation:', error.response ? error.response.data : error.message);
//             if (error.response && error.response.data.message === 'Designation name must be unique') {
//                 alert('Designation name already exists. Please choose a different name.');
//             } else {
//                 alert('Failed to update designation. Please try again later.');
//             }
//         }
//     };

//     const handleToggleStatus = async (id, currentStatus) => {
//         // console.log(`Toggling status for ID: ${id}, Current Status: ${currentStatus}`);

//          try {
//         const editData = {
//             status: !currentStatus,
//             modifiedby: loggedInUser.username // Update modifiedby field with logged-in user's username
//         };
//             const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/designations/${id}/toggle-status`, { status: editData });
//             // console.log(`Toggle status response: `, response.data);
//             fetchDesignations();
//         } catch (error) {
//             console.error('Error toggling status:', error.response ? error.response.data : error.message);
//         }
//     };



//     return (
//         <div>
//             <h2>Designations</h2>
//             <table>
//                 <thead>
//                     <tr>
//                         <th>Designation ID</th>
//                         <th>Designation Level</th>
//                         <th>Designation Name</th>
//                         <th>Status</th>
//                         <th>Created By</th>
//                         <th>Created At</th>
//                         <th>Modified By</th>
//                         <th>Modified At</th>
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {designations.map((designation) => (
//                         <tr key={designation._id}>
//                             <td>{designation.designation_id}</td>
//                             <td>{designation.level}</td>
//                             <td>{designation.designation}</td>
//                             <td>
//                                 <button onClick={() => handleToggleStatus(designation._id, designation.status)}>
//                                     {designation.status ? 'Active' : 'Inactive'}
//                                 </button>
//                             </td>
//                             <td>{designation.createdby}</td>
//                             <td>{designation.createdat}</td>
//                             <td>{designation.modifiedby}</td>
//                             <td>{designation.modifiedat}</td>
//                             <td>
//                                 <button onClick={() => handleEditClick(designation)}>Edit</button>
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>

//             {editData && (
//                 <Popup open={true} onClose={() => setEditData(null)}>
//                     <div>
//                         <h3>Edit Designation</h3>
//                         <form onSubmit={handleEditSubmit}>
//                             <label>
//                                 <span>Designation ID</span>
//                                 <input type="text" name="designation_id" value={editData.designation_id} onChange={handleEditChange} disabled />
//                             </label>
//                             <label>
//                                 <span>Level</span>
//                                 <select
//                                     name="level"
//                                     value={editData.level}
//                                     onChange={handleEditChange}
//                                     required
//                                 >
//                                     <option value=''>Select Level</option>
//                                     <option value='Level-1'>Level-1</option>
//                                     <option value='Level-2'>Level-2</option>
//                                     <option value='Level-3'>Level-3</option>
//                                     <option value='Level-4'>Level-4</option>
//                                 </select>
//                             </label>
//                             <label>
//                                 <span>Designation Name</span>
//                                 <input type="text" name="designation" value={editData.designation} onChange={handleEditChange} required />
//                             </label>
//                             <label>
//                                 <span>Created By</span>
//                                 <input type="text" name="createdby" value={editData.createdby} onChange={handleEditChange} disabled />
//                             </label>
//                             <label>
//                                 <span>Created At</span>
//                                 <input type="text" name="createdat" value={editData.createdat} onChange={handleEditChange} disabled />
//                             </label>
//                             <label>
//                                 <span>Modified By</span>
//                                 <input type="text" name="modifiedby" value={editData.modifiedby} onChange={handleEditChange} disabled />
//                             </label>
//                             <label>
//                                 <span>Modified At</span>
//                                 <input type="text" name="modifiedat" value={editData.modifiedat} onChange={handleEditChange} disabled />
//                             </label>
//                             <button type="submit">Update</button>
//                             <button type="button" onClick={() => setEditData(null)}>Cancel</button>
//                         </form>
//                     </div>
//                 </Popup>
//             )}
//         </div>
//     );
// };

// export default DesignationTable;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Designationfetch.css';
import { Designationheader } from '../../Operator/Data';
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
import { Link } from 'react-router-dom';
const DesignationTable = () => {
    const [designations, setDesignations] = useState([]);
    const [editData, setEditData] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);

    useEffect(() => {
        fetchDesignations();
        // Fetch logged-in user information from local storage or context
        const user = JSON.parse(localStorage.getItem('user'));
        setLoggedInUser(user);
    }, []);

    const fetchDesignations = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
            setDesignations(response.data);
        } catch (error) {
            console.error('Error fetching designations:', error);
        }
    };

    const handleEditClick = (designation) => {
        setEditData(designation);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const updatedDesignationData = {
            ...editData,
            modifiedby: loggedInUser.username,
        };

        try {
            console.log('Submitting Edit Data:', updatedDesignationData);
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/designations/${editData._id}`, updatedDesignationData);
            console.log('Response:', response.data);
            fetchDesignations();
            setEditData(null);
            alert("Designation updated successfully");
        } catch (error) {
            console.error('Error updating designation:', error.response ? error.response.data : error.message);
            if (error.response && error.response.data.message === 'Designation name must be unique') {
                alert('Designation name already exists. Please choose a different name.');
            } else {
                alert('Failed to update designation. Please try again later.');
            }
        }
    };

    const handleToggleStatus = async (id, currentStatus) => {
        try {
            const editData = {
                status: !currentStatus,
                modifiedby: loggedInUser.username,
            };
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/designations/${id}/toggle-status`, editData);
            fetchDesignations();
        } catch (error) {
            console.error('Error toggling status:', error.response ? error.response.data : error.message);
        }
    };

    return (

        <div id='designationfecth-total'>
            <div>
                <Superadminsidebar />
            </div>
            <div id='designationfecth-right'>
                <div id='designationfecth-right-1'>
                    <div id='designationfecth-heading'>
                        {Designationheader.alertHeading}
                    </div>
                    <div id='designationfecth-heading1'>
                        {Designationheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Designationheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='designationfecth-right-2'>
                    <div id='designationfecth-right-21'>
                        <label>
                            <Link to='/Designation'><button>Form</button></Link>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Designation ID</th>
                                <th>Designation Level</th>
                                <th>Designation Name</th>
                                <th>Status</th>
                                <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {designations.map((designation) => (
                                <tr key={designation._id}>
                                    <td>{designation.designation_id}</td>
                                    <td>{designation.level}</td>
                                    <td>{designation.designation}</td>
                                    <td>
                                        <button onClick={() => handleToggleStatus(designation._id, designation.status)}>
                                            {designation.status ? 'Active' : 'Inactive'}
                                        </button>
                                    </td>
                                    <td>{designation.createdby}</td>
                                    <td>{designation.createdat}</td>
                                    <td>{designation.modifiedby}</td>
                                    <td>{designation.modifiedat}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(designation)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {editData && (
                        <Popup open={true} onClose={() => setEditData(null)}>
                            <div id='designationfecth-popup'>
                                <h3>Edit Designation</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='designationfecth-popup1'>
                                        <label>
                                            <span>Designation ID</span>
                                            <input type="text" name="designation_id" value={editData.designation_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Level</span>
                                            <select
                                                name="level"
                                                value={editData.level}
                                                onChange={handleEditChange}
                                                required
                                            >
                                                <option value=''>Select Level</option>
                                                <option value='Level-1'>Level-1</option>
                                                <option value='Level-2'>Level-2</option>
                                                <option value='Level-3'>Level-3</option>
                                                <option value='Level-4'>Level-4</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div id='designationfecth-popup1'>
                                        <label>
                                            <span>Designation Name</span>
                                            <input type="text" name="designation" value={editData.designation} onChange={handleEditChange} required />
                                        </label>
                                    </div>

                                    <div id='designationfecth-popup1'>


                                    </div>
                                    <div id='designationfecth-popup1'> </div>


                                    <button id='designationfecth-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>


        // <div>
        //     <h2>Designations</h2>
        //     <table>
        //         <thead>
        //             <tr>
        //                 <th>Designation ID</th>
        //                 <th>Designation Level</th>
        //                 <th>Designation Name</th>
        //                 <th>Status</th>
        //                 <th>Created By</th>
        //                 <th>Created At</th>
        //                 <th>Modified By</th>
        //                 <th>Modified At</th>
        //                 <th>Actions</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {designations.map((designation) => (
        //                 <tr key={designation._id}>
        //                     <td>{designation.designation_id}</td>
        //                     <td>{designation.level}</td>
        //                     <td>{designation.designation}</td>
        //                     <td>
        //                         <button onClick={() => handleToggleStatus(designation._id, designation.status)}>
        //                             {designation.status ? 'Active' : 'Inactive'}
        //                         </button>
        //                     </td>
        //                     <td>{designation.createdby}</td>
        //                     <td>{designation.createdat}</td>
        //                     <td>{designation.modifiedby}</td>
        //                     <td>{designation.modifiedat}</td>
        //                     <td>
        //                         <button onClick={() => handleEditClick(designation)}>Edit</button>
        //                     </td>
        //                 </tr>
        //             ))}
        //         </tbody>
        //     </table>

        //     {editData && (
        //         <Popup open={true} onClose={() => setEditData(null)}>
        //             <div>
        //                 <h3>Edit Designation</h3>
        //                 <form onSubmit={handleEditSubmit}>
        //                     <label>
        //                         <span>Designation ID</span>
        //                         <input type="text" name="designation_id" value={editData.designation_id} onChange={handleEditChange} disabled />
        //                     </label>
        //                     <label>
        //                         <span>Level</span>
        //                         <select
        //                             name="level"
        //                             value={editData.level}
        //                             onChange={handleEditChange}
        //                             required
        //                         >
        //                             <option value=''>Select Level</option>
        //                             <option value='Level-1'>Level-1</option>
        //                             <option value='Level-2'>Level-2</option>
        //                             <option value='Level-3'>Level-3</option>
        //                             <option value='Level-4'>Level-4</option>
        //                         </select>
        //                     </label>
        //                     <label>
        //                         <span>Designation Name</span>
        //                         <input type="text" name="designation" value={editData.designation} onChange={handleEditChange} required />
        //                     </label>
        //                     <label>
        //                         <span>Created By</span>
        //                         <input type="text" name="createdby" value={editData.createdby} onChange={handleEditChange} disabled />
        //                     </label>
        //                     <label>
        //                         <span>Created At</span>
        //                         <input type="text" name="createdat" value={editData.createdat} onChange={handleEditChange} disabled />
        //                     </label>
        //                     <label>
        //                         <span>Modified By</span>
        //                         <input type="text" name="modifiedby" value={editData.modifiedby} onChange={handleEditChange} disabled />
        //                     </label>
        //                     <label>
        //                         <span>Modified At</span>
        //                         <input type="text" name="modifiedat" value={editData.modifiedat} onChange={handleEditChange} disabled />
        //                     </label>
        //                     <button type="submit">Update</button>
        //                     <button type="button" onClick={() => setEditData(null)}>Cancel</button>
        //                 </form>
        //             </div>
        //         </Popup>
        //     )}
        // </div>
    );
};

export default DesignationTable;

