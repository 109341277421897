// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
// import Adminsidebar from '../../Adminsidebar/Adminsidebar';

// const Usercreation = () => {
//   const [formData, setFormData] = useState({
//     designation: '',
//     name: '',
//     username: '',
//     password: '',
//     confirmpassword: '',
//     createdby: '',
//     modifiedby: ''
//   });

//   const [errors, setErrors] = useState({});
//   const [designations, setDesignations] = useState([]);
//   const [userDesignation, setUserDesignation] = useState('');

//   useEffect(() => {
//     fetchDesignations();
//     const user = JSON.parse(localStorage.getItem('user'));
//     if (user && user.username) {
//       setFormData((prevData) => ({
//         ...prevData,
//         createdby: user.username
//       }));
//       setUserDesignation(user.designation);
//     }
//   }, []);

//   const fetchDesignations = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
//       const filteredDesignations = response.data.filter(designation => (
//         designation.designation === 'Admin' ||
//         designation.designation === 'Supervisor' ||
//         designation.designation === 'Operator'
//       ));
//       setDesignations(filteredDesignations);
//     } catch (error) {
//       console.error('Error fetching designations:', error);
//     }
//   };

//   const { designation, name, username, password, confirmpassword } = formData;

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     let errorMessages = { ...errors };

//     if (name === 'username') {
//       const usernameRegex = /^[a-z0-9]+$/;
//       if (!usernameRegex.test(value)) {
//         errorMessages.username = 'Username should only contain lowercase letters and numbers.';
//       } else {
//         delete errorMessages.username;
//       }
//     }

//     if (name === 'password' || name === 'confirmpassword') {
//       if (name === 'password' && value === username) {
//         errorMessages.password = 'Password should not be the same as Username.';
//       } else {
//         delete errorMessages.password;
//       }

//       if (name === 'confirmpassword' && value !== password) {
//         errorMessages.confirmpassword = 'Passwords do not match.';
//       } else {
//         delete errorMessages.confirmpassword;
//       }
//     }

//     setErrors(errorMessages);
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (Object.keys(errors).length > 0) {
//       alert('Please fix the errors in the form.');
//       return;
//     }

//     const postData = {
//       designation,
//       name,
//       username,
//       password,
//       confirmpassword,
//       createdby: formData.createdby,
//       modifiedby: formData.modifiedby
//     };

//     try {
//       await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/users`, postData);
//       alert('User created successfully');
//       setFormData({
//         designation: '',
//         name: '',
//         username: '',
//         password: '',
//         confirmpassword: '',
//         createdby: '',
//         modifiedby: ''
//       });
//     } catch (error) {
//       alert('Error creating user:', error.message);
//     }
//   };

//   return (
//     <div>
//       {userDesignation === 'Superadmin' ? <Superadminsidebar /> : <Adminsidebar />}
//       <div>
//         <h2>Create User</h2>
//         <form onSubmit={handleSubmit}>
//           <div>
//             <label>Designation</label>
//             <select name="designation" value={designation} onChange={handleChange} required>
//               <option value="">Select Designation</option>
//               {designations.map((desig) => (
//                 <option key={desig._id} value={desig.designation}>
//                   {desig.designation}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div>
//             <label>Name</label>
//             <input type="text" name="name" value={name} onChange={handleChange} required />
//           </div>
//           <div>
//             <label>Username</label>
//             <input type="text" name="username" value={username} onChange={handleChange} required />
//             {errors.username && <p style={{ color: 'red' }}>{errors.username}</p>}
//           </div>
//           <div>
//             <label>Password</label>
//             <input type="password" name="password" value={password} onChange={handleChange} required />
//             {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
//           </div>
//           <div>
//             <label>Confirm Password</label>
//             <input type="password" name="confirmpassword" value={confirmpassword} onChange={handleChange} required />
//             {errors.confirmpassword && <p style={{ color: 'red' }}>{errors.confirmpassword}</p>}
//           </div>
//           <div>
//             <button type="submit">Create User</button>
//             <Link to='/Userfetch'>Userfetch</Link>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Usercreation;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
import Adminsidebar from '../../Adminsidebar/Adminsidebar';
import './Usercreation.css'
import { Userheader } from '../../Operator/Data';
const Usercreation = () => {
  const [formData, setFormData] = useState({
    designation: '',
    level: '',
    name: '',
    username: '',
    password: '',
    confirmpassword: '',
    createdby: '',
    modifiedby: ''
  });

  const [errors, setErrors] = useState({});
  const [designations, setDesignations] = useState([]);
  const [userDesignation, setUserDesignation] = useState('');

  useEffect(() => {
    fetchDesignations();
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setFormData((prevData) => ({
        ...prevData,
        createdby: user.username
      }));
      setUserDesignation(user.level);
    }
  }, []);

  const fetchDesignations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
      const filteredDesignations = response.data.filter(designation => (
        designation.level !== 'Level-1'
      ));
      setDesignations(filteredDesignations);
    } catch (error) {
      console.error('Error fetching designations:', error);
    }
  };

  const { designation, level, name, username, password, confirmpassword } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessages = { ...errors };

    if (name === 'username') {
      const usernameRegex = /^[a-z0-9]+$/;
      if (!usernameRegex.test(value)) {
        errorMessages.username = 'Username should only contain lowercase letters and numbers.';
      } else {
        delete errorMessages.username;
      }
    }

    if (name === 'password' || name === 'confirmpassword') {
      if (name === 'password' && value === username) {
        errorMessages.password = 'Password should not be the same as Username.';
      } else {
        delete errorMessages.password;
      }

      if (name === 'confirmpassword' && value !== password) {
        errorMessages.confirmpassword = 'Passwords do not match.';
      } else {
        delete errorMessages.confirmpassword;
      }
    }

    setErrors(errorMessages);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDesignationChange = (e) => {
    const selectedDesignation = designations.find(desig => desig.designation === e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      designation: e.target.value,
      level: selectedDesignation ? selectedDesignation.level : ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.keys(errors).length > 0) {
      alert('Please fix the errors in the form.');
      return;
    }

    const postData = {
      ...formData
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/users`, postData);
      alert('User created successfully');
      setFormData({
        designation: '',
        level: '',
        name: '',
        username: '',
        password: '',
        confirmpassword: '',
        createdby: '',
        modifiedby: ''
      });
    } catch (error) {
      alert('Error creating user:', error.message);
    }
  };

  return (


    <div id='usercreation-total'>
      <div>
        {userDesignation === 'Level-1' ? <Superadminsidebar /> : <Adminsidebar />}
      </div>
      <div id='usercreation-right'>
        <div id='usercreation-right-1'>
          <div id='usercreation-heading'>
            {Userheader.alertHeading}
          </div>
          <div id='usercreation-heading1'>
            {Userheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Userheader.logoSrc} alt='logo' />
          </div>
        </div>
        <div id='usercreation-right-2'>
          <form onSubmit={handleSubmit}>
            <div id='usercreation-divtop'>
              <label>
                <span>User Name :</span>
                <span>#123456</span>
              </label>
              <label>
                <Link to='/Userfetch'><button type="button">View Data</button></Link>
              </label>
            </div>
            <div id='usercreation-div1'>
              <label>
                <span>Designation</span>
                <select name="designation" value={designation} onChange={handleDesignationChange} required>
                  <option value="">Select Designation</option>
                  {designations.map((desig) => (
                    <option key={desig._id} value={desig.designation}>
                      {desig.designation}
                    </option>
                  ))}
                </select>
              </label>

              {/* <label>
                <span>Designation</span>
                <input
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={handleChange}
                  required
                  disabled
                />
              </label> */}
            </div>
            <div id='usercreation-div1'>
              <label>
                <span>Name</span>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                <span>Username</span>
                <input
                  type="text"
                  name="username"
                  value={username}
                  onChange={handleChange}
                  required
                />
                {errors.username && <div style={{ color: 'red' }}>{errors.username}</div>}
              </label>
            </div>
            <div id='usercreation-div1'>
              <label>
                <span>Password</span>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  required
                />
                {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
              </label>
              <label>
                <span>Confirm Password</span>
                <input
                  type="password"
                  name="confirmpassword"
                  value={confirmpassword}
                  onChange={handleChange}
                  required
                />
                {errors.confirmpassword && <div style={{ color: 'red' }}>{errors.confirmpassword}</div>}
              </label>
            </div>
            <div id='usercreation-div1'>

            </div>
            <button id='usercreation-save' type="submit">Add User</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Usercreation;

