
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OperatorAlertstable from "./Components/Operator/AlertsTable/Alertstable";
import LiveData from "./Components/Operator/Livedata/Livedata";
import Historydata from "./Components/Operator/HistoryData/Historydata";
import LiveAlerts from "./Components/Operator/LiveAlerts/LiveAlerts";
import Statussummaryview from "./Components/Operator/StatusSummary/Statussummaryview";
import Sectionpoints from "./Components/Operator/Sectionpointview/Sectionpoints";
import TODview from "./Components/Operator/TODview/TODview";
import Breakdownlogs from "./Components/Operator/OperatorForms/Breakdownlogs/Breakdownlogs";
import Transformermonitoring from "./Components/Operator/OperatorForms/Tranformermonitoringlogs/Transformermonitoring";
import Transformermonitoringfetch from "./Components/Operator/OperatorForms/Tranformermonitoringlogs/Transformermonitoringfetch";
import Breakdownlogsfetch from "./Components/Operator/OperatorForms/Breakdownlogs/Breakdownlogsfetch";
import DGlogs from "./Components/Operator/OperatorForms/DGlogs/DGlogs";
import DGlogsfetch from "./Components/Operator/OperatorForms/DGlogs/DGlogsfetch";
import DailyConsumption from "./Components/Operator/DailyConsumption/DailyConsumption";
import Tranformerform from "./Components/Masterdata/Tranformerform/Tranformerform";
import Transformerformfetch from "./Components/Masterdata/Tranformerform/Tranformerformfetch";
import Areaform from "./Components/Masterdata/Areaform/Areafrom";
import Areaformfetch from "./Components/Masterdata/Areaform/Areaformfetch";
import Calendar from "./Components/Calender/Calender";
import TODviewcomponent from "./Components/Operator/TODview/SavedTOD";
import LiveSummary from "./Components/Livesummary/Livesummary";
import PowerBills from "./Components/Supervisor/PowerBills/PowerBills";
import Productionentry from "./Components/Supervisor/Productionentry/Productionentry";
import CriticalAlerts from "./Components/CriticalAlerts/CriticalAlerts";
import EnergyConsumption from "./Components/EnergyConsumption&Billing/EnergyConsumption";
import PF_MD from "./Components/PF&MD/PF&MD";
import MonthlyCalenderevents from "./Components/Calender/MonthlyCalenderevents";
import Monthly_PF from "./Components/PF&MD/Monthly_PF";
import PowerBillsfetch from "./Components/Supervisor/PowerBills/PowerBillsfetch";
import Operator_Dash from "./Components/Operator/Home/Home";
import Revenueloss from "./Components/Revenuelosspage/Revenueloss";
import Energyintensity from "./Components/Energyintensity/Energyintensity";
import Incomers from "./Components/MasterForms/Incomers/Incomers";
import Utilization_Percenatage from "./Components/Utilization%/Utilization%";
import Alertscount from "./Components/Trends/Alerts/Alertscount";
import Downtime_Uptime from "./Components/Downtime_Uptime/Downtime_Uptime";
import Transformerhealth from "./Components/Transformerloss/Transformerloss";
import Groundingpoints from "./Components/MasterForms/Groundingpoints/Groundingpoints";
import GroundingpointsFetch from "./Components/MasterForms/Groundingpoints/Groundingpointsfetch";
import Incomersfetch from "./Components/MasterForms/Incomers/Incomersfetch";
import HVform from "./Components/MasterForms/HVform/HVform";
import HvformFetch from "./Components/MasterForms/HVform/HVformfetch";
import Parentdetails from "./Components/MasterForms/Parentdetails/Parentdetails";
import Mastertransformerform from "./Components/MasterForms/MasterTransformer/Mastertransformer";
import Mastertransformerfetch from "./Components/MasterForms/MasterTransformer/Mastertransformerfetch";
import LVform from "./Components/MasterForms/LVform/LVform";
import LVformFetch from "./Components/MasterForms/LVform/LVformfetch";
import Mastergenerator from "./Components/MasterForms/Generators/Generators";
import Mastergeneratorfetch from "./Components/MasterForms/Generators/Generstorsfetch";
import Sections from "./Components/MasterForms/Sections/Sections";
import Sectionsfetch from "./Components/MasterForms/Sections/Sectionsfetch";
import Mastermachine from "./Components/MasterForms/Machine/Machine";
import Mastermachinefetch from "./Components/MasterForms/Machine/Machinefetch";
import UPS from "./Components/MasterForms/UPS/UPS";
import UPSfetch from "./Components/MasterForms/UPS/UPSfetch";
import Stebilizer from "./Components/MasterForms/Stabilizers/Stebilizers";
import Stebilizersfetch from "./Components/MasterForms/Stabilizers/Stebilizersfetch";
import PFcorrectors from "./Components/MasterForms/PFcorrectors/PFcorrectors";
import PFcorrectorsfetch from "./Components/MasterForms/PFcorrectors/PFcorrectorsfetch";
import Harmonicfilter from "./Components/MasterForms/Harmonic_filters/Harmonic_filters";
import Harmonicfiltersfetch from "./Components/MasterForms/Harmonic_filters/Harmonic_filtersfetch";
import VFD from "./Components/MasterForms/VFD/VFD";
import VFDfetch from "./Components/MasterForms/VFD/VFDfetch";
import LightingArrestor from "./Components/MasterForms/LighteningArrestors/LighteningArrestors";
import LightingArrestorfetch from "./Components/MasterForms/LighteningArrestors/LighteningArrestorsfetch";
import Tariff from "./Components/MasterForms/Tariff/Tariff";
import Alertsduration from "./Components/MasterForms/Alertsduration/Alertsduration";
import Alertsdurationfetch from "./Components/MasterForms/Alertsduration/Alertsdurationfetch";
import Diagnose from "./Components/Diagnose/Diagnosepage/Diagnosepage";
import Voltageregulator from "./Components/Diagnose/Voltagediagnose/Voltageregulator/Voltageregulator";
import Stabilizerform from "./Components/Diagnose/Voltagediagnose/Stabilizerform/Stabilizerform";
import Stabilizerformfetch from "./Components/Diagnose/Voltagediagnose/Stabilizerform/Stabilizerformfetch";
import Voltageregulatorfetch from "./Components/Diagnose/Voltagediagnose/Voltageregulator/Voltageregulatorfetch";
import Groundingresistance from "./Components/Diagnose/Voltagediagnose/Groundingresistance/Groundingresistance";
import Groundingresistancefetch from "./Components/Diagnose/Voltagediagnose/Groundingresistance/Groundingresistancefetch";
import EnvironmentalFactors from "./Components/Diagnose/Voltagediagnose/EnvironmentalFactors/EnvironmentalFactors";
import EnvironmentalFactorsfetch from "./Components/Diagnose/Voltagediagnose/EnvironmentalFactors/EnvironmentalFactorsfetch";
import Powersurge_Groundingresistance from "./Components/Diagnose/Powersurge/Groundingresistance/Groundingresistance";
import Powersurge_Groundingresistancefetch from "./Components/Diagnose/Powersurge/Groundingresistance/Groundingresistancefetch";
import Internal_Fault from "./Components/Diagnose/Powersurge/Internal_Fault/Internal_Fault";
import InternalFaultfetch from "./Components/Diagnose/Powersurge/Internal_Fault/internal_Faultfetch";
import Harmonic_Form from "./Components/Diagnose/Harmonicdistortion/Harmonic_Form/Harmonic_Form";
import Harmonic_Formfetch from "./Components/Diagnose/Harmonicdistortion/Harmonic_Form/Harmonic_Formfetch";
import Power_Quality_Form from "./Components/Diagnose/Harmonicdistortion/Power_Quality_Form/Power_Quality_Form";
import Power_Quality_Formfetch from "./Components/Diagnose/Harmonicdistortion/Power_Quality_Form/Power_Quality_Formfetch";
import Oil_Winding_Temperature_Form from "./Components/Diagnose/Unbalancedloads/Oil_Winding_Temperature_Form/Oil_Winding_Temperature_Form";
import Oil_Winding_Temperature_Formfetch from "./Components/Diagnose/Unbalancedloads/Oil_Winding_Temperature_Form/Oil_Winding_Temperature_Formfetch";
import Hotspot_Test_Form from "./Components/Diagnose/Unbalancedloads/Hotspot_Test_Form/Hotspot_Test_Form";
import Hotspot_Test_Formfetch from "./Components/Diagnose/Unbalancedloads/Hotspot_Test_Form/Hotspot_Test_Formfetch";
import Impedence_Test_Formfetch from "./Components/Diagnose/Unbalancedloads/Impedence_Test_Form/Impedence_Test_Formfetch";
import Impedance_Test_Form from "./Components/Diagnose/Unbalancedloads/Impedence_Test_Form/Impedence_Test_Form";
import Loose_Connection_and_Corrosion_Form from "./Components/Diagnose/Unbalancedloads/Loose_Connection_and_Corrosion_Form/Loose_Connection_and_Corrosion_Form";
import Loose_Connection_and_Corrosion_Formfetch from "./Components/Diagnose/Unbalancedloads/Loose_Connection_and_Corrosion_Form/Loose_Connection_and_Corrosion_Formfetch";
import APFC_Signalling_Manual_Form from "./Components/Diagnose/LeadPF/APFC_Signalling_Manual_Form/APFC_Signalling_Manual_Form";
import APFC_Signalling_Manual_Formfetch from "./Components/Diagnose/LeadPF/APFC_Signalling_Manual_Form/APFC_Signalling_Manual_Formfetch";
import Capacitance_Manual_Form from "./Components/Diagnose/LowPF/Capacitance_Manual_Form/Capacitance_Manual_Form";
import Capacitance_Manual_Formfetch from "./Components/Diagnose/LowPF/Capacitance_Manual_Form/Capacitance_Manual_Formfetch";
import Pending_Tasks from "./Components/Diagnose/Pendingtasks/Pending_Tasks";
import Designation from "./Components/UserManagement/Designation/Designation";
import DesignationTable from "./Components/UserManagement/Designation/Designationfetch";
import SuperadminRegistration from "./Components/UserManagement/Superadmin/SuperadminRegistration";
import SuperadminTable from "./Components/UserManagement/Superadmin/SuperadminRegistrationfetch";
import LoginPage from "./Components/UserManagement/Login/Login";
import { UserProvider } from "./Components/UserManagement/Details/UserContext";
import UserdetailsHeader from "./Components/UserManagement/Details/Userdetailshead";
import ProtectedRoute from "./Components/UserManagement/Details/Protect";
import Usercreation from "./Components/UserManagement/Usercreation/Usercreation";
import Userfetch from "./Components/UserManagement/Usercreation/Usercreationfetch";
import Main_transformerform from "./Components/Maintenanceforms/Transformerform/Transformerform";
import Main_TransformerFormFetch from "./Components/Maintenanceforms/Transformerform/Transformerformfetch";

import Voltagediagnose from "./Components/RevenuelossDiagnose/Voltagediagnose/Voltagediagnose";
import Powersurgediagnose from "./Components/RevenuelossDiagnose/Powersurgediagnose/Powersurgediagnose";
import Harmonicdistortiondiagnose from "./Components/RevenuelossDiagnose/Harmonicdistortion/Harmonicdistortion";
import Unbalancedloaddiagnose from "./Components/RevenuelossDiagnose/Unbalancedloads/Unbalancedloads";
import LowPFdiagnose from "./Components/RevenuelossDiagnose/LowPF/LowPF";
import LeadPFdiagnose from "./Components/RevenuelossDiagnose/LeadPF/LeadPF";
import Idle_Loss_Revenue_Diagnose from "./Components/RevenuelossDiagnose/Idle_Loss_Revenue_Diagnose/Idle_Loss_Revenue_Diagnose";
import TOD_loss_Revenue_Diagnose from "./Components/RevenuelossDiagnose/TOD_loss_Revenue_Diagnose/TOD_loss_Revenue_Diagnose";
import Utilization_Loss_Revenue_Diagnose from "./Components/RevenuelossDiagnose/Utilization_Loss_Revenue_Diagnose/Utilization_Loss_Revenue_Diagnose";
import Transformer_Loss_Revenue_Diagnose from "./Components/RevenuelossDiagnose/Transformer_Loss_Revenue_Diagnose/Transformer_Loss_Revenue_Diagnose";
import TOD_Run_Time_Week from "./Components/TOD_Run_Time/TOD_Run_Time_Week/TOD_Run_Time_Week";
import TOD_Run_Time_Month from "./Components/TOD_Run_Time/TOD_Run_Time/TOD_Run_Time";
import RCPE_Report from "./Components/RCPE_Report/RCPE_Report";
import Type_of_load_Consumption from "./Components/Type_of_load_Consumption/Type_of_load_Consumption";
import GeneratorsKPI from "./Components/GeneratorsKPI/GeneratorsKPI";
import Stationpoints from "./Components/Commonfns/Stationpoints";
import Tasks from "./Components/Tasks/Tasks";
import PendingTasks from "./Components/Tasks/PendingTasks";
import Alldash from "./Components/SuperDashboards/Alldash";
import ReportsAlertscount from "./Components/Reports/AlertsCount/ReportAlertcount";
import ReportDowntime_Uptime from "./Components/Reports/Downtimereport/ReportDowntime";
import ReportRCPE_Report from "./Components/Reports/RCPEreports/ReportsRCPE";
import AllReports from "./Components/Reports/Allreports/Allreports";
import Doitlater from "./Components/Tasks/Doitlater/Doitlater";
import Todotasks from "./Components/Tasks/Todotasks/Todotasks";
import TotalTaskcounts from "./Components/Diagnose/Pendingtasks/TaksCounts/TotalTaskcounts";
import Formheadercomponent from "./Components/Formscomponent/Formheadercompo/Formheadercomponent";
import Calenderscombine from "./Components/Calender/Calenderscombine/Calenderscombine";
import Loginscreens from "./Components/UserManagement/Loginscreens/Loginscreens";
import DailyConsumptionprint from "./Components/Operator/DailyConsumption/Dailyconsumptionprint";
import Alertspage from "./Components/Operator/Alertspage/Alertspage";
import AllTOD from "./Components/Operator/TODview/AllTOD";
import Formscomponent from "./Components/Operator/OperatorForms/Formscomponent/Formscomponent";
import ToastConfig from "./ToastifyContainer";
import AllTrends from "./Components/Trends/Alltrends";
import Fixedvariables from "./Components/MasterForms/Fixedvariables/Fixedvariables";

function App() {
  return (
    <div >
      <UserProvider>
        <Router>
          {/* <UserdetailsHeader /> */}
          <Routes>
            <Route path="/Historical-Alerts" element={<OperatorAlertstable />} />
            <Route path="/Livedata" element={<LiveData />} />
            <Route path="/Historydata" element={<Historydata />} />
            <Route path="/Alertspage" element={<Alertspage />} />
            <Route path="/Live-Alerts" element={<LiveAlerts />} />
            <Route path="/Statussummary" element={<Statussummaryview />} />
            <Route path="/Section-Wise-View" element={<Sectionpoints />} />
            <Route path="/TODview" element={<TODview />} />
            <Route path="/Breakdownlogs" element={<Breakdownlogs />} />
            <Route path="/Breakdownlogsfetch" element={<Breakdownlogsfetch />} />
            <Route path="/Transformermonitoring" element={<Transformermonitoring />} />
            <Route path="/Transformermonitoringfetch" element={<Transformermonitoringfetch />} />
            <Route path="/DGlogs" element={<DGlogs />} />
            <Route path="/DGlogsfetch" element={<DGlogsfetch />} />
            <Route path="/DailyConsumption" element={<DailyConsumption />} />
            <Route path="/Tranformerform" element={<Tranformerform />} />
            <Route path="/Transformerformfetch" element={<Transformerformfetch />} />
            <Route path="/Areaform" element={<Areaform />} />
            <Route path="/Areaformfetch" element={<Areaformfetch />} />
            <Route path="/Calendar" element={<Calendar />} />
            <Route path="/TODHistory" element={<TODviewcomponent />} />
            <Route path="/AllTOD" element={<AllTOD />} />
            <Route path="/LiveSummary" element={<LiveSummary />} />
            <Route path="/Forms" element={<Formscomponent />} />

            {/* ---------------Supervisor---------------- */}
            <Route path="/PowerBills" element={<PowerBills />} />
            <Route path="/PowerBillsfetch" element={<PowerBillsfetch />} />
            <Route path="/Productionentry" element={<Productionentry />} />
            <Route path="/EnergyConsumption" element={<EnergyConsumption />} />
            <Route path="/PF_MD" element={<PF_MD />} />
            <Route path="/MonthlyCalenderevents" element={<MonthlyCalenderevents />} />
            <Route path="/Monthly_PF" element={<Monthly_PF />} />
            <Route path="/Operator-Dash" element={<Operator_Dash />} />
            <Route path="/Revenueloss" element={<Revenueloss />} />

            <Route path="/Energyintensity" element={<Energyintensity />} />
            <Route path="/Utilization_Percenatage" element={<Utilization_Percenatage />} />

            {/* -----------Trends------------- */}
            <Route path="/AllTrends" element={<AllTrends />} />
            <Route path="/Alertscount" element={<Alertscount />} />
            <Route path="/Downtime_Uptime" element={<Downtime_Uptime />} />
            <Route path="/Transformerhealth" element={<Transformerhealth />} />
            <Route path="/TOD_Run_Time_Week" element={<TOD_Run_Time_Week />} />
            <Route path="/TOD_Run_Time_Month" element={<TOD_Run_Time_Month />} />
            <Route path="/RCPE_Report" element={<RCPE_Report />} />
            <Route path="/Type_of_load_Consumption" element={<Type_of_load_Consumption />} />
            <Route path="/GeneratorsKPI" element={<GeneratorsKPI />} />

            {/* ---------------------Master forms----------------------------- */}
            <Route path="/Incomers" element={<Incomers />} />
            <Route path="/Incomersfetch" element={<Incomersfetch />} />
            <Route path="/Groundingpoints" element={<Groundingpoints />} />
            <Route path="/GroundingpointsFetch" element={<GroundingpointsFetch />} />
            <Route path="/HVform" element={<HVform />} />
            <Route path="/HVformFetch" element={<HvformFetch />} />
            <Route path="/Parentdetails" element={<Parentdetails />} />
            <Route path="/Mastertransformerform" element={<Mastertransformerform />} />
            <Route path="/Mastertransformerfetch" element={<Mastertransformerfetch />} />
            <Route path="/LVform" element={<LVform />} />
            <Route path="/LVformFetch" element={<LVformFetch />} />
            <Route path="/Mastergenerator" element={<Mastergenerator />} />
            <Route path="/Mastergeneratorfetch" element={<Mastergeneratorfetch />} />
            <Route path="/Sections" element={<Sections />} />
            <Route path="/Sectionsfetch" element={<Sectionsfetch />} />
            <Route path="/Mastermachine" element={<Mastermachine />} />
            <Route path="/Mastermachinefetch" element={<Mastermachinefetch />} />
            <Route path="/UPS" element={<UPS />} />
            <Route path="/UPSfetch" element={<UPSfetch />} />
            <Route path="/Stebilizer" element={<Stebilizer />} />
            <Route path="/Stebilizersfetch" element={<Stebilizersfetch />} />
            <Route path="/PFcorrectors" element={<PFcorrectors />} />
            <Route path="/PFcorrectorsfetch" element={<PFcorrectorsfetch />} />
            <Route path="/Harmonicfilter" element={<Harmonicfilter />} />
            <Route path="/Harmonicfiltersfetch" element={<Harmonicfiltersfetch />} />
            <Route path="/VFD" element={<VFD />} />
            <Route path="/VFDfetch" element={<VFDfetch />} />
            <Route path="/LightingArrestor" element={<LightingArrestor />} />
            <Route path="/LightingArrestorfetch" element={<LightingArrestorfetch />} />
            <Route path="/Tariff" element={<Tariff />} />
            <Route path="/Alertsduration" element={<Alertsduration />} />
            <Route path="/Alertsdurationfetch" element={<Alertsdurationfetch />} />
            <Route path="/Fixedvariables" element={<Fixedvariables />} />

            {/* ------------------Supervisor-------------------------- */}
            <Route path="/CriticalAlerts" element={<CriticalAlerts />} />
            {/* <Route  path="/Voltagediagnose" element={<Voltagediagnose />} /> */}
            <Route path="/Diagnose/:token_id" element={<Diagnose />} />



            <Route path="/Voltageregulator/:token_id" element={<Voltageregulator />} />
            <Route path="/Voltageregulatorfetch" element={<Voltageregulatorfetch />} />
            <Route path="/Stabilizerform" element={<Stabilizerform />} />
            <Route path="/Stabilizerformfetch" element={<Stabilizerformfetch />} />
            <Route path="/VoltageGroundingresistance/:token_id" element={<Groundingresistance />} />
            <Route path="/VoltageGroundingresistancefetch" element={<Groundingresistancefetch />} />
            <Route path="/EnvironmentalFactors/:token_id" element={<EnvironmentalFactors />} />
            <Route path="/EnvironmentalFactorsfetch" element={<EnvironmentalFactorsfetch />} />
            {/* ------------- */}
            <Route path="/Powersurge_Groundingresistance/:token_id" element={<Powersurge_Groundingresistance />} />
            <Route path="/Powersurge_Groundingresistancefetch" element={<Powersurge_Groundingresistancefetch />} />
            <Route path="/Internal_Fault/:token_id" element={<Internal_Fault />} />
            <Route path="/Internal_Faultfetch" element={<InternalFaultfetch />} />
            {/* ------------- */}
            <Route path="/Harmonic_Form/:token_id" element={<Harmonic_Form />} />
            <Route path="/Harmonic_Formfetch" element={<Harmonic_Formfetch />} />
            <Route path="/Power_Quality_Form/:token_id" element={<Power_Quality_Form />} />
            <Route path="/Power_Quality_Formfetch" element={<Power_Quality_Formfetch />} />
            {/* ------------ */}
            <Route path="/Oil_Winding_Temperature_Form/:token_id" element={<Oil_Winding_Temperature_Form />} />
            <Route path="/Oil_Winding_Temperature_Formfetch" element={<Oil_Winding_Temperature_Formfetch />} />
            <Route path="/Impedance_Test_Form/:token_id" element={<Impedance_Test_Form />} />
            <Route path="/Impedence_Test_Formfetch" element={<Impedence_Test_Formfetch />} />
            <Route path="/Loose_Connection_and_Corrosion_Form/:token_id" element={<Loose_Connection_and_Corrosion_Form />} />
            <Route path="/Loose_Connection_and_Corrosion_Formfetch" element={<Loose_Connection_and_Corrosion_Formfetch />} />
            <Route path="/Hotspot_Test_Form/:token_id" element={<Hotspot_Test_Form />} />
            <Route path="/Hotspot_Test_Formfetch" element={<Hotspot_Test_Formfetch />} />
            {/* ----------- */}
            <Route path="/APFC_Signalling_Manual_Form/:token_id" element={<APFC_Signalling_Manual_Form />} />
            <Route path="/APFC_Signalling_Manual_Formfetch" element={<APFC_Signalling_Manual_Formfetch />} />
            {/* ------------ */}
            <Route path="/Capacitance_Manual_Form/:token_id" element={<Capacitance_Manual_Form />} />
            <Route path="/Capacitance_Manual_Formfetch" element={<Capacitance_Manual_Formfetch />} />

            <Route path="/Pending_Tasks" element={<Pending_Tasks />} />


            {/* --------------User management---------------- */}
            <Route path="/" element={<LoginPage />} />
            <Route path="/Loginscreens" element={<Loginscreens />} />

            <Route path="/Designation" element={<Designation />} />
            <Route path="/DesignationTable" element={<DesignationTable />} />
            <Route path="/SuperadminRegistration" element={<SuperadminRegistration />} />
            <Route path="/SuperadminTable" element={<SuperadminTable />} />
            <Route path="/Usercreation" element={<Usercreation />} />
            <Route path="/Userfetch" element={<Userfetch />} />

            {/* ---------------Maintenanceforms------------------------ */}

            <Route path="/Main_transformerform" element={<Main_transformerform />} />
            <Route path="/Main_TransformerFormFetch" element={<Main_TransformerFormFetch />} />


            {/* -------------------revenueloss Diagnose-------------- */}
            <Route path="/Voltagediagnose" element={<Voltagediagnose />} />
            <Route path="/Powersurgediagnose" element={<Powersurgediagnose />} />
            <Route path="/Harmonicdistortiondiagnose" element={<Harmonicdistortiondiagnose />} />
            <Route path="/Unbalancedloaddiagnose" element={<Unbalancedloaddiagnose />} />
            <Route path="/LowPFdiagnose" element={<LowPFdiagnose />} />
            <Route path="/LeadPFdiagnose" element={<LeadPFdiagnose />} />
            <Route path="/Idle_Loss_Revenue_Diagnose" element={<Idle_Loss_Revenue_Diagnose />} />
            <Route path="/TOD_loss_Revenue_Diagnose" element={<TOD_loss_Revenue_Diagnose />} />
            <Route path="/Utilization_Loss_Revenue_Diagnose" element={<Utilization_Loss_Revenue_Diagnose />} />
            <Route path="/Transformer_Loss_Revenue_Diagnose" element={<Transformer_Loss_Revenue_Diagnose />} />

            <Route path="/UserdetailsHeader" element={<UserdetailsHeader />} />

            <Route path="/Stationpoints" element={<Stationpoints />} />


            {/* ------------------------------ */}
            <Route path="/Alldash" element={<Alldash />} />


            {/* -------------Reports Table format---------- */}
            <Route path="/AllReports" element={<AllReports />} />

            <Route path="/ReportsAlertscount" element={<ReportsAlertscount />} />
            <Route path="/ReportDowntime_Uptime" element={<ReportDowntime_Uptime />} />
            <Route path="/ReportRCPE_Report" element={<ReportRCPE_Report />} />


            {/* -------------------- */}
            <Route path="/Tasks" element={<Tasks />} />
            <Route path="/PendingTasks" element={<PendingTasks />} />
            <Route path="/Doitlater" element={<Doitlater />} />
            <Route path="/Todotasks" element={<Todotasks />} />

            <Route path="/TotalTaskcounts" element={<TotalTaskcounts />} />
            <Route path="/Masterforms" element={<Formheadercomponent />} />


            <Route path="/Calenderscombine" element={<Calenderscombine />} />


            <Route path="/DailyConsumptionprint" element={<DailyConsumptionprint />} />
          </Routes>
        </Router>
        <ToastConfig />
      </UserProvider>
    </div>
  );
}

export default App;


// mongodb+srv://EMS:EMS@123@cluster0.csix44i.mongodb.net/?retryWrites=true&w=majority&appName=Cluster0


// mongodb+srv://EMS:<password>@cluster0.csix44i.mongodb.net/?retryWrites=true&w=majority&appName=Cluster0