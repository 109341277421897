// import React from 'react'
// import logo from '../../../Images/logo1.png'
// import './Loginscreens.css'
// import { Link } from 'react-router-dom'
// const Loginscreens = () => {
//     return (
//         <div id='loginscreen-totalcontainer'>
//             <div id='loginscreen-total'>
//                 <div className="loginscreenimage">
//                     <img src={logo} alt="" />
//                 </div>
//             </div>
//             <div>
//                 <div id='loginscreen-desigcard'>
//                     <div id='loginscreen-desiglongcard'>
//                         <label>Level-2</label>
//                         <Link id='loginscreen-link'>Login</Link>
//                     </div>
//                     <div id='loginscreen-desigcardflex'>
//                         <div id='loginscreen-desigcard1'>
//                             <label>Level-3</label>
//                             <Link id='loginscreen-link'>Login</Link>
//                         </div>
//                         <div id='loginscreen-desigcard1'>
//                             <label>Level-4</label>
//                             <Link id='loginscreen-link'>Login</Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Loginscreens


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import logo from '../../../Images/logo1.png';
// import './Loginscreens.css';
// import { Link } from 'react-router-dom';

// const Loginscreens = () => {
//     const [designations, setDesignations] = useState([]);

//     useEffect(() => {
//         const fetchDesignations = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
//                 setDesignations(response.data);
//             } catch (error) {
//                 console.error('Error fetching designations:', error);
//             }
//         };

//         fetchDesignations();
//     }, []);

//     return (
//         <div id='loginscreen-totalcontainer'>
//             <div id='loginscreen-total'>
//                 <div className="loginscreenimage">
//                     <img src={logo} alt="" />
//                 </div>
//             </div>
//             <div>
//                 <div id='loginscreen-desigcard'>
//                     {designations.length > 0 ? (
//                         <div>
//                             <div id='loginscreen-desiglongcard'>
//                                 <label>{designations[0].designation}</label>
//                                 <Link id='loginscreen-link' to={`/login/${designations[0]._id}`}>Login</Link>
//                             </div>
//                             <div id='loginscreen-desigcardflex'>
//                                 {designations.slice(1).map((designation, index) => (
//                                     <div key={index} id='loginscreen-desigcard1'>
//                                         <label>{designation.designation}</label>
//                                         <Link id='loginscreen-link' to={`/login/${designation._id}`}>Login</Link>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     ) : (
//                         <p>Loading designations...</p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Loginscreens;




// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import logo from '../../../Images/logo1.png';
// import './Loginscreens.css';
// import { Link } from 'react-router-dom';

// const Loginscreens = () => {
//     const [designations, setDesignations] = useState({
//         level2: null,
//         level3: null,
//         level4: null
//     });

//     useEffect(() => {
//         const fetchDesignations = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
//                 const designationsMap = response.data.reduce((acc, designation) => {
//                     if (designation.level === 'Level-2') acc.level2 = designation;
//                     if (designation.level === 'Level-3') acc.level3 = designation;
//                     if (designation.level === 'Level-4') acc.level4 = designation;
//                     return acc;
//                 }, {});

//                 setDesignations(designationsMap);
//             } catch (error) {
//                 console.error('Error fetching designations:', error);
//             }
//         };

//         fetchDesignations();
//     }, []);

//     return (
//         <div id='loginscreen-totalcontainer'>
//             <div id='loginscreen-total'>
//                 <div className="loginscreenimage">
//                     <img src={logo} alt="" />
//                 </div>
//             </div>
//             <div>
//                 <div id='loginscreen-desigcard'>
//                     <div id='loginscreen-desiglongcard'>
//                         <label>{designations.level2 ? designations.level2.designation : 'Level-2'}</label>
//                         <Link id='loginscreen-link' to='/login/level2'>Login</Link>
//                     </div>
//                     <div id='loginscreen-desigcardflex'>
//                         <div id='loginscreen-desigcard1'>
//                             <label>{designations.level3 ? designations.level3.designation : 'Level-3'}</label>
//                             <Link id='loginscreen-link' to='/login/level3'>Login</Link>
//                         </div>
//                         <div id='loginscreen-desigcard1'>
//                             <label>{designations.level4 ? designations.level4.designation : 'Level-4'}</label>
//                             <Link id='loginscreen-link' to='/login/level4'>Login</Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Loginscreens;




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../../../Images/logo1.svg';
import './Loginscreens.css';
import { Link, useNavigate } from 'react-router-dom';

const Loginscreens = () => {
    const [designations, setDesignations] = useState({
        level2: null,
        level3: null,
        level4: null
    });
    const [userLevel, setUserLevel] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDesignations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
                const designationsMap = response.data.reduce((acc, designation) => {
                    if (designation.level === 'Level-2') acc.level2 = designation;
                    if (designation.level === 'Level-3') acc.level3 = designation;
                    if (designation.level === 'Level-4') acc.level4 = designation;
                    return acc;
                }, {});

                setDesignations(designationsMap);
            } catch (error) {
                console.error('Error fetching designations:', error);
            }
        };

        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.level) {
            setUserLevel(user.level);
        }

        fetchDesignations();
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('accessedLevel');
        navigate('/');
    };

    const handleLevelAccess = (level) => {
        localStorage.setItem('accessedLevel', level);
    };

    return (
        <div id='loginscreen-totalcontainer'>
            <div id='loginscreen-total'>
                <div className="loginscreenimage">
                    <img src={logo} alt="" />
                </div>
            </div>
            <div>
                {userLevel ? (
                    <div id='loginscreen-desigcard'>
                        {(userLevel === 'Level-2') && (
                            <div id='loginscreen-desiglongcard'>
                                <label>{designations.level2 ? designations.level2.designation : 'Level-2'}</label>
                                <Link id='loginscreen-link' to='/Usercreation'
                                  onClick={() => handleLevelAccess('Level-2')}>Access</Link>
                            </div>
                        )}
                        {(userLevel === 'Level-2' || userLevel === 'Level-3') && (
                            <div id='loginscreen-desigcardflex'>
                                <div id='loginscreen-desigcard1'>
                                    <label>{designations.level3 ? designations.level3.designation : 'Level-3'}</label>
                                    <Link id='loginscreen-link' to='/Alldash'
                                      onClick={() => handleLevelAccess('Level-3')}>Access</Link>
                                </div>
                                <div id='loginscreen-desigcard1'>
                                    <label>{designations.level4 ? designations.level4.designation : 'Level-4'}</label>
                                    <Link id='loginscreen-link' to='/Operator-Dash'
                                      onClick={() => handleLevelAccess('Level-4')}>Access</Link>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div id='loginscreen-loginprompt'>
                        <center>Please login</center>
                    </div>
                )}
            </div>
            {userLevel && <center id='loginscreen-exittext' onClick={handleLogout}>Exit</center>}
        </div>
    );
};

export default Loginscreens;
