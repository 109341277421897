// // import React from 'react'

// // const Home = () => {
// //   return (
// //     <div>

// //     </div>
// //   )
// // }

// // export default Home

// import React, { useEffect, useState } from 'react';
// import { io } from 'socket.io-client';
// import { alertData4 } from '../Data';
// import Operatorsidebar from '../Sidebar/Sidebar';
// import './Home.css'
// const MAX_ALERTS = 5; 

// const Home = () => {
//   const [alerts, setAlerts] = useState([]);

//   useEffect(() => {
//     const socket = io('http://localhost:3001');

//     socket.on('mqtt_alert', (data) => {
//       console.log('Alert received:', data);
//       const alertKey = `${data.stationPoint}-${data.parameterName}-${data.eventDescription}`;

//       setAlerts((prevAlerts) => {
//         const newAlerts = prevAlerts.filter(alert => alert.key !== alertKey);
//         const updatedAlerts = [{ ...data, key: alertKey }, ...newAlerts].slice(0, MAX_ALERTS);
//         return updatedAlerts;
//       });
//     });

//     socket.on('connect_error', (err) => {
//       console.error('Connection Error:', err);
//     });

//     return () => {
//       socket.disconnect();
//     };
//   }, []);

//   return (
//     <div id='opehome-total'>
//       <div>
//         <Operatorsidebar />
//       </div>
//       <div id='opehome-right'>
//         <div id='opehome-right-1'>
//           <div id='opehome-heading'>
//             {alertData4.alertHeading}
//           </div>
//           <div id='opehome-heading1'>
//             {alertData4.alertActions.map((action, index) => (
//               <button key={index} aria-label={action.label}>
//                 {action.icon}
//               </button>
//             ))}
//             <img src={alertData4.logoSrc} alt='logo' />
//           </div>
//         </div>
//         <div id='opehome-right-2'>
//           {alerts.map((alert, index) => (
//             <div key={alert.key} className="alert-item">
//               <div className="alert-details">
//                 <p><strong>Section:</strong> {alert.stationPoint}</p>
//                 <p><strong>Parameter Name:</strong> {alert.parameterName}</p>
//                 <p><strong>Event Description:</strong> {alert.eventDescription}</p>
//                 <p><strong>Event Type:</strong> {alert.eventType}</p>
//                 <p><strong>Event Value:</strong> {alert.eventValue}</p>
//                 <p><strong>Date & Time:</strong> {alert.dateTime}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;

import React from 'react'
import Homealerts from './Homealerts'
import HomeLoad from './HomeLoad'
import './Home.css'
import { Operatordahboard } from '../Data';
import Operatorsidebar from '../Sidebar/Sidebar';
import DashPending_Task from '../../SuperDashboards/Tab1/DashPending_Task/DashPending_Task';
import TopBar from '../../Commonfns/Topbar/Topbar';
const Home = () => {
    return (
        <div id='opehome-total'>
            <div>
                <Operatorsidebar />
            </div>
            <div id='opehome-right'>
                <TopBar section="Operatordashheading" />

                <div id='opehome-right-2'>
                    <div>
                        <DashPending_Task />
                    </div>
                    <div id='opehome-right-22'>
                        <Homealerts />
                    </div>
                </div>
                <div>
                    <HomeLoad />
                </div>
            </div>
        </div>
    )
}

export default Home
