// import React, { useEffect, useState } from 'react';
// import { FaChevronDown } from "react-icons/fa";
// import { FaChevronUp } from "react-icons/fa";
// import DailyConsumptionprint from '../../Operator/DailyConsumption/Dailyconsumptionprint'
// import './Dailycomsptionreport.css'
// import Stationpoints from '../../Commonfns/Stationpoints';
// const Dailycomsptionreport = () => {
//     const [dailyconsumptionData, setDailyconsumptionData] = useState([]);
//     const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
//     const [showHVdata, setshowHVdata] = useState(false);
//     const [showLV2data, setShowLV2data] = useState(false);
//     const [showProductiondata, setShowProductiondata] = useState(false);
//     const [showDG1data, setShowDG1data] = useState(false);
//     const [showDG2data, setShowDG2data] = useState(false);
//     const [section, setSection] = useState('');

//     function getYesterdayDate() {
//         const today = new Date();
//         today.setDate(today.getDate() - 1); // Subtract one day
//         const year = today.getFullYear();
//         const month = String(today.getMonth() + 1).padStart(2, '0');
//         const day = String(today.getDate()).padStart(2, '0');
//         return `${year}-${month}-${day}`;
//     }

//     const fetchDailyConsumptionData = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/dailyconsumption`);
//             const data = await response.json();
//             if (Array.isArray(data)) {
//                 setDailyconsumptionData(data);
//             } else {
//                 console.error('Daily Consumption data is not an array:', data);
//             }
//         } catch (error) {
//             console.error('Error fetching Daily Consumption data:', error);
//         }
//     };

//     useEffect(() => {
//         fetchDailyConsumptionData();
//     }, [selectedDate]); // Update data when date changes

//     const handleDateChange = (e) => {
//         setSelectedDate(e.target.value);
//     };

//     const toggleshowHVdataData = () => {
//         setshowHVdata(!showHVdata);
//     };

//     const toggleLV2Data = () => {
//         setShowLV2data(!showLV2data);
//     };
//     const toggleProductionData = () => {
//         setShowProductiondata(!showProductiondata);
//     };
//     const toggleDG1Data = () => {
//         setShowDG1data(!showDG1data);
//     };
//     const toggleDG2Data = () => {
//         setShowDG2data(!showDG2data);
//     };

//     const handleStationPointChange = (selectedStationPoint) => {
//         setSection(selectedStationPoint);
//       };

//     return (
//         <div id='dailyconsumptionreport-right-2'>
//             <div id='dailyconsumptionreport-right-21'>
//             <label style={{ display: 'flex', alignItems: 'center' }}>
//                 <span>Section</span>
//               <Stationpoints onStationPointChange={handleStationPointChange} />
//             </label>
//                 <label>
//                     <span>Select Date</span>
//                     <input
//                         type="date"
//                         value={selectedDate}
//                         onChange={handleDateChange}
//                     />
//                 </label>
//                 <label>
//                     <DailyConsumptionprint />
//                 </label>
//             </div>
//             <div id='dailyconsumption-right-22'>
//                 <div id='dailyconsumption-right-221'>
//                     <div id='dailyconsumption-right-head'>Section</div>
//                     <div id='dailyconsumption-right-head1'>Date</div>
//                     <div id='dailyconsumption-right-head2'>kWh Consumption</div>
//                 </div>
//                 {dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'HV')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row'>
//                             <div id='dailyconsumption-right-data1'>{item.Section} <button id='dailyconsumption-dropbtn12' onClick={toggleshowHVdataData}>{showHVdata ? <FaChevronUp /> : <FaChevronDown />}</button></div>
//                             <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Induction Heater')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Compressor (11kw)')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Cut Bend')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Compressor (75kw)')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Compressor (30kw)')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Epoxy Coating Blower')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Epoxy Coating Booth Stabilizer')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Shot Blasting-1')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Shot Blasting-2')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Pump House')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Work Conveyor Panel')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Lighting Panel ')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Utility Panel')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}

//                 {dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'DG 62.5 KVA')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row'>
//                             <div id='dailyconsumption-right-data1'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {dailyconsumptionData
//                     .filter(item => item.Date === selectedDate && item.Section === 'Solar 734.66 KVA')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row'>
//                             <div id='dailyconsumption-right-data1'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//             </div>
//         </div>
//     )
// }

// export default Dailycomsptionreport



import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import DailyConsumptionprint from '../../Operator/DailyConsumption/Dailyconsumptionprint';
import './Dailycomsptionreport.css';
import Stationpoints from '../../Commonfns/Stationpoints';

const Dailycomsptionreport = () => {
    const [dailyconsumptionData, setDailyconsumptionData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
    const [showHVdata, setshowHVdata] = useState(false);
    const [showLV2data, setShowLV2data] = useState(false);
    const [showProductiondata, setShowProductiondata] = useState(false);
    const [showDG1data, setShowDG1data] = useState(false);
    const [showDG2data, setShowDG2data] = useState(false);
    const [section, setSection] = useState('');

    function getYesterdayDate() {
        const today = new Date();
        today.setDate(today.getDate() - 1); // Subtract one day
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const fetchDailyConsumptionData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/dailyconsumption`);
            const data = await response.json();
            if (Array.isArray(data)) {
                setDailyconsumptionData(data);
            } else {
                console.error('Daily Consumption data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching Daily Consumption data:', error);
        }
    };

    useEffect(() => {
        fetchDailyConsumptionData();
    }, [selectedDate]); // Update data when date changes

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const toggleshowHVdataData = () => {
        setshowHVdata(!showHVdata);
    };

    const toggleLV2Data = () => {
        setShowLV2data(!showLV2data);
    };
    const toggleProductionData = () => {
        setShowProductiondata(!showProductiondata);
    };
    const toggleDG1Data = () => {
        setShowDG1data(!showDG1data);
    };
    const toggleDG2Data = () => {
        setShowDG2data(!showDG2data);
    };

    const handleStationPointChange = (selectedStationPoint) => {
        setSection(selectedStationPoint);
    };

    const filteredData = dailyconsumptionData.filter(item => {
        return item.Date === selectedDate && (section === '' || item.Section === section || (section === 'HV' && showHVdata && item.Section !== 'HV'));
    });

    return (
        <div id='dailyconsumptionreport-right-2'>
            <div id='dailyconsumptionreport-right-21'>
                <label >
                    <Stationpoints onStationPointChange={handleStationPointChange} />
                </label>
                <label>
                    <span>Select Date:</span>
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                </label>
                <label>
                    <DailyConsumptionprint filteredData={filteredData} />
                </label>
            </div>
            <div id='dailyconsumption-right-22'>
                <div id='dailyconsumption-right-221'>
                    <div id='dailyconsumption-right-head'>Section</div>
                    <div id='dailyconsumption-right-head1'>Date</div>
                    <div id='dailyconsumption-right-head2'>kWh Consumption</div>
                </div>
                {filteredData
                    .filter(item => item.Section === 'HV')
                    .map((item, index) => (
                        <div key={index} className='dailyconsumption-data-row'>
                            <div id='dailyconsumption-right-data1'>{item.Section} <button id='dailyconsumption-dropbtn12' onClick={toggleshowHVdataData}>{showHVdata ? <FaChevronUp /> : <FaChevronDown />}</button></div>
                            <div id='dailyconsumption-right-data2'>{item.Date}</div>
                            <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
                        </div>
                    ))}
                {showHVdata && filteredData
                    .filter(item => item.Section === 'Induction Heater')
                    .map((item, index) => (
                        <div key={index} className='dailyconsumption-data-row1'>
                            <div id='dailyconsumption-right-data12'>{item.Section}</div>
                            <div id='dailyconsumption-right-data22'>{item.Date}</div>
                            <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                        </div>
                    ))}
                {showHVdata && filteredData
                    .filter(item => item.Section === 'Compressor (11kw)')
                    .map((item, index) => (
                        <div key={index} className='dailyconsumption-data-row1'>
                            <div id='dailyconsumption-right-data12'>{item.Section}</div>
                            <div id='dailyconsumption-right-data22'>{item.Date}</div>
                            <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                        </div>
                    ))}
                {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Cut Bend')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Compressor (75kw)')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Compressor (30kw)')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Epoxy Coating Blower')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Epoxy Coating Booth Stabilizer')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Shot Blasting-1')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Shot Blasting-2')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Pump House')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Work Conveyor Panel')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Lighting Panel ')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}
                        {showHVdata && dailyconsumptionData
                            .filter(item => item.Date === selectedDate && item.Section === 'Utility Panel')
                            .map((item, index) => (
                                <div key={index} className='dailyconsumption-data-row1'>
                                    <div id='dailyconsumption-right-data12'>{item.Section}</div>
                                    <div id='dailyconsumption-right-data22'>{item.Date}</div>
                                    <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
                                </div>
                            ))}

                
                {filteredData
                    .filter(item => item.Section === 'DG 62.5 KVA')
                    .map((item, index) => (
                        <div key={index} className='dailyconsumption-data-row'>
                            <div id='dailyconsumption-right-data1'>{item.Section}</div>
                            <div id='dailyconsumption-right-data2'>{item.Date}</div>
                            <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
                        </div>
                    ))}
                {filteredData
                    .filter(item => item.Section === 'Solar 734.66 KVA')
                    .map((item, index) => (
                        <div key={index} className='dailyconsumption-data-row'>
                            <div id='dailyconsumption-right-data1'>{item.Section}</div>
                            <div id='dailyconsumption-right-data2'>{item.Date}</div>
                            <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default Dailycomsptionreport;




// import React, { useEffect, useState } from 'react';
// import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import DailyConsumptionprint from '../../Operator/DailyConsumption/Dailyconsumptionprint';
// import './Dailycomsptionreport.css';
// import Stationpoints from '../../Commonfns/Stationpoints';

// const Dailycomsptionreport = () => {
//     const [dailyconsumptionData, setDailyconsumptionData] = useState([]);
//     const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
//     const [showHVdata, setshowHVdata] = useState(false);
//     const [section, setSection] = useState('');

//     function getYesterdayDate() {
//         const today = new Date();
//         today.setDate(today.getDate() - 1); // Subtract one day
//         const year = today.getFullYear();
//         const month = String(today.getMonth() + 1).padStart(2, '0');
//         const day = String(today.getDate()).padStart(2, '0');
//         return `${year}-${month}-${day}`;
//     }

    
//     // Sections under HV
//     const hvSections = [
//         'Induction Heater',
//         'Compressor (11kw)',
//         'Cut Bend',
//         'Compressor (75kw)',
//         'Compressor (30kw)',
//         'Epoxy Coating Blower',
//         'Epoxy Coating Booth Stabilizer',
//         'Shot Blasting-1',
//         'Shot Blasting-2',
//         'Pump House',
//         'Work Conveyor Panel',
//         'Lighting Panel',
//         'Utility Panel'
//     ];

//     const fetchDailyConsumptionData = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/dailyconsumption`);
//             const data = await response.json();
//             if (Array.isArray(data)) {
//                 setDailyconsumptionData(data);
//             } else {
//                 console.error('Daily Consumption data is not an array:', data);
//             }
//         } catch (error) {
//             console.error('Error fetching Daily Consumption data:', error);
//         }
//     };

//     useEffect(() => {
//         fetchDailyConsumptionData();
//     }, [selectedDate]);

//     const handleDateChange = (e) => {
//         setSelectedDate(e.target.value);
//     };

//     const toggleshowHVdataData = () => {
//         setshowHVdata(!showHVdata);
//     };

//     const handleStationPointChange = (selectedStationPoint) => {
//         setSection(selectedStationPoint);
//         if (selectedStationPoint !== 'HV') {
//             setshowHVdata(false); // Collapse HV data if another section is selected
//         }
//     };

//     // Filtered data logic
//     const filteredData = dailyconsumptionData.filter(item => {
//         if (!section) {
//             return item.Date === selectedDate;
//         }

//         if (section === 'HV') {
//             return item.Date === selectedDate && item.Section === 'HV';
//         }

//         if (hvSections.includes(section)) {
//             return item.Date === selectedDate && item.Section === section;
//         }

//         return item.Date === selectedDate && item.Section === section;
//     });

//     // Sorting to prioritize the selected section
//     const sortedFilteredData = filteredData.sort((a, b) => {
//         if (a.Section === section) return -1;
//         if (b.Section === section) return 1;
//         return 0;
//     });

//     return (
//         <div id='dailyconsumptionreport-right-2'>
//             <div id='dailyconsumptionreport-right-21'>
//                 <label style={{ display: 'flex', alignItems: 'center' }}>
//                     <span>Section</span>
//                     <Stationpoints onStationPointChange={handleStationPointChange} />
//                 </label>
//                 <label>
//                     <span>Select Date</span>
//                     <input
//                         type="date"
//                         value={selectedDate}
//                         onChange={handleDateChange}
//                     />
//                 </label>
//                 <label>
//                     <DailyConsumptionprint />
//                 </label>
//             </div>
//             <div id='dailyconsumption-right-22'>
//                 <div id='dailyconsumption-right-221'>
//                     <div id='dailyconsumption-right-head'>Section</div>
//                     <div id='dailyconsumption-right-head1'>Date</div>
//                     <div id='dailyconsumption-right-head2'>kWh Consumption</div>
//                 </div>

//                 {/* HV Section */}
//                 {section === 'HV' && sortedFilteredData
//                     .filter(item => item.Section === 'HV')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row'>
//                             <div id='dailyconsumption-right-data1'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}

//                 {/* Children under HV */}
//                 {showHVdata && hvSections.includes(section) && sortedFilteredData
//                     .filter(item => item.Section === section)
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}

//                 {/* Other Sections like DG and Solar */}
//                 {sortedFilteredData
//                     .filter(item => item.Section === 'DG 62.5 KVA' || item.Section === 'Solar 734.66 KVA')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row'>
//                             <div id='dailyconsumption-right-data1'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//             </div>
//         </div>
//     )
// }

// export default Dailycomsptionreport;
