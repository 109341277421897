// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#fromheader-total {
    background-color: #F3F6FE;
    min-height: 100vh;
}

#fromheader-belowcontent {
    margin-top: 2vh;
    margin-left: 5.5vw;

    /* select{
        background-color: white;
        border: 1.5px solid #d0d7ec;
        border-radius: 5px;
        height: 5.5vh;
        width: 15vw;
    } */


    select {
        background-color: white;
        border: 1.5px solid #d0d7ec;
        border-radius: 5px;
        height: 5.5vh;
        width: 15vw;

        option {
            padding: 5px;
            height: 5.5vh;
        }

        option.filled {
            color: green;
        }

        option.empty {
            color: red;
        }

        option.error {
            color: gray;
        }
    }


}


#fromheader-right {
    margin-left: 4vw;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Formscomponent/Formheadercompo/Formheadercomponent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,kBAAkB;;IAElB;;;;;;OAMG;;;IAGH;QACI,uBAAuB;QACvB,2BAA2B;QAC3B,kBAAkB;QAClB,aAAa;QACb,WAAW;;QAEX;YACI,YAAY;YACZ,aAAa;QACjB;;QAEA;YACI,YAAY;QAChB;;QAEA;YACI,UAAU;QACd;;QAEA;YACI,WAAW;QACf;IACJ;;;AAGJ;;;AAGA;IACI,gBAAgB;AACpB","sourcesContent":["#fromheader-total {\n    background-color: #F3F6FE;\n    min-height: 100vh;\n}\n\n#fromheader-belowcontent {\n    margin-top: 2vh;\n    margin-left: 5.5vw;\n\n    /* select{\n        background-color: white;\n        border: 1.5px solid #d0d7ec;\n        border-radius: 5px;\n        height: 5.5vh;\n        width: 15vw;\n    } */\n\n\n    select {\n        background-color: white;\n        border: 1.5px solid #d0d7ec;\n        border-radius: 5px;\n        height: 5.5vh;\n        width: 15vw;\n\n        option {\n            padding: 5px;\n            height: 5.5vh;\n        }\n\n        option.filled {\n            color: green;\n        }\n\n        option.empty {\n            color: red;\n        }\n\n        option.error {\n            color: gray;\n        }\n    }\n\n\n}\n\n\n#fromheader-right {\n    margin-left: 4vw;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
