
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Breakdownlogs.css';
import { formatDateToDDMMYYYY, formatDateTimeToDDMMYYYYHHMM } from '../Dateformat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Breakdownlogs = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const [formData, setFormData] = useState({
        typeofdowntime: '',
        location: '',
        starttime: '',
        endtime: '',
        duration: '',
        cause: '',
        createdby: '',
        date: getCurrentDate(),
    });

    const [errors, setErrors] = useState({
        starttime: '',
        endtime: ''
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
            setFormData((prevData) => ({
                ...prevData,
                createdby: user.username
            }));
            setUserDesignation(user.level);
        }
    }, []);

    const calculateDuration = (starttime, endtime) => {
        if (starttime && endtime) {
            const startTime = new Date(starttime);
            const endTime = new Date(endtime);

            let duration = endTime - startTime;

            const days = Math.floor(duration / (1000 * 60 * 60 * 24));
            const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

            const formattedDuration = `${days}d ${hours < 10 ? '0' + hours : hours}h ${minutes < 10 ? '0' + minutes : minutes}m`;

            setFormData(prevState => ({
                ...prevState,
                duration: formattedDuration
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => {
            const updatedFormData = {
                ...prevState,
                [name]: value
            };

            if (name === 'starttime' || name === 'endtime') {
                const now = new Date();
                const startTime = new Date(updatedFormData.starttime);
                const endTime = new Date(updatedFormData.endtime);

                if (startTime > now || endTime > now) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        [name]: 'Date cannot be in the future.'
                    }));
                } else {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        [name]: ''
                    }));

                    if (name === 'endtime' && startTime > endTime) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            endtime: 'End Date & Time cannot be earlier than start date & time.'
                        }));
                    } else if (name === 'starttime' && endTime < startTime) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            endtime: 'End Date & Time cannot be earlier than start date & time.'
                        }));
                    } else {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            endtime: ''
                        }));
                    }

                    calculateDuration(updatedFormData.starttime, updatedFormData.endtime);
                }
            }
            return updatedFormData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Format the date and time using the formatDateToDDMMYYYY and formatDateTimeToDDMMYYYYHHMM functions
        const formattedDate = formatDateToDDMMYYYY(formData.date);
        const formattedStartTime = formatDateTimeToDDMMYYYYHHMM(formData.starttime);
        const formattedEndTime = formatDateTimeToDDMMYYYYHHMM(formData.endtime);

        // Prepare the data to be sent to the server with the formatted values
        const formattedFormData = {
            ...formData,
            date: formattedDate,
            starttime: formattedStartTime,
            endtime: formattedEndTime,
        };

        if (!errors.starttime && !errors.endtime) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/breakdown-logs`, formattedFormData);
                if (response.data.success) {
                    setFormData({
                        typeofdowntime: '',
                        location: '',
                        starttime: '',
                        endtime: '',
                        duration: '',
                        cause: '',
                        createdby: loggedInUser.username,
                        date: getCurrentDate(),
                    });
                    toast.success('Breakdown Form submitted successfully');
                } else {
                    toast.error('Failed to submit data');
                }
            } catch (error) {
                console.error('Error submitting form', error);
                toast.error('Failed to submit data');
            }
        } else {
            toast.error('Please fix the errors before submitting.');
        }
    };


    return (
        <div id='breakdown-total'>
            {/* <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Formsidebar />}
            </div> */}
            <div id='breakdown-right'>
                {/* <div id='breakdown-right-1'>
                    <div id='breakdown-heading'>
                        {Breakdownheader.alertHeading}
                    </div>
                    <div id='breakdown-heading1'>
                        {Breakdownheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Breakdownheader.logoSrc} alt='logo' />
                    </div>
                </div> */}

                <div id='breakdown-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='breakdown-divtop'>
                            {/* <label>
                                <span>Operator Name</span>
                                <span>#123456</span>
                            </label> */}
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='breakdown-div1'>
                            <label>
                                <span>Date <span id='all-mandetorymark'>*</span></span>
                                <input type='date' name="date" value={formData.date} onChange={handleChange}  max={getCurrentDate()} required />
                            </label>

                            <label>
                                <span>Downtime Type <span id='all-mandetorymark'>*</span></span>
                                <select name="typeofdowntime" value={formData.typeofdowntime} onChange={handleChange} required>
                                    <option value="">Select The Down Time</option>
                                    <option value="Planned down time">Planned down time</option>
                                    <option value="Unplanned down time">Unplanned down time</option>
                                </select>
                            </label>

                        </div>

                        <div id='breakdown-div1'>
                            <label>
                                <span>Location <span id='all-mandetorymark'>*</span></span>
                                <input type='text' name="location" value={formData.location} onChange={handleChange} required />
                            </label>

                            <label>
                                <span>Start Time <span id='all-mandetorymark'>*</span></span>
                                <input type='datetime-local' name="starttime" value={formData.starttime} onChange={handleChange}  required />
                                {errors.starttime && <span id='breakdown-errormessage' style={{ color: 'red' }}>{errors.starttime}</span>}
                            </label>

                        </div>
                        <div id='breakdown-div1'>
                            <label>
                                <span>End Time <span id='all-mandetorymark'>*</span></span>
                                <input type='datetime-local' name="endtime" value={formData.endtime} onChange={handleChange} required />
                                {errors.endtime && <span id='breakdown-errormessage' style={{ color: 'red' }}>{errors.endtime}</span>}
                            </label>
                            <label>
                                <span>Duration <span id='all-mandetorymark'>*</span></span>
                                <input name="duration" value={formData.duration} onChange={handleChange} disabled required />
                            </label>

                        </div>
                        <div id='breakdown-div1'>
                            <label>
                                <span>Cause</span>
                                <textarea name="cause" value={formData.cause} onChange={handleChange} required />
                            </label>
                        </div>

                        <button id='breakdown-save' type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Breakdownlogs;
