
// import React, { useEffect, useState } from 'react';
// import './Alertstable.css';
// import { isWithinInterval } from 'date-fns';
// import { FaFileCsv } from "react-icons/fa6";
// import Stationpoints from '../../Commonfns/Stationpoints'; 

// const OperatorAlertstable = () => {
//   const [alerts, setAlerts] = useState([]);
//   const [filteredAlerts, setFilteredAlerts] = useState([]);
//   const [section, setSection] = useState('');
//   const [fromDateTime, setFromDateTime] = useState('');
//   const [toDateTime, setToDateTime] = useState('');

//   useEffect(() => {
//     const fetchAlerts = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/Alertshistoydata`);
//         const data = await response.json();

//         const formattedData = data.map(alert => ({
//           ...alert,
//           rawDateTime: new Date(alert.dateTime)
//         }));

//         setAlerts(formattedData);
//         setFilteredAlerts(formattedData);
//       } catch (error) {
//         console.error("Error fetching alerts:", error);
//       }
//     };

//     fetchAlerts();
//   }, []);

//   useEffect(() => {
//     const filtered = alerts.filter(alert => {
//       const matchesSection = section ? alert.stationPoint.toLowerCase().includes(section.toLowerCase()) : true;

//       const alertDate = new Date(alert.rawDateTime);
//       const fromDate = new Date(fromDateTime);
//       const toDate = new Date(toDateTime);

//       // Adjust the toDate to include the entire day
//       toDate.setHours(23, 59, 59, 999);

//       const matchesDate = fromDateTime && toDateTime ?
//         isWithinInterval(alertDate, { start: fromDate, end: toDate }) : true;

//       return matchesSection && matchesDate;
//     });
//     setFilteredAlerts(filtered);
//   }, [alerts, section, fromDateTime, toDateTime]);

//   const handleStationPointChange = (selectedStationPoint) => {
//     setSection(selectedStationPoint);
//   };

//   const downloadCSV = () => {
//     const csvContent = [
//       ['S NO', 'Section', 'Parameter Name', 'Event Description', 'Event Type', 'Event Value', 'Date & Time'],
//       ...filteredAlerts.map((alert, index) => [
//         index + 1,
//         alert.stationPoint,
//         alert.parameterName,
//         alert.eventDescription,
//         alert.eventType,
//         alert.eventValue,
//         alert.dateTime,
//       ]),
//     ]
//       .map(row => row.join(','))
//       .join('\n');

//     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.setAttribute('href', url);
//     link.setAttribute('download', 'AlertsHistory.csv');
//     link.style.display = 'none';
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <div id='opalert-total'>
//       <div id='opalert-right'>

//         <div id='alert-right-2'>
//           <div id='alert-right-21'>
//             <label>
//               <span>From Date:</span>
//               <input type='date' value={fromDateTime} onChange={(e) => setFromDateTime(e.target.value)} />
//             </label>
//             <label>
//               <span>To Date:</span>
//               <input type='date' value={toDateTime} onChange={(e) => setToDateTime(e.target.value)} />
//             </label>
//             <label>
//               <Stationpoints onStationPointChange={handleStationPointChange} />
//             </label>

//             <button onClick={downloadCSV}><FaFileCsv /></button>
//           </div>

//           <div id='alert-right-3'>
//             <table>
//               <thead>
//                 <tr>
//                   <th>S. No.</th>
//                   <th>Section</th>
//                   <th>Parameter</th>
//                   <th> Description</th>
//                   <th> Type</th>
//                   <th> Value</th>
//                   <th>Date & Time</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredAlerts.map((alert, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     <td>{alert.stationPoint}</td>
//                     <td>{alert.parameterName}</td>
//                     <td>{alert.eventDescription}</td>
//                     <td>{alert.eventType}</td>
//                     <td>{alert.eventValue}</td>
//                     <td>{alert.dateTime}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OperatorAlertstable;




import React, { useEffect, useState, useRef } from 'react';
import './Alertstable.css';
import { isWithinInterval } from 'date-fns';
import { FaFileCsv } from "react-icons/fa6";
import Stationpoints from '../../Commonfns/Stationpoints';

const OperatorAlertstable = () => {
  const [alerts, setAlerts] = useState([]);
  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const [displayedAlerts, setDisplayedAlerts] = useState([]);
  const [section, setSection] = useState('');
  const [fromDateTime, setFromDateTime] = useState('');
  const [toDateTime, setToDateTime] = useState('');
  const [page, setPage] = useState(1);
  const [fromDateValidation, setFromDateValidation] = useState(''); // Validation message for 'From Date'
  const [toDateValidation, setToDateValidation] = useState('');
  const itemsPerPage = 50; // Number of items to display initially and on each scroll

  const tableRef = useRef(null);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/Alertshistoydata`);
        const data = await response.json();

        const formattedData = data.map(alert => ({
          ...alert,
          rawDateTime: new Date(alert.dateTime)
        }));

        setAlerts(formattedData);
        setFilteredAlerts(formattedData);
        setDisplayedAlerts(formattedData.slice(0, itemsPerPage)); // Load the first batch of items
      } catch (error) {
        console.error("Error fetching alerts:", error);
      }
    };

    fetchAlerts();
  }, []);

  useEffect(() => {
    const filtered = alerts.filter(alert => {
      const matchesSection = section ? alert.stationPoint.toLowerCase().includes(section.toLowerCase()) : true;

      const alertDate = new Date(alert.rawDateTime);
      const fromDate = new Date(fromDateTime);
      const toDate = new Date(toDateTime);

      // Adjust the toDate to include the entire day
      toDate.setHours(23, 59, 59, 999);

      const matchesDate = fromDateTime && toDateTime ?
        isWithinInterval(alertDate, { start: fromDate, end: toDate }) : true;

      return matchesSection && matchesDate;
    });

    setFilteredAlerts(filtered);
    setDisplayedAlerts(filtered.slice(0, page * itemsPerPage)); // Reset displayed alerts based on filters
  }, [alerts, section, fromDateTime, toDateTime]);

  const loadMoreItems = () => {
    const newPage = page + 1;
    const newItems = filteredAlerts.slice(0, newPage * itemsPerPage);
    setDisplayedAlerts(newItems);
    setPage(newPage);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        tableRef.current &&
        tableRef.current.scrollTop + tableRef.current.clientHeight >= tableRef.current.scrollHeight
      ) {
        loadMoreItems();
      }
    };

    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [filteredAlerts, page]);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const handleFromDateChange = (e) => {
    const fromDate = e.target.value;
    setFromDateTime(fromDate);

    // If the selected 'To Date' is earlier than the new 'From Date', reset the 'To Date'
    if (toDateTime && toDateTime < fromDate) {
      setToDateTime(''); // Clear 'To Date' if it no longer falls within range
    }
  };

  const handleToDateChange = (e) => {
    setToDateTime(e.target.value);
  };


  const downloadCSV = () => {
    const csvContent = [
      ['S NO', 'Section', 'Parameter Name', 'Event Description', 'Event Type', 'Event Value', 'Date & Time'],
      ...filteredAlerts.map((alert, index) => [
        index + 1,
        alert.stationPoint,
        alert.parameterName,
        alert.eventDescription,
        alert.eventType,
        alert.eventValue,
        alert.dateTime,
      ]),
    ]
      .map(row => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'AlertsHistory.csv');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
  };

  return (
    <div id='opalert-total'>
      <div id='opalert-right'>
        <div id='alert-right-2'>
          <div id='alert-right-21'>
          <label>
              <span>From Date:</span>
              <input
                type='date'
                value={fromDateTime}
                onChange={handleFromDateChange}
                max={getTodayDate()}
              />
            </label>
            <label>
              <span>To Date:</span>
              <input
                type='date'
                value={toDateTime}
                onChange={handleToDateChange}
                max={getTodayDate()} 
                min={fromDateTime || ''} 
                disabled={!fromDateTime} 
              />
            </label>
            <label>
              <Stationpoints onStationPointChange={handleStationPointChange} />
            </label>
            <label>
              <button onClick={downloadCSV}><FaFileCsv /></button>

            </label>
          </div>

          <div id='alert-right-3'>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Section</th>
                  <th>Parameter</th>
                  <th> Description</th>
                  <th> Type</th>
                  <th> Value</th>
                  <th>Date & Time</th>
                </tr>
              </thead>
              <tbody>
              {filteredAlerts.length === 0 ? (
                  <tr>
                    <td colSpan="7">No data available for selected filters.</td>
                  </tr>
                ) : (
                displayedAlerts.map((alert, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{alert.stationPoint}</td>
                    <td>{alert.parameterName}</td>
                    <td>{alert.eventDescription}</td>
                    <td>{alert.eventType}</td>
                    <td>{alert.eventValue}</td>
                    <td>{alert.dateTime}</td>
                  </tr>
               ))
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorAlertstable;
