// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.station-select-container {
    display: flex;
    align-items: center;

    label {
        display: flex;
        flex-direction: column;
        justify-content:left ;


        select {
            border-radius: 5px;
            border: 1.5px solid #DFE6FA;
            height: 30px;
        }
    }
}


@media screen and (min-width: 1024px) {
    .station-select-container {
        label { 
            select {
                height: 3.9vh;
            } 
        }
       
    }
}

@media screen and (min-width: 1440px) {
    .station-select-container {
        label { 
            select {
                height: 3.9vh;
            } 
        }
    }
   
}
@media screen and (min-width: 1920px) {
    .station-select-container {
        label { 
            select {
                height: 3.9vh;
            } 
        }
    }
   
}`, "",{"version":3,"sources":["webpack://./src/Components/Commonfns/Stationpoints.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB;QACI,aAAa;QACb,sBAAsB;QACtB,qBAAqB;;;QAGrB;YACI,kBAAkB;YAClB,2BAA2B;YAC3B,YAAY;QAChB;IACJ;AACJ;;;AAGA;IACI;QACI;YACI;gBACI,aAAa;YACjB;QACJ;;IAEJ;AACJ;;AAEA;IACI;QACI;YACI;gBACI,aAAa;YACjB;QACJ;IACJ;;AAEJ;AACA;IACI;QACI;YACI;gBACI,aAAa;YACjB;QACJ;IACJ;;AAEJ","sourcesContent":[".station-select-container {\n    display: flex;\n    align-items: center;\n\n    label {\n        display: flex;\n        flex-direction: column;\n        justify-content:left ;\n\n\n        select {\n            border-radius: 5px;\n            border: 1.5px solid #DFE6FA;\n            height: 30px;\n        }\n    }\n}\n\n\n@media screen and (min-width: 1024px) {\n    .station-select-container {\n        label { \n            select {\n                height: 3.9vh;\n            } \n        }\n       \n    }\n}\n\n@media screen and (min-width: 1440px) {\n    .station-select-container {\n        label { \n            select {\n                height: 3.9vh;\n            } \n        }\n    }\n   \n}\n@media screen and (min-width: 1920px) {\n    .station-select-container {\n        label { \n            select {\n                height: 3.9vh;\n            } \n        }\n    }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
