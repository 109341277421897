// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Livedata-total{
    display: flex;
    background-color: #F3F6FE;
    height: 100vh;
    position: fixed;
    
}
#Livedata-right{
    margin-left: 4vw;
}
#Livedata-right-1{
    height: 70px;
    width: 96vw;
    background-color: #F3F6FE;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    #Livedata-heading{
        /* padding-top: 15px; */
        padding-left: 20px;
        font-size: 25px;
        font-weight: bold;
    }
    #Livedata-heading1{
        align-items: center;
        margin-right: 30px;
        justify-items: center;
    }
    button{
        margin-right: 20px;
        font-size: 25px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    img {
        height: 20px;
        width: 80px;
    }
}
#Livedata-right-2{
    margin-top: 25px;
    padding: 0px 30px 25px 30px;
    
    table{
      background-color: white;
      width: 91vw;
      height: 80vh;
      overflow-y: scroll;
      overflow-x: scroll;
      display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/Livedata/Livedata.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,eAAe;;AAEnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,2CAA2C;IAC3C,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B;QACI,uBAAuB;QACvB,kBAAkB;QAClB,eAAe;QACf,iBAAiB;IACrB;IACA;QACI,mBAAmB;QACnB,kBAAkB;QAClB,qBAAqB;IACzB;IACA;QACI,kBAAkB;QAClB,eAAe;QACf,YAAY;QACZ,6BAA6B;QAC7B,eAAe;IACnB;IACA;QACI,YAAY;QACZ,WAAW;IACf;AACJ;AACA;IACI,gBAAgB;IAChB,2BAA2B;;IAE3B;MACE,uBAAuB;MACvB,WAAW;MACX,YAAY;MACZ,kBAAkB;MAClB,kBAAkB;MAClB,cAAc;IAChB;AACJ","sourcesContent":["#Livedata-total{\n    display: flex;\n    background-color: #F3F6FE;\n    height: 100vh;\n    position: fixed;\n    \n}\n#Livedata-right{\n    margin-left: 4vw;\n}\n#Livedata-right-1{\n    height: 70px;\n    width: 96vw;\n    background-color: #F3F6FE;\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    #Livedata-heading{\n        /* padding-top: 15px; */\n        padding-left: 20px;\n        font-size: 25px;\n        font-weight: bold;\n    }\n    #Livedata-heading1{\n        align-items: center;\n        margin-right: 30px;\n        justify-items: center;\n    }\n    button{\n        margin-right: 20px;\n        font-size: 25px;\n        border: none;\n        background-color: transparent;\n        cursor: pointer;\n    }\n    img {\n        height: 20px;\n        width: 80px;\n    }\n}\n#Livedata-right-2{\n    margin-top: 25px;\n    padding: 0px 30px 25px 30px;\n    \n    table{\n      background-color: white;\n      width: 91vw;\n      height: 80vh;\n      overflow-y: scroll;\n      overflow-x: scroll;\n      display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
